import React, { useContext, useEffect, useState } from "react";
import { ApplicationContext } from "../../Context/ApplicationContextProvider";
import { Layout, Space, Select, Input, DatePicker } from "antd";
import { RightOutlined } from "@ant-design/icons";
import type { RadioChangeEvent } from "antd";
import "./FileSAR.scss";
import { useNavigate } from "react-router-dom";
import { Button, Modal, Image, Row, Col, Radio } from "antd";
import cancel from "../../Assets/Images/cancelBg.png";
import submitFin from "../../Assets/Images/sarSubmitBg.png";
import excelIcon from "../../Assets/Images/exporticonSvg.svg";
import starimage from "../../Assets/Images/starSvg.svg";

const { Header, Footer, Sider, Content } = Layout;
const { TextArea } = Input;
const size = "large";

type FinalPopupProps = {
  showPopup: boolean;
  setShowPopup: (value: boolean) => void;
  showSARDId: string;
  setshowSARDId: (value: string) => void;
};
const FileSAR = (popupProps: FinalPopupProps) => {
  const [value, setValue] = useState(1);
  const navigate = useNavigate();

  const handleOk = () => {
    popupProps.setShowPopup(false);
  };

  const handleCancel = () => {
    popupProps.setShowPopup(false);
  };

  const onChange = (e: RadioChangeEvent) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  return (
    <Modal
      centered
      open={popupProps.showPopup}
      title={popupProps.showSARDId}
      width={900}
      onOk={handleOk}
      onCancel={handleCancel}
      className="reportsarscreenmain"
      footer={[
        <div className="footersar">
          <Row className="reportsfillingfooter">
            <Col
              xs={24}
              sm={24}
              md={6}
              lg={7}
              className="reportsar-containerfooter"
            >              
             <div className="buttonBg floatLeft cursor" onClick={handleCancel}>
              <img src={cancel} alt="cancel" className="buttonWidthSarCancel" />
              <span className="buttonTextSarCancel">Cancel</span>
            </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={5}
              lg={5}
              className="reportsar-containerfooterright"
            >
              <p>
                <span className="exporttimefoo cursor">Export to XML</span>
                <span className="exportimgfoo cursor">
                  <img
                    className="excelIconimgfoo"
                    src={excelIcon}
                    alt="excelIcon"
                  />
                </span>
              </p>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={13}
              lg={11}
              className="reportsar-containerfooterexterme"
            >

              <div className="buttonBg floatRight cursor" onClick={handleCancel}>
              <img src={submitFin} alt="submitFin" className="buttonWidthSarSubmit" />
              <span className="buttonTextSarSubmit">Submit to FinCEN</span>
            </div>
            </Col>
          </Row>
        </div>,
      ]}
    >
      <div className="reportsar-container">
        <div className="reportsar-group">
          <h3 className="reportsheading">
            Filing Institution Contract Information
          </h3>
          <p className="reportsarpara">Filing Entity Information</p>
          <Row className="reportsfilling">
            <Col
              xs={24}
              sm={24}
              md={11}
              lg={11}
              className="reportsar-container "
            >
              <div className="info_reportText">
                <span className="report_span">Filer Name</span>
                <Input
                  className="info_inputreportClass"
                  placeholder={popupProps.showSARDId == "" ? "" : "XYZ Bank"}
                  bordered={false}
                />
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={11}
              lg={11}
              className="reportsar-container "
            >
              <div className="info_reportText nospan">
                <Input
                  className="info_inputreportClass"
                  placeholder="Alternative Name"
                  bordered={false}
                />
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={11}
              lg={11}
              className="reportsar-container "
            >
              <div className="info_reportText">
                <span className="report_span">TIN</span>
                <Input
                  className="info_inputreportClass"
                  placeholder={popupProps.showSARDId == "" ? "" : "670-18-6362"}
                  bordered={false}
                />
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={11}
              lg={11}
              className="reportsar-container "
            >
              <div className="info_reportText">
                <span className="report_span">TIN Type</span>
                <Space
                  wrap
                  className="fullWidth info_inputTextClass select-input-padding"
                >
                  <Select
                    size={size}
                    bordered={false}
                    value={popupProps.showSARDId == "" ? "" : 0}
                    className="info_inputreportClass"
                    options={[
                      {
                        value: 0,
                        label: "EIN",
                      },
                      { value: 2, label: "EIN2" },
                    ]}
                  ></Select>
                </Space>
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={11}
              lg={11}
              className="reportsar-container "
            >
              <div className="info_reportText">
                <span className="report_span">
                  Type of Financial Institution
                </span>
                <Space
                  wrap
                  className="fullWidth info_inputTextClass select-input-padding"
                >
                  <Select
                    size={size}
                    bordered={false}
                    value={popupProps.showSARDId == "" ? "" : 0}
                    className="info_inputreportClass"
                    options={[
                      {
                        value: 0,
                        label: "MSB",
                      },
                      { value: 2, label: "MSB1" },
                    ]}
                  ></Select>
                </Space>
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={11}
              lg={11}
              className="reportsar-container "
            >
              <div className="info_reportText nospan">
                <Input
                  className="info_inputreportClass"
                  placeholder="Institution Type Other"
                  bordered={false}
                />
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={11}
              lg={11}
              className="reportsar-container "
            >
              <div className="info_reportText">
                <span className="report_span">Primary Federal Regulator</span>
                <Space
                  wrap
                  className="fullWidth info_inputTextClass select-input-padding"
                >
                  <Select
                    size={size}
                    bordered={false}
                    value={popupProps.showSARDId == "" ? "" : 0}
                    className="info_inputreportClass"
                    options={[
                      {
                        value: 0,
                        label: "IRS",
                      },
                      { value: 2, label: "IRS1" },
                    ]}
                  ></Select>
                </Space>
              </div>
            </Col>
          </Row>
          <p className="reportsarpara">Contact Information</p>
          <Row className="reportsfilling">
            <Col
              xs={24}
              sm={24}
              md={11}
              lg={11}
              className="reportsar-container "
            >
              <div className="info_reportText">
                <span className="report_span">Office Name / Identifier</span>
                <Input
                  className="info_inputreportClass"
                  placeholder={popupProps.showSARDId == "" ? "" : "Compliance"}
                  bordered={false}
                />
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={11}
              lg={11}
              className="reportsar-container "
            >
              <div className="info_reportText">
                <span className="report_span">Phone</span>
                <Input
                  className="info_inputreportClass"
                  placeholder={
                    popupProps.showSARDId == "" ? "" : "(234) 567-8901"
                  }
                  bordered={false}
                />
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={11}
              lg={11}
              className="reportsar-container "
            >
              <div className="info_reportText">
                <span className="report_span">Street Address</span>
                <Input
                  className="info_inputreportClass"
                  placeholder={
                    popupProps.showSARDId == "" ? "" : "445 1st Avenue"
                  }
                  bordered={false}
                />
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={11}
              lg={11}
              className="reportsar-container "
            >
              <div className="info_reportText">
                <span className="report_span">City</span>
                <Input
                  className="info_inputreportClass"
                  placeholder={popupProps.showSARDId == "" ? "" : "New York"}
                  bordered={false}
                />
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={11}
              lg={11}
              className="reportsar-container "
            >
              <div className="info_reportText">
                <span className="report_span">State</span>
                <Space
                  wrap
                  className="fullWidth info_inputTextClass select-input-padding"
                >
                  <Select
                    size={size}
                    bordered={false}
                    value={popupProps.showSARDId == "" ? "" : 0}
                    className="info_inputreportClass"
                    options={[
                      {
                        value: 0,
                        label: "NY",
                      },
                      { value: 2, label: "NY2" },
                    ]}
                  ></Select>
                </Space>
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={11}
              lg={11}
              className="reportsar-container "
            >
              <div className="info_reportText">
                <span className="report_span">ZIP Code</span>
                <Input
                  className="info_inputreportClass"
                  placeholder={popupProps.showSARDId == "" ? "" : "10001"}
                  bordered={false}
                />
              </div>
            </Col>
          </Row>
          <h3 className="reportsheading">
            Filling Institution Where Activity Occurred
          </h3>
          <p className="reportsarpara">Branch's Role in the Transaction</p>
          <Row className="reportsfilling">
            <Col
              xs={24}
              sm={24}
              md={11}
              lg={11}
              className="reportsar-container "
            >
              <div className="info_reportText">
                <span className="report_span">Role</span>
                <Space
                  wrap
                  className="fullWidth info_inputTextClass select-input-padding"
                >
                  <Select
                    size={size}
                    bordered={false}
                    value={popupProps.showSARDId == "" ? "" : 0}
                    className="info_inputreportClass"
                    options={[
                      {
                        value: 0,
                        label: "Both",
                      },
                      { value: 2, label: "Both1" },
                    ]}
                  ></Select>
                </Space>
              </div>
            </Col>
          </Row>

          <p className="reportsarpara">Branch Address Information</p>
          <Row className="reportsfilling">
            <Col
              xs={24}
              sm={24}
              md={11}
              lg={11}
              className="reportsar-container "
            >
              <div className="info_reportText">
                <img
                  className="cursor starimage1"
                  src={starimage}
                  alt="starimage"
                />
                <span className="report_span">Street Address</span>
                <Input
                  className="info_inputreportClass"
                  placeholder={popupProps.showSARDId == "" ? "" : "123 Main st"}
                  bordered={false}
                />
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={11}
              lg={11}
              className="reportsar-container "
            >
              <div className="info_reportText">
                <img
                  className="cursor starimage1"
                  src={starimage}
                  alt="starimage"
                />
                <span className="report_span">City</span>
                <Input
                  className="info_inputreportClass"
                  placeholder={popupProps.showSARDId == "" ? "" : "Seattle"}
                  bordered={false}
                />
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={11}
              lg={11}
              className="reportsar-container "
            >
              <div className="info_reportText">
                <span className="report_span">State</span>
                <Space
                  wrap
                  className="fullWidth info_inputTextClass select-input-padding"
                >
                  <Select
                    size={size}
                    bordered={false}
                    value={popupProps.showSARDId == "" ? "" : 0}
                    className="info_inputreportClass"
                    options={[
                      {
                        value: 0,
                        label: "WA",
                      },
                      { value: 2, label: "WA1" },
                    ]}
                  ></Select>
                </Space>
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={11}
              lg={11}
              className="reportsar-container "
            >
              <div className="info_reportText">
                <span className="report_span">ZIP Code</span>
                <Input
                  className="info_inputreportClass"
                  placeholder={popupProps.showSARDId == "" ? "" : "98001"}
                  bordered={false}
                />
              </div>
            </Col>
          </Row>

          <h3 className="reportsheading">Subject Information</h3>
          <p className="reportsarpara">Personal Information</p>
          <Row className="reportsfilling">
            <Col
              xs={24}
              sm={24}
              md={11}
              lg={11}
              className="reportsar-container "
            >
              <div className="info_reportText">
                <img
                  className="cursor starimage1"
                  src={starimage}
                  alt="starimage"
                />
                <span className="report_span">Last Name</span>
                <Input
                  className="info_inputreportClass"
                  placeholder={popupProps.showSARDId == "" ? "" : "Brown"}
                  bordered={false}
                />
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={11}
              lg={11}
              className="reportsar-container "
            >
              <div className="info_reportText">
                <img
                  className="cursor starimage1"
                  src={starimage}
                  alt="starimage"
                />
                <span className="report_span">First Name</span>
                <Input
                  className="info_inputreportClass"
                  placeholder={popupProps.showSARDId == "" ? "" : "James"}
                  bordered={false}
                />
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={11}
              lg={11}
              className="reportsar-container "
            >
              <div className="info_reportText nospan">
                <Input
                  className="info_inputreportClass"
                  placeholder="Middle Name"
                  bordered={false}
                />
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={11}
              lg={11}
              className="reportsar-container "
            >
              <div className="info_reportText nospan">
                <Input
                  className="info_inputreportClass"
                  placeholder="Suffix"
                  bordered={false}
                />
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={11}
              lg={11}
              className="reportsar-container "
            >
              <div className="info_reportText nospan">
                <Input
                  className="info_inputreportClass"
                  placeholder="Alternative Name (AKA, DBA, etc.)"
                  bordered={false}
                />
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={11}
              lg={11}
              className="reportsar-container "
            >
              <div className="info_reportText">
                <span className="report_span">Gender</span>
                <Space
                  wrap
                  className="fullWidth info_inputTextClass select-input-padding"
                >
                  <Select
                    size={size}
                    bordered={false}
                    value={popupProps.showSARDId == "" ? "" : 0}
                    className="info_inputreportClass"
                    options={[
                      {
                        value: 0,
                        label: "None",
                      },
                      { value: 2, label: "Male" },
                    ]}
                  ></Select>
                </Space>
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={11}
              lg={11}
              className="reportsar-container "
            >
              <div className="info_reportText datrofbirth">
                <img
                  className="cursor starimage1"
                  src={starimage}
                  alt="starimage"
                />
                {/* <span className="report_span">Start Date</span>  */}
                <Space direction="vertical" className="fullWidth">
                  <DatePicker
                    bordered={false}
                    placeholder="Date of Birth"
                    format={"MM/DD/YYYY"}
                  />
                </Space>
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={11}
              lg={11}
              className="reportsar-container "
            >
              <div className="info_reportText nospan">
                <Input
                  className="info_inputreportClass"
                  placeholder="Occupation"
                  bordered={false}
                />
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={11}
              lg={23}
              className="reportsar-container "
            >
              <div className="info_reportText">
                <span className="report_span">NAICS Code</span>
                <Space
                  wrap
                  className="fullWidth info_inputTextClass select-input-padding"
                >
                  <Select
                    size={size}
                    bordered={false}
                    value={popupProps.showSARDId == "" ? "" : 0}
                    className="info_inputreportClass"
                    options={[
                      {
                        value: 0,
                        label: "None",
                      },
                      { value: 2, label: "None1" },
                    ]}
                  ></Select>
                </Space>
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={111}
              lg={11}
              className="reportsar-container "
            >
              <div className="info_reportText noselectspan">
                {/* <span className="report_span">Role</span> */}
                <Space
                  wrap
                  className="fullWidth info_inputTextClass select-input-padding"
                >
                  <Select
                    size={size}
                    bordered={false}
                    value={popupProps.showSARDId == "" ? "" : 0}
                    className="info_inputreportClass"
                    options={[
                      {
                        value: 0,
                        label: "TIN Type",
                      },
                      { value: 2, label: "TIN Type1" },
                    ]}
                  ></Select>
                </Space>
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={11}
              lg={11}
              className="reportsar-container "
            >
              <div className="info_reportText nospan">
                <img
                  className="cursor starimage1"
                  src={starimage}
                  alt="starimage"
                />
                <Input
                  className="info_inputreportClass"
                  placeholder="TIN"
                  bordered={false}
                />
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={11}
              lg={11}
              className="reportsar-container "
            >
              <div className="info_reportText nospan">
                <Input
                  className="info_inputreportClass"
                  placeholder="Phone"
                  bordered={false}
                />
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={11}
              lg={11}
              className="reportsar-container "
            >
              <div className="info_reportText nospan">
                <Input
                  className="info_inputreportClass"
                  placeholder="Extension"
                  bordered={false}
                />
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={11}
              lg={11}
              className="reportsar-container "
            >
              <div className="info_reportText nospan">
                <Input
                  className="info_inputreportClass"
                  placeholder="Email Address 1"
                  bordered={false}
                />
              </div>
            </Col>
          </Row>
          <h3 className="reportsheading">Suspicious Activity Information</h3>
          <p className="reportsarpara">Amount and Time Frame</p>
          <Row className="reportsfilling">
            <Col
              xs={24}
              sm={24}
              md={11}
              lg={11}
              className="reportsar-container "
            >
              <div className="info_reportTextradio">
                <div className="info_Textradioleft">
                  <p>Is Amount Known?</p>
                </div>
                <div className="info_Textradioright">
                  <Radio.Group onChange={onChange} value={value}>
                    <Radio value={1}>Yes</Radio>
                    <Radio value={2}>No</Radio>
                  </Radio.Group>
                </div>
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={11}
              lg={11}
              className="reportsar-container "
            >
              <div className="info_reportText">
                <span className="report_span">Amount Involved</span>
                <Input
                  className="info_inputreportClass"
                  placeholder={popupProps.showSARDId == "" ? "" : "102"}
                  bordered={false}
                />
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={11}
              lg={11}
              className="reportsar-container "
            >
              <div className="info_reportText">
                <span className="report_span">Start Date</span>
                <Space direction="vertical" className="fullWidth">
                  <DatePicker
                    bordered={false}
                    placeholder={
                      popupProps.showSARDId == "" ? "" : "05/16/2019"
                    }
                    format={"MM/DD/YYYY"}
                  />
                </Space>
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={11}
              lg={11}
              className="reportsar-container "
            >
              <div className="info_reportText">
                <span className="report_span">End Date</span>
                <Space direction="vertical" className="fullWidth">
                  <DatePicker
                    bordered={false}
                    placeholder={
                      popupProps.showSARDId == "" ? "" : "05/17/2019"
                    }
                    format={"MM/DD/YYYY"}
                  />
                </Space>
              </div>
            </Col>
          </Row>
          <p className="reportsarpara">
            Activity Categories <br />
            (You must select at least one item from at least one of the
            categories below)
          </p>

          <Row className="reportsfilling">
            <Col
              xs={24}
              sm={24}
              md={11}
              lg={11}
              className="reportsar-container "
            >
              <div className="info_reportText noselectspan">
                {/* <span className="report_span">Role</span> */}
                <Space
                  wrap
                  className="fullWidth info_inputTextClass select-input-padding"
                >
                  <Select
                    size={size}
                    bordered={false}
                    value={popupProps.showSARDId == "" ? "" : 0}
                    className="info_inputreportClass"
                    options={[
                      {
                        value: 0,
                        label: "Structuring",
                      },
                      { value: 2, label: "Structuring1" },
                    ]}
                  ></Select>
                </Space>
              </div>
            </Col>
          </Row>
          <Row className="reportsfilling">
            <Col
              xs={24}
              sm={24}
              md={11}
              lg={11}
              className="reportsar-container "
            >
              <div className="info_reportText noselectspan">
                {/* <span className="report_span">Role</span> */}
                <Space
                  wrap
                  className="fullWidth info_inputTextClass select-input-padding"
                >
                  <Select
                    size={size}
                    bordered={false}
                    value={popupProps.showSARDId == "" ? "" : 0}
                    className="info_inputreportClass"
                    options={[
                      {
                        value: 0,
                        label: "Terrorist Financing",
                      },
                      { value: 2, label: "Terrorist Financing1" },
                    ]}
                  ></Select>
                </Space>
              </div>
            </Col>
          </Row>
          <Row className="reportsfilling">
            <Col
              xs={24}
              sm={24}
              md={11}
              lg={11}
              className="reportsar-container "
            >
              <div className="info_reportText noselectspan">
                {/* <span className="report_span">Role</span> */}
                <Space
                  wrap
                  className="fullWidth info_inputTextClass select-input-padding"
                >
                  <Select
                    size={size}
                    bordered={false}
                    value={popupProps.showSARDId == "" ? "" : 0}
                    className="info_inputreportClass"
                    options={[
                      {
                        value: 0,
                        label: "Fraud",
                      },
                      { value: 2, label: "Fraud1" },
                    ]}
                  ></Select>
                </Space>
              </div>
            </Col>
          </Row>
          <p className="reportsarpara">Narrative</p>
          <Row className="reportsfilling">
            <Col
              xs={24}
              sm={24}
              md={23}
              lg={23}
              className="reportsar-container "
            >
              <div className="info_reportText descriptionarea ">
                <TextArea
                  bordered={false}
                  placeholder="Narrative, 6000 characters remaining"
                  className="detaistextarea"
                  rows={4}
                  style={{ resize: "none" }}
                />
              </div>
            </Col>
          </Row>
          <p className="reportsarpara">Filing Details</p>
          <Row className="reportsfilling">
            <Col
              xs={24}
              sm={24}
              md={23}
              lg={23}
              className="reportsar-container "
            >
              <div className="info_reportText nospan">
                <Input
                  className="info_inputreportClass"
                  placeholder="Filing Name"
                  bordered={false}
                />
              </div>
            </Col>
          </Row>
          <p className="reportsarpara">Type of Filing</p>
          <Row className="reportsfilling">
            <Col
              xs={24}
              sm={24}
              md={23}
              lg={23}
              className="reportsar-container "
            >
              <div className="info_reportText">
                <span className="report_span">Type of Filing</span>
                <Space
                  wrap
                  className="fullWidth info_inputTextClass select-input-padding"
                >
                  <Select
                    size={size}
                    bordered={false}
                    value={popupProps.showSARDId == "" ? "" : 0}
                    className="info_inputreportClass"
                    options={[
                      {
                        value: 0,
                        label: "Initial Report",
                      },
                      { value: 2, label: "Initial Report1" },
                    ]}
                  ></Select>
                </Space>
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={23}
              lg={23}
              className="reportsar-container "
            >
              <div className="info_reportText nospan">
                <Input
                  className="info_inputreportClass"
                  placeholder="Prior BSAID"
                  bordered={false}
                />
              </div>
              <span className="digitnum">
                A 14 digit number is required if it is not initial filing
              </span>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={23}
              lg={23}
              className="reportsar-container "
            >
              <div className="info_reportText nospan">
                <Input
                  className="info_inputreportClass"
                  placeholder="Filing Institution Note to FinCEN"
                  bordered={false}
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  );
};

export default FileSAR;
