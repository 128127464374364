import React, { useContext, useEffect, useState } from "react";
import { ApplicationContext } from "../../Context/ApplicationContextProvider";
import {
  Layout,
  Space,
  Button,
  Row,
  Col,
  Input,
  Select,
  Form,
  Collapse,
  CollapseProps,
  theme,
  DatePicker,
  Modal,
  Switch,
} from "antd";

import "./Settings.scss";

import { IStepsHeader } from "../../Contracts/Contracts";
import save from "../../Assets/Images/saveBtnSettings.png";
const { Header, Footer, Sider, Content } = Layout;
type FinalPopupProps = {
  showPopup: boolean;
  setShowPopup: (value: boolean) => void;
};

const Settings = (popupProps: FinalPopupProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showChangePopup, setShowchangePopup] = useState(false);
  const [showNewPass, setshowNewPAss] = useState(false);
  const [passwordChangeSuccess, setPasswordChangeSuccess] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const handleOk = () => {
    if (showNewPass && !passwordChangeSuccess) {
      return;
    }

    if (showNewPass) {
      form
        .validateFields()
        .then(() => {
          popupProps.setShowPopup(false);
          setshowNewPAss(false);
        })
        .catch((errors) => {
          setFormErrors(errors);
        });
    } else {
      popupProps.setShowPopup(false);
    }
  };

  const handleCancel = () => {
    setPasswordChangeSuccess(false);
    setshowNewPAss(false);
    popupProps.setShowPopup(false);
  };

  const showNewSection = () => {
    setshowNewPAss(true);
  };

  const validatePassword = (rule: any, value: string, callback: any) => {
    if (showNewPass) {
      if (value && value !== form.getFieldValue("newPassword")) {
        callback("Passwords do not match");
      } else {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=]).{8,}$/;
        if (!regex.test(value)) {
          callback(
            "Password must have at least 8 characters, 1 lowercase, 1 uppercase, 1 number, and 1 special character"
          );
        } else {
          setPasswordChangeSuccess(true);
        }
        callback();
      }
    } else {
      callback();
    }
  };

  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    setTimeout(() => {
      setPasswordChangeSuccess(true);
    }, 2000);
  };

  return (
    <Modal
      centered
      open={popupProps.showPopup}
      onOk={handleOk}
      onCancel={handleCancel}
      width={800}
      title="Change Settings"
      className="passHeaderfix widthOfPopup"
      footer={[
        <div className="flex">
          {!showNewPass && (
            <div
              className="buttonBg floatLeft createButton"
              onClick={handleCancel}
            >
              <span className="buttonText">Save changes</span>
            </div>
          )}
          {showNewPass && (
            <div className="buttonBg floatRight cursor" onClick={handleOk}>
              <img src={save} alt="reset" className="buttonWidthSetRest" />
              <span className="buttonTextRestSettings">Reset password</span>
            </div>
          )}
        </div>,
      ]}
    >
      <div className="change-container">
        <Form
          form={form}
          name="settingsForm"
          initialValues={{ newPassword: "" }}
          onFinish={onFinish}
        >
          <Row>
            <Col md={11} xs={24}>
              <Form.Item
                name="firstname"
                rules={[
                  {
                    required: true,
                    message: "First Name is required",
                  },
                ]}
              >
                <div className="sett_borderText">
                  <span className="sett_span">First Name</span>
                  <Input
                    className="sett_inputTextClass"
                    placeholder="John"
                    bordered={false}
                  />
                </div>
              </Form.Item>
            </Col>

            <Col md={12} xs={24}>
              <Form.Item
                name="lastname"
                rules={[
                  {
                    required: true,
                    message: "Last Name is required",
                  },
                ]}
              >
                <div className="sett_borderText">
                  <span className="sett_span">Last Name</span>
                  <Input
                    className="sett_inputTextClass"
                    placeholder="Smith"
                    bordered={false}
                  />
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={14} xs={24}>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Email is required",
                  },
                ]}
              >
                <div className="sett_borderText">
                  <span className="sett_span">Email</span>
                  <Input
                    className="sett_inputTextClass"
                    placeholder="john_smith@loquatinc.com"
                    bordered={false}
                  />
                </div>
              </Form.Item>
            </Col>

            <Col md={9} xs={24} className="switch">
              <div className="floatRight">
                <span className="sett_email">Email notifications</span>
                <Switch defaultChecked />
              </div>
            </Col>
          </Row>
          {!showNewPass && (
            <div>
              <Row>
                <Col md={10} xs={24} className="">
                  <div className="sett_borderText">
                    <span className="sett_span">Password</span>
                    <Input.Password
                      bordered={false}
                      placeholder="Password"
                      className="sett_inputTextClass"
                      value="asasa"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <span
                  className="changePsd underline cursor"
                  // onClick={showNewSection}
                >
                  Change Password?
                </span>
              </Row>
            </div>
          )}
          {showNewPass && (
            <div>
              <Row className="NewPassSection">
                <Col md={11} xs={24} className="">
                  <Form.Item
                    name="newPassword"
                    rules={[
                      {
                        validator: validatePassword,
                      },
                    ]}
                  >
                    <div className="sett_borderText">
                      <Input.Password
                        bordered={false}
                        placeholder="New Password"
                        className="sett_inputTextClass"
                      />
                    </div>
                  </Form.Item>
                </Col>

                <Col md={12} xs={24} className="">
                  <Form.Item
                    name="reenterNewPassword"
                    rules={[
                      {
                        validator: validatePassword,
                      },
                    ]}
                  >
                    <div className="sett_borderText">
                      <Input.Password
                        bordered={false}
                        placeholder="Re-enter new Password"
                        className="sett_inputTextClass"
                      />
                    </div>
                  </Form.Item>
                </Col>
              </Row>
              {/* <Row>
                <Col md={11} xs={24} className="">
                  <p className="passTExt">
                    Passwords must have at least 8 characters, 1 lowercase, 1
                    upper case, 1 number, and 1 special character
                  </p>
                </Col>
              </Row> */}
            </div>
          )}
        </Form>
      </div>
    </Modal>
  );
};

export default Settings;
