import React, { useContext, useEffect, useState } from "react";
import {
  Layout,
  Space,
  Button,
  Row,
  Col,
  Input,
  Select,
  Form,
  notification,
} from "antd";

import "./ApplicationsOpened.scss";
import ApplnTabView from "../ApplnTabs/ApplnTabs";
import ApplicationHeader from "../Header/Header";
import PrivacyPolicy from "../PrivayPolicy/PrivacyPolicy";
import FixedFooter from "../Footer/Footer";

const { Header, Footer, Sider, Content } = Layout;
const size = "large";

const ApplicationOpened = () => {
  return (
    <Layout className="Applications-Open-layout">
      <ApplicationHeader></ApplicationHeader>
      <ApplnTabView></ApplnTabView>
   
    </Layout>
  );
};

export default ApplicationOpened;
