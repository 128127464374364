import React, { useContext, useEffect, useState } from "react";
import {
  Layout,
  Space,
  Button,
  Row,
  Col,
  Input,
  Select,
  Form,
  notification,
  Tooltip,
  Dropdown,
} from "antd";
import {
  AppstoreOutlined,
  DownOutlined,
  UpOutlined,
  MailOutlined,
  SettingOutlined,
} from "@ant-design/icons";

import type { MenuProps } from "antd";
import { Menu } from "antd";
import deleleIcon from "../../Assets/Images/deleteiconSvg.svg";

import "./FixedHeaderCasesTable.scss";
import CreateCase from "../CreateCase/CreateCase";
import EmailApplicant from "../EmailApplicant/EmailApplicant";
import AddApplicationsToExisting from "../AddApplicationsExistingCase/AddApplicationsExistingCase";
import AddaNotePopup from "../AddAnotePopup/AddAnotePopup";
import FileSAR from "../FileSAR/FileSAR";
import AddAttachementPopup from "../AddAttachementPopup/AddAttachementPopup";
import DeleteFilter from "../DeleteFilter/DeleteFilter";
import { ApplicationContext } from "../../Context/ApplicationContextProvider";
import {
  HighPriorityCaseFilterValue,
  IFilterItem,
} from "../../Contracts/Contracts";
import {
  getValueFromLocalStorage,
  removeValueFromLocalStorage,
  setValueInLocalStorage,
} from "../Utility/Utility";
import ic_arrow_down from "../../Assets/Images/icons/ic_arrow_down.svg";
import ic_arrow_up from "../../Assets/Images/icons/ic_arrow_up.svg";

const { Header, Footer, Sider, Content } = Layout;
const size = "large";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

// const items: MenuProps["items"] = [
//   getItem("Take action", "sub2", <AppstoreOutlined />, [
//     getItem("Request for Information", "1"),
//     getItem("File SAR", "2"),
//     getItem("Add attachment", "3"),
//     getItem("Add note", "4"),
//     getItem("Export to PDF", "5"),
//   ]),
// ];

type FilterProps = {
  currentFilterName?: any;
  setDeleteFilter?: any;
  setFilterValues?: any;
};

const FixedHeaderCasesTable = (props: FilterProps) => {
  const [showSARDId, setshowSARDId] = useState("false");
  const [showPopup, setShowPopup] = useState(false);
  const [showNotePopup, setShowNotePopup] = useState(false);
  const [showAddAttchPopup, setShowAddAttchPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [isDeleteFilter, setIsDeleteFilter] = useState(false);
  const [deletedById, setDeletedById] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSavedDropdownOpen, setIsSavedDropdownOpen] = useState(false); // Track dropdown open state
  // const [savedCaseFilterNames,setSavedCaseFilterNames] = useState();
  const [showEmailPopup, setShowEmailPopup] = useState(false);

  useEffect(() => {
    const savedFilters = JSON.parse(
      getValueFromLocalStorage("savedCasesFiltersName") || "[]"
    );
    const savedFiltersValues = JSON.parse(
      getValueFromLocalStorage("caseTableFilterData") || "[]"
    );
    const filterIdToCheck = 1;
    const filterDataExists = savedFiltersValues.some(
      (filter: any) => filter.filterId === filterIdToCheck
    );
    if (!filterDataExists) {
      savedFiltersValues.push(HighPriorityCaseFilterValue);
      setValueInLocalStorage(
        "caseTableFilterData",
        JSON.stringify(savedFiltersValues)
      );
    }
    const defaultFilterData = { id: "1", name: "High priority cases" };
    const filterExists = savedFilters.some(
      (filter: { id: string }) => filter.id === defaultFilterData.id
    );
    if (!filterExists) {
      savedFilters.push(defaultFilterData);
      setValueInLocalStorage(
        "savedCasesFiltersName",
        JSON.stringify(savedFilters)
      );
    }
  }, []);

  useEffect(() => {
    if (isDeleteFilter) {
      const savedData = JSON.parse(
        getValueFromLocalStorage("caseTableFilterData") || "[]"
      );
      const savedFilterNames = JSON.parse(
        getValueFromLocalStorage("savedCasesFiltersName") || "[]"
      );
      const updatedData = savedData.filter(
        (filter: { filterId: string }) =>
          filter.filterId.toString() !== deletedById
      );
      const updatedFilterNames = savedFilterNames.filter(
        (filter: { id: string }) => filter.id !== deletedById
      );

      const caseDefaultChecked = JSON.parse(
        getValueFromLocalStorage("caseDefaultChecked?") || "{}"
      );
      if (deletedById === caseDefaultChecked.id) {
        removeValueFromLocalStorage("caseDefaultChecked?");
      }
      setValueInLocalStorage(
        "savedCasesFiltersName",
        JSON.stringify(updatedFilterNames)
      );
      setValueInLocalStorage(
        "caseTableFilterData",
        JSON.stringify(updatedData)
      );
      setIsDeleteFilter(false);
      if (props.currentFilterName.id === deletedById) {
        props.setDeleteFilter(true);
      } else if (props.currentFilterName.id !== deletedById) {
        props.setDeleteFilter(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleteFilter]);

  const handlePopup = () => {
    setshowSARDId("");
    setShowPopup(true);
  };
  const handleDeleteFilterPopup = (id: string) => {
    setDeletedById(id);
    // setshowSARDId("");
    setShowDeletePopup(true);
  };

  const onFilterNameClick = (id: string) => {
    const savedFilterData = JSON.parse(
      getValueFromLocalStorage("caseTableFilterData") || "[]"
    );
    const selectedFilter = savedFilterData.find(
      (filter: any) => filter.filterId.toString() === id
    );
    if (selectedFilter) {
      props.setFilterValues(selectedFilter);
    }
  };

  // Create a function to dynamically generate filter items based on state data
  // const generateFilterItems = () => {
  //   const filterList = JSON.parse(getValueFromLocalStorage("savedCasesFiltersName") || "[]");
  //   if (filterList.length <= 0) {
  //     return [getItem("No data found", "1")];
  //   }
  //   return filterList?.map((filter:IFilterItem) =>
  //     getItem(
  //       <div>
  //         <span className="filterName" onClick={()=>onFilterNameClick(filter.id)}>{filter.name}</span>
  //         <img
  //           src={deleleIcon}
  //           alt="articleimg"
  //           className="articleimg-logo deleteImg cursor"
  //           onClick={() => handleDeleteFilterPopup(filter.id)}
  //         />
  //       </div>,
  //       filter.id
  //     )
  //   );
  // };
  // const itemsFilters: MenuProps["items"] = [
  //   getItem("Saved filters", "sub2", <AppstoreOutlined />, generateFilterItems()),
  // ];

  const onClick: MenuProps["onClick"] = (e) => {
    if (e.key == "2") {
      handlePopup();
    } else if (e.key == "4") {
      setShowNotePopup(true);
    } else if (e.key == "3") {
      setShowAddAttchPopup(true);
    } else if (e.key == "1") {
      setShowEmailPopup(true);
    }
  };

  const takeActionmenu = (
    <Menu onClick={onClick} className="dropdown-options">
      <Menu.Item disabled key="1">
        Request for Information
      </Menu.Item>
      <Menu.Item disabled key="2">
        File SAR
      </Menu.Item>
      <Menu.Item disabled key="3">
        Add attachment
      </Menu.Item>
      <Menu.Item disabled key="4">
        Add note
      </Menu.Item>
      <Menu.Item disabled key="5">
        Export to PDF
      </Menu.Item>
    </Menu>
  );

  const getMenuOptions = () => {
    const filterList = JSON.parse(
      getValueFromLocalStorage("savedCasesFiltersName") || "[]"
    );
    if (filterList.length <= 0) {
      return <Menu.Item>No data found</Menu.Item>;
    }
    const listItems = filterList?.map((filter: IFilterItem, index: number) => (
      <Menu.Item key={index} className="menu-item">
        <span
          className="filterName"
          onClick={() => onFilterNameClick(filter.id)}
        >
          {filter.name}
        </span>
        <img
          src={deleleIcon}
          alt="articleimg"
          className="articleimg-logo deleteImg cursor delete-icon"
          onClick={() => handleDeleteFilterPopup(filter.id)}
        />
      </Menu.Item>
    ));
    return listItems;
  };

  const savedFilterMenu = (
    <Menu className="dropdown-options">
      {/* <Menu.Item key="1"></Menu.Item> */}
      {getMenuOptions()}
    </Menu>
  );

  const handleDropdownVisibleChange = (visible: boolean) => {
    setIsDropdownOpen(visible);
  };

  const handleSavedDropdownVisibleChange = (visible: boolean) => {
    setIsSavedDropdownOpen(visible);
  };

  return (
    <div className="fixed_portal_sec">
      <div className="header_fixed">
        <div className="fheader_images floatLeft">
          <span className="appnumber">Cases</span>
        </div>
        <div className="menuSections floatRight">
          <div className="paymore floatLeft">
            {/* <Menu
              onClick={onClick}
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["sub1"]}
              mode="inline"
              items={items}
              key={1}
            /> */}
            <Dropdown
              overlay={takeActionmenu}
              trigger={["click"]}
              className="dropdown-takeaction"
              onOpenChange={handleDropdownVisibleChange}
            >
              <Button className="dropdown-btn">
                Take action{" "}
                <img
                  src={isDropdownOpen ? ic_arrow_up : ic_arrow_down}
                  alt="icon arrow"
                />
              </Button>
            </Dropdown>
          </div>
          <div className="paymore_filters floatRight">
            {/* <Menu
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["sub1"]}
              mode="inline"
              items={itemsFilters}
              key={1}
            /> */}
            <Dropdown
              overlay={savedFilterMenu}
              trigger={["click"]}
              className="dropdown-takeaction"
              onOpenChange={handleSavedDropdownVisibleChange}
            >
              <Button className="dropdown-btn">
                Saved filters{" "}
                <img
                  src={isSavedDropdownOpen ? ic_arrow_up : ic_arrow_down}
                  alt="icon arrow"
                />
              </Button>
            </Dropdown>
          </div>
        </div>
      </div>
      <EmailApplicant
        showPopup={showEmailPopup}
        popupHeading="Request for Information"
        checkboxText="Include case"
        setShowPopup={setShowEmailPopup}
      ></EmailApplicant>
      <FileSAR
        showPopup={showPopup}
        setShowPopup={setShowPopup}
        showSARDId={showSARDId}
        setshowSARDId={setshowSARDId}
      />
      <AddaNotePopup
        showPopup={showNotePopup}
        setShowPopup={setShowNotePopup}
      ></AddaNotePopup>
      <AddAttachementPopup
        showPopup={showAddAttchPopup}
        setShowPopup={setShowAddAttchPopup}
      ></AddAttachementPopup>
      <DeleteFilter
        showPopup={showDeletePopup}
        setShowPopup={setShowDeletePopup}
        setIsDeleteFilter={setIsDeleteFilter}
      ></DeleteFilter>
    </div>
  );
};

export default FixedHeaderCasesTable;
