import React, { useContext, useEffect, useState } from "react";
import { ApplicationContext } from "../../Context/ApplicationContextProvider";
import {
  Layout,
  Space,
  Button,
  Row,
  Col,
  Input,
  Select,
  Form,
  Collapse,
  CollapseProps,
  theme,
  DatePicker,
  Modal,
  Checkbox,
  Alert,
} from "antd";

import "./SaveFilterPopup.scss";

import saveFilter from "../../Assets/Images/saveBg.png";
import {
  getValueFromLocalStorage,
  setValueInLocalStorage,
} from "../Utility/Utility";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

const { Header, Footer, Sider, Content } = Layout;
type FinalPopupProps = {
  showPopup: boolean;
  setShowPopup: (value: boolean) => void;
  filterType?: string;
  setOnSaveClick?: (value: boolean) => void;
  setCodeNumber?: any;
  setCaseNumber?: any;
  setSarFilter?: any;
  setCurrentFilterName?: any;
};
const SaveFilterPopup = (popupProps: FinalPopupProps) => {
  const [name, setName] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [makeDefault, setMakeDefault] = useState<boolean>(false);
  const handleOk = () => {
    popupProps.setShowPopup(false);
  };

  const handleCancel = () => {
    popupProps.setShowPopup(false);
  };

  const casesFilterList = JSON.parse(
    getValueFromLocalStorage("savedCasesFiltersName") || "[]"
  );
  const applicationFilterList = JSON.parse(
    getValueFromLocalStorage("savedAppFiltersName") || "[]"
  );
  const SARsFilterList = JSON.parse(
    getValueFromLocalStorage("savedSarsFiltersName") || "[]"
  );

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    setMakeDefault(e.target.checked);
  };

  const handleSave = async () => {
    if (!name.trim()) {
      setError("Please enter a name for the filter");
      return; // Don't proceed with save if the name is empty
    }

    const filterType = popupProps.filterType?.toLowerCase();
    const filterList =
      filterType === "case"
        ? casesFilterList
        : filterType === "sars"
        ? SARsFilterList
        : applicationFilterList;

    const filterExists = filterList.some(
      (filter: { name: string }) => filter.name === name
    );

    if (filterExists) {
      setError("Filter with the same name already exists.");
    } else {
      setError("");
      const id =
        filterType === "case"
          ? casesFilterList.length + 1
          : filterType === "sars"
          ? SARsFilterList.length + 1
          : applicationFilterList.length + 1;
      const newFilterItem = { id: id.toString(), name: name };
      const makeByDefaultValue = {
        id: id.toString(),
        name: name,
        makeDefault: makeDefault.toString(),
      };
      popupProps.setCurrentFilterName(newFilterItem);

      if (filterType === "case") {
        casesFilterList.push(newFilterItem);
        setValueInLocalStorage(
          "savedCasesFiltersName",
          JSON.stringify(casesFilterList)
        );
        if (makeDefault) {
          setValueInLocalStorage(
            `${filterType}DefaultChecked?`,
            JSON.stringify(makeByDefaultValue)
          );
        }
      } else if (filterType === "sars") {
        SARsFilterList.push(newFilterItem);
        setValueInLocalStorage(
          "savedSarsFiltersName",
          JSON.stringify(SARsFilterList)
        );
        if (makeDefault) {
          setValueInLocalStorage(
            `${filterType}DefaultChecked?`,
            JSON.stringify(makeByDefaultValue)
          );
        }
      } else {
        applicationFilterList.push(newFilterItem);
        setValueInLocalStorage(
          "savedAppFiltersName",
          JSON.stringify(applicationFilterList)
        );
        if (makeDefault) {
          setValueInLocalStorage(
            `${filterType}DefaultChecked?`,
            JSON.stringify(makeByDefaultValue)
          );
        }
      }

      if (popupProps.setOnSaveClick) {
        await popupProps.setOnSaveClick(true);
      }
      if (popupProps.setCodeNumber) {
        await popupProps.setCodeNumber(false);
      }
      if (popupProps.setCaseNumber) {
        await popupProps.setCaseNumber(false);
      }
      if (popupProps.setSarFilter) {
        await popupProps.setSarFilter(false);
      }
      await popupProps.setShowPopup(false);
      await setMakeDefault(false);
    }
  };

  return (
    <Modal
      centered
      open={popupProps.showPopup}
      onOk={handleOk}
      onCancel={handleCancel}
      width={900}
      title="Save Filters"
      className="passHeaderfix widthOfPopup"
      footer={[
        <div
          style={{ width: "200px" }}
          className="btn-apply floatRight"
          onClick={handleSave}
        >
          Save
        </div>,
      ]}
    >
      <div className="save-container">
        <Row>
          <Col md={24} xs={24}>
            <Form.Item
              name="firstname"
              rules={[
                {
                  required: true,
                  message: "First Name is required",
                },
              ]}
            >
              <div className="input-form__wrapper">
                <Input
                  className="input-form"
                  placeholder="Give your filter a meaningful name"
                  onChange={(e) => {
                    setName(e.target.value);
                    setError("");
                  }}
                />
                {name && <span className="customLabel">Name</span>}
              </div>
            </Form.Item>
          </Col>
        </Row>
        <div className="alert-message">
          {error && <Alert message={error} type="error" />}
        </div>
        <Row>
          <Col md={14} xs={24}>
            <Checkbox
              className="makDefault"
              onChange={handleCheckboxChange}
              checked={makeDefault}
            >
              Make default
            </Checkbox>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default SaveFilterPopup;
