import { frameQueryStringKeyValuePairs } from "./Utility";

export class ApiEndpoints {
  public static MasterData = {
    getLegalType() {
      return `todos/1`;
    },
    getDocumentType() {
      return `masterdata/documenttypes?CountryCode=USA`;
    },
    getIndustries() {
      return `masterdata/industries`;
    },
  };

  public static Geolocation = {
    setGeolocation() {
      return `geolocation/validate`;
    },
  };

  public static PhoneAuthentication = {
    getOtp() {
      return `auth/generateotp`;
    },
  };

  public static ValidateOtp = {
    validateOtp() {
      return `auth/validateotp`;
    },
  };

  public static UnderstandingUpload = {
    setUnderstandingUpload(
      documentTypeId: number,
      countrycode: string,
      legalTypeId: number
    ) {
      return `understandingUpload?documentTypeId=${documentTypeId}&countrycode=${countrycode}&legalTypeId=${legalTypeId}`;
    },
  };

  public static Profile = {
    getPersonalInfo() {
      return `profile/getprofile`;
    },
    postPersonalInfo() {
      return `profile/postprofile`;
    },
    getUserAppStatus() {
      return `profile/user-application-status`;
    },
  };

  public static Business = {
    getBusinessInfo() {
      return `business/businesses`;
    },
    postBusinessInfo() {
      return `business/businesses`;
    },
    setBeneficiaryOwnerInvitation() {
      return `/business/beneficiaryownersinvitations`;
    },
  };
}
