export interface IStepsHeader {
  title: string;
  titleClass: string;
  img: any;
}

export interface IApprouteProps {
  online: boolean;
}

export interface IFooterProps {
  inviteClick?: () => void;
  uploadDocuments?: () => void;
  gobackPath: string;
  nextPath: string;
  onClick?: () => void;
  buttonName?: string;
  form?: any;
  loading?: boolean;
}

export interface IFilterItem {
  id: string;
  name: string;
}

export interface IGeoLocProps {
  clat: number;
  clong: number;
}

export interface IUnderStandingUploadProps {
  image: any;
}

export interface IUnderStandingUploadQueryProps {
  documentTypeId: number;
  countrycode: string;
  legalTypeId: number;
}

export interface IValidateOtpDetails {
  Phone: string;
  SecurityKey: string;
  Reason: string;
}

export interface IPersonalInfoPostDetails {
  SSN: string;
  DateOfBirth: string;
  Email: string;
  FirstName: string;
  LastName: string;
  Phone: string;
  State: string;
  Id: string;
  UserId: string;
  Zip: string;
  Address: string;
  User: string;
}
export interface IPersonalInfoFormValues {
  firstname: string;
  middlename: string;
  lastname: string;
  SSN: string;
  dateofbirth: string | any;
  phone: string;
  email: string;
  zip: string;
  state: string;
  city: string;
  streetaddress1: string;
  streetaddress2: string;
}

export interface IBusinessInfoFormValues {
  name: string;
  einTin: string;
  legalTypeId: number;
  industryId: number;
  phone: string;
  email: string;
  zip: string;
  state: string;
  city: string;
  addressLine1: null | string;
  addressLine2: null | string;
}

export interface IBusinessPostInfoValues {
  name: null | string;
  einTin: null | string;
  email: null | string;
  phone: string;
  address: {
    addressLine1: null | string;
    addressLine2: null | string;
    state: null | string;
    city: null | string;
    zip: null | string;
  };
  legalTypeId: number;
  industryId: number;
}
export const businessInfoInitialvalues: IBusinessInfoFormValues = {
  name: "",
  einTin: "",
  email: "",
  phone: "",
  addressLine1: "",
  addressLine2: "",
  state: "",
  city: "",
  zip: "",
  legalTypeId: 1,
  industryId: 0,
};

export const personalInfoValues: IPersonalInfoFormValues = {
  firstname: "",
  middlename: "",
  lastname: "",
  SSN: "",
  dateofbirth: "",
  phone: "",
  email: "",
  zip: "",
  state: "",
  city: "",
  streetaddress1: "",
  streetaddress2: "",
};

export interface IPersonalInfoFormFinalStepValues {
  firstName: string;
  lastName: string;
  SSN: string;
  dateOfBirth: string | any;
  phone: string;
  ssn: any;
  email: string;
  zip: string | null;
  state: string;
  userId: string;
  city: string;
  addressLine1: string;
  addressLine2: string;
  profileImage: any;
}

export interface IDocumentsValue {
  documentType: {
    documentTypeId: number;
    documentTypeName: string;
  }[];
  legaltype: {
    legalTypeId: number;
    legalTypeName: string;
  };
}
export const businessInfoValues: IBusinessInfoFormValues = {
  name: "AVIA",
  einTin: "27657",
  email: "avia@global.com",
  phone: "8768979899",
  addressLine1: "doorno-233,d-block, Austin",
  addressLine2: "doorno-233,d-block, Austin",
  state: "UT",
  city: "Austin",
  zip: "78990",
  legalTypeId: 2,
  industryId: 0,
};

export interface IAddIndividualValues {
  firstname: string;
  lastname: string;
  phone: string;
  email: string;
}

export interface ISendOtp {
  MobileNumber: string;
}

export interface IValidateOtp {
  MobileNumber: string;
  OTP: string;
}

export interface IBeneficiaryOwnerInviteValue {
  businessUsers: {
    email: string;
    firstname: string;
    lastname: string;
    phone: string;
    userType: string;
  }[];
}

export interface ICreateCaseFormValues {
  // category: string;
  // assignee: string;
  // priority: string;
  tag: string;
  dueDate: string | any;
  desc: string;
}

export const createCaseFormValues: ICreateCaseFormValues = {
  // category: "",
  // assignee: "",
  // priority: "",
  tag: "",
  dueDate: "",
  desc: "",
};

export interface IFilterValues {
  ID: string;
  Fname: string;
  Bname: string;
  ssn: string;
  Lname: string;
  businessAddress: string;
  tin: string;
  dob: Date | null;
  industry: string;
  phone: string;
  address: string;
  Status: string;
  email: string;
  beneficialOwnership: string;
  assignee: string;
  filterId?: string;
}

export interface ICaseTableFilterValues {
  Cdate: any;
  Udate: any;
  ID: string;
  Status: string;
  Creat: string;
  Cat: string;
  assignee: string;
  Priority: string;
  filterId?: string;
}

export interface ISelectedRows {
  key: React.Key;
  ID: string;
  Cdate: any;
  Udate: any;
  Bname: string;
  Fname: string;
  Lname: string;
  Status: string;
}

export interface IReportSarsFilterValues {
  ID: string;
  Cdate: any;
  Udate: any;
  Bname: string;
  Status: string;
  Tin: string;
  Cat: string;
  Creat: string;
  filterId?: string;
}

export const InitialApplicationFilterValue = {
  ID: "",
  Fname: "",
  Bname: "",
  ssn: "",
  Lname: "",
  businessAddress: "",
  tin: "",
  dob: null,
  industry: "",
  phone: "",
  address: "",
  Status: "",
  email: "",
  beneficialOwnership: "",
  assignee: "",
  filterId: "",
};

export const NewApplicationFilterValue = {
  ID: "",
  Fname: "",
  Bname: "",
  ssn: "",
  Lname: "",
  businessAddress: "",
  tin: "",
  dob: null,
  industry: "",
  phone: "",
  address: "",
  Status: "New",
  email: "",
  beneficialOwnership: "",
  assignee: "",
  filterId: 1,
};

export const InitialCaseFilterValue = {
  ID: "",
  Status: "",
  assignee: "",
  Creat: "",
  Cat: "",
  Cdate: "",
  Udate: "",
  Priority: "",
  filterId: "",
};

export const HighPriorityCaseFilterValue = {
  ID: "",
  Status: "",
  assignee: "",
  Creat: "",
  Cat: "",
  Cdate: "",
  Udate: "",
  Priority: "High",
  filterId: 1,
};

export const InitialSARsFilterValue = {
  ID: "",
  Cdate: "",
  Udate: "",
  Bname: "",
  Status: "",
  Cat: "",
  Tin: "",
  Creat: "",
  filterId: "",
};

export const defaultSARsFilterValue1 = {
  ID: "",
  Cdate: "",
  Udate: "",
  Bname: "",
  Status: "Ready for review",
  Cat: "",
  Tin: "",
  Creat: "",
  filterId: 1,
};

export const defaultSARsFilterValue2 = {
  ID: "",
  Cdate: "",
  Udate: "",
  Bname: "",
  Status: "Failed",
  Cat: "",
  Tin: "",
  Creat: "",
  filterId: 2,
};

export interface IAppTableFilterValuesWithKey {
  ID: string;
  Fname: string;
  Bname: string;
  ssn: string;
  Lname: string;
  businessAddress: string;
  tin: string;
  dob: any;
  industry: string;
  phone: string;
  address: string;
  Status: string;
  email: string;
  beneficialOwnership: string;
  assignee: string;
  [key: string]: string | null;
  filterId?: any;
}

export interface ICaseTableFilterValuesWithKey {
  Cdate: any;
  Udate: any;
  ID: string;
  Status: string;
  Creat: string;
  Cat: string;
  assignee: string;
  Priority: string;
  [key: string]: string | null;
  filterId?: any;
}

export interface IReportSarsFilterValuesWithKey {
  ID: string;
  Cdate: any;
  Udate: any;
  Bname: string;
  Status: string;
  Tin: string;
  Cat: string;
  Creat: string;
  [key: string]: string | null;
  filterId?: any;
}
