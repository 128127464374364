import React, { createContext, useContext, useState } from "react";
import { LoquatClient } from "../../API/LoquatClient";
import { IFilterItem } from "../Contracts/Contracts";

interface DocumentType {
  documentTypeId: number;
  documentTypeName: string;
  id: number;
  value: string;
}

interface LegalType {
  legalTypeId: number;
  legalTypeName: string;
}
interface ApplicationContext {
  auth: { ls: boolean; loadingUser: boolean };
  setAuth: (auth: { ls: boolean; loadingUser: boolean }) => void;
  userId: string;
  setUserId: (userId: string) => void;
  documentData: {
    documentTypes: DocumentType[];
    legalType: LegalType;
  };
  setDocumentData: (documentData: {
    documentTypes: DocumentType[];
    legalType: LegalType;
  }) => void;
  getLoquatClient: () => LoquatClient;
  // applicationFilterList: IFilterItem[];
  // setApplicationFilterList: (value: IFilterItem[]) => void;
  // casesFilterList: IFilterItem[];
  // setCasesFilterList: (value: IFilterItem[]) => void;
  globalFilterStatus: boolean;
  setGlobalFillterStatus: (value: boolean) => void;
  // isAcceptEnabled : boolean,
  isApproveDeclineEnabled: boolean;
  // enableAccept:(value:boolean)=>void,
  enableApproveDecline: (value: boolean) => void;
  // SARsFilterList: IFilterItem[];
  // setSARsFilterList: (value: IFilterItem[]) => void;
}

const stub = (): never => {
  throw new Error(
    "You forgot to wrap your component in <ApplicationContext Provider>."
  );
};

const applicationDefaultValue: ApplicationContext = {
  auth: { ls: false, loadingUser: true },
  setAuth: () => {
    // return void
  },

  userId: "",
  setUserId: () => {
    //return void
  },
  documentData: {
    documentTypes: [],
    legalType: { legalTypeId: 0, legalTypeName: "" },
  },
  setDocumentData: () => {},
  getLoquatClient: stub,
  // applicationFilterList: [],
  // setApplicationFilterList: () => {},
  // casesFilterList: [],
  // setCasesFilterList: () => {},
  globalFilterStatus: false,
  setGlobalFillterStatus: () => {},
  isApproveDeclineEnabled: false,
  enableApproveDecline: () => {},
  // SARsFilterList: [],
  // setSARsFilterList: () => {},
};

export const ApplicationContext = createContext<ApplicationContext>(
  applicationDefaultValue
);

export const ApplicationContextProvider = ({ config, children }: any) => {
  const [auth, setAuth] = useState(applicationDefaultValue.auth);
  const [userId, setUserId] = useState<string>(applicationDefaultValue.userId);
  // const [isAcceptEnabled, setIsAcceptEnabled] = useState(true);
  const [isApproveDeclineEnabled, setIsApproveDeclineEnabled] = useState(false);
  const [documentData, setDocumentData] = useState<{
    documentTypes: DocumentType[];
    legalType: LegalType;
  }>(applicationDefaultValue.documentData);
  const [applicationFilterList, setApplicationFilterList] = useState<IFilterItem[]>([
    { id: "1", name: "New applications" },
  ]);
  const [casesFilterList, setCasesFilterList] = useState<IFilterItem[]>([]);
  const [SARsFilterList, setSARsFilterList] = useState<IFilterItem[]>([]);
  const [globalFilterStatus, setGlobalFillterStatus] = useState(false);

  const liquatClient = new LoquatClient(config.baseURL, config);

  const getLoquatClient = () => {
    return liquatClient;
  };

  const enableApproveDecline = (value:boolean) => {
    setIsApproveDeclineEnabled(value);
  };
  
  const value: ApplicationContext = {
    auth,
    setAuth,
    userId,
    setUserId,
    documentData,
    setDocumentData,
    getLoquatClient,
    // applicationFilterList,
    // setApplicationFilterList,
    // casesFilterList,
    // setCasesFilterList,
    globalFilterStatus,
    setGlobalFillterStatus,
    isApproveDeclineEnabled,
    enableApproveDecline,
    // SARsFilterList,
    // setSARsFilterList,
  };

  return (
    <ApplicationContext.Provider value={value}>
      {children}
    </ApplicationContext.Provider>
  );
};
