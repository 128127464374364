import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { ApplicationContext } from "../../Context/ApplicationContextProvider";
import {
  Layout,
  Space,
  Button,
  Row,
  Col,
  Input,
  Table,
  Form,
  Collapse,
  CollapseProps,
  Select,
  DatePicker,
  Modal,
  Checkbox,
  Dropdown,
} from "antd";

import type { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import ascend from "../../Assets/Images/ascendSvg.svg";

import "./CreateCaseTablePopup.scss";
import exportIcon from "../../Assets/Images/exporticonSvg.svg";
import ClientFilter from "../../Components/ClientsFilter/ClientFilters";
import progress from "../../Assets/Images/Inprogress.png";
import search from "../../Assets/Images/searchSvg.svg";
import filtericon from "../../Assets/Images/filtericonSvg.svg";
import filtercross from "../../Assets/Images/filterCrossSvg.svg";

import { DownOutlined, UpOutlined } from "@ant-design/icons";

import rightVector from "../../Assets/Images/rightvectorSvg.svg";
import leftVector from "../../Assets/Images/leftvectorSvg.svg";
import cancel from "../../Assets/Images/cancelBg.png";
import confirm from "../../Assets/Images/createTableBg.png";
import Casefilters from "../CaseFilter/CaseFilter";
import { CommonValues } from "../Common/Base";
import {
  ICreateCaseFormValues,
  ISelectedRows,
} from "../../Contracts/Contracts";
import appTableJsonData from "../ApplicationTable/ApplicationTableData.json";
const { Header, Footer, Sider, Content } = Layout;
const { TextArea } = Input;

interface DataType {
  key: React.Key;
  ID: string;
  Cdate: any;
  Udate: any;
  Cat: string;
  Creat: string;
  Due: string;
  Prior: string;
  Status: string;
}
//   {
//     key: "1",
//     ID: "AML-123456789",
//     Cdate: "8/15/2023 7:37 PM",
//     Udate: "08/15/2023 7:37 PM",
//     Cat: "AML",
//     Creat: "John Smith",
//     Due: "11/15/2023",
//     Prior: "High",
//     Status: "UNDECIDED",
//   },
//   {
//     key: "2",
//     ID: "FRD-123456789",
//     Cdate: "8/15/2023 7:37 PM",
//     Udate: "08/20/2023 10:09 AM",
//     Cat: "Fraud",
//     Creat: "James Williams",
//     Due: "11/15/2023",
//     Prior: "Medium",
//     Status: "UNDECIDED",
//   },
//   {
//     key: "3",
//     ID: "OFC-123456789",
//     Cdate: "8/15/2023 7:12 PM",
//     Udate: "08/20/2023 10:00 AM",
//     Cat: "OFAC",
//     Creat: "James Williams",
//     Due: "11/15/2023",
//     Prior: "Low",
//     Status: "UNDECIDED",
//   },
// ];
// rowSelection object indicates the need for row selection
const rowSelection = {
  onChange: (selectedRowKeys: React.Key[], selectedRows: ISelectedRows[]) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
};
const { RangePicker } = DatePicker;
const dateFormat = "MM/DD/YYYY";
type FinalPopupProps = {
  showPopup: boolean;
  setShowPopup: (value: boolean) => void;
  saveCreatedData?: any;
  data?: any;
};
const CreateCaseTablePopup = (popupProps: FinalPopupProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [codeNumber, setCodeNumber] = useState(false);
  const [selectionType, setSelectionType] = useState<"checkbox" | "radio">(
    "checkbox"
  );
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const dateFormat = CommonValues.dateFormat;
  const [caseFormValues, setCaseFormValues] = useState<
    ICreateCaseFormValues | undefined
  >();
  const [category, setCategory] = useState<string | undefined>(undefined);
  const [assignee, setAssignee] = useState<string | undefined>(undefined);
  const [priority, setPriority] = useState<string | undefined>(undefined);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleOk = () => {
    popupProps.setShowPopup(false);
  };

  const handleCancel = () => {
    popupProps.setShowPopup(false);
  };

  const handleInputChange = (fieldName: string, value: any) => {
    let updatedValues: ICreateCaseFormValues | undefined;
    if (caseFormValues) {
      updatedValues = {
        ...caseFormValues,
        [fieldName]: value,
      };
    } else {
      updatedValues = {
        // category: "",
        // assignee: "",
        // priority: "",
        tag: "",
        dueDate: null,
        desc: "",
        [fieldName]: value,
      };
    }
    form.setFieldsValue(updatedValues);
    setCaseFormValues(updatedValues);
  };

  const handleCategoryChange = (value: string) => {
    setCategory(value);
  };

  const handleAssigneeChange = (value: string) => {
    setAssignee(value);
  };

  const handlePriorityChange = (value: string) => {
    setPriority(value);
  };

  function formatDate(dateString: string | number | Date) {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    const formattedDate = new Date(dateString).toLocaleDateString(
      "en-US",
      options as Intl.DateTimeFormatOptions
    );
    return formattedDate;
  }
  const onCreateCaseFinish = async () => {
    const dueDate = caseFormValues?.dueDate
      ? new Date(caseFormValues.dueDate)
      : new Date();
    const currentDate = new Date();
    const formattedCurrentDate = currentDate.toLocaleString("en-US", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    // const formattedDate = dueDate.toLocaleString("en-US", {
    //   year: "numeric",
    //   month: "numeric",
    //   day: "numeric",
    //   hour: "numeric",
    //   minute: "numeric",
    //   hour12: true,
    // });
    const formattedDueDate = formatDate(caseFormValues?.dueDate);
    const randomNumber = Math.floor(Math.random() * 900000000) + 100000000;

    const postData = {
      Cat: category,
      Assignee: assignee,
      Priority: priority,
      Tag: caseFormValues?.tag,
      // dueDate: formattedDueDate,
      DueDate: caseFormValues?.dueDate ? formattedDueDate : "",
      Description: caseFormValues?.desc,
      Cdate: formattedCurrentDate,
      Udate: formattedCurrentDate,
      ID: "FRD-" + randomNumber,
      Creat: "John Smith",
      Status: "UNDECIDED",
    };
    popupProps.saveCreatedData(postData);
    handleCancel();
  };

  const openFilter = () => {
    setCodeNumber(!codeNumber);
  };
  const handleGoBack = () => {
    navigate("/openappln");
  };
  const columns: ColumnsType<ISelectedRows> = [
    {
      title: "Application ID",
      dataIndex: "ID",
      // render: (text: string) => (
      //   <a className="firstColumn underline" onClick={handleGoBack}>
      //     {text}
      //   </a>
      // ),
      render: (text: string, row) => {
        return (
          <div style={{ justifyContent: "spaceBetween" }}>
            {row.Fname == "Pawneet" ? (
              <a className="firstColumn underline" onClick={handleGoBack}>
                {text}
              </a>
            ) : (
              <span className="">{text}</span>
            )}
          </div>
        );
      },
    },
    {
      title: "Created",
      dataIndex: "Cdate",
    },
    {
      dataIndex: "Udate",
      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "name"
        );

        return (
          <div style={{ justifyContent: "spaceBetween" }}>
            Last Updated
            {/* <img src={ascend} alt="addcase" className="ascendImg cursor" style={{ width: 6, height: 10}}/> */}
          </div>
        );
      },
    },
    {
      title: "Business Name",
      dataIndex: "Bname",
    },
    {
      title: "First Name",
      dataIndex: "Fname",
    },
    {
      title: "Last Name",
      dataIndex: "Lname",
    },
    {
      title: "Status",
      dataIndex: "Status",
      render: (text: string, row) => (
        // <img src={progress} alt={"404-Notfound"} className="progressImg" />
        <div style={{ justifyContent: "spaceBetween" }}>
          {row.Status == "New" ? (
            <div className="newbg">
              <span className="newtext">NEW</span>
            </div>
          ) : (
            <div className="newprogrss">
              <span className="progresstext">IN PROGRESS</span>
            </div>
          )}
        </div>
      ),
    },
  ];

  const handleDropdownVisibleChange = (visible: boolean) => {
    setIsDropdownOpen(visible);
  };

  const takeActionmenu = (
    <ClientFilter
      // setFilterValues={setFilterValues}
      // filterValues={filterValues}
      setCodeNumber={setCodeNumber}
      isAddApplication={true}
      // setCurrentFilterName={setCurrentFilterName}
      setIsDropdownOpen={setIsDropdownOpen}
    />
  );

  return (
    <Modal
      centered
      open={popupProps.showPopup}
      onOk={handleOk}
      onCancel={handleCancel}
      width={1200}
      title="Create Case"
      className="createHeaderfix"
      footer={[
        <Row className="popBtns marginCreate20">
          <div className="leftBtn">
            <div className="buttonBg floatLeft cursor" onClick={handleCancel}>
              <img
                src={cancel}
                alt="cancel"
                className="buttonWidthCreateTCancel"
              />
              <span className="buttonTextCreateTCancel">Cancel</span>
            </div>
          </div>
          <div className="rightBtn">
            <div
              className="buttonBg floatRight cursor"
              onClick={onCreateCaseFinish}
            >
              <img
                src={confirm}
                alt="confirm"
                className="buttonWidthCreateTCreate"
              />
              <span className="buttonTextTCreate">Create</span>
            </div>
          </div>
        </Row>,
      ]}
    >
      <div className="Apppopup-container addpronew">
        <div className="appTable">
          <Row>
            <span className="headingChose">
              Please select an application(s)
            </span>
          </Row>
          <div className="search-box">
            <img
              src={search}
              alt="search"
              className="search-logoimage cursor"
            />
            <Input className="searchele" placeholder="Search" />
          </div>
          <div className="filter-box">
            <div className="onlyFilterSection">
              <div className="filterleft cursor floatLeft">
                <Dropdown
                  overlay={takeActionmenu}
                  trigger={["click"]}
                  visible={isDropdownOpen}
                  className="dropdown-casesaddAppplication-filters"
                  onOpenChange={handleDropdownVisibleChange}
                >
                  <Button
                    icon={
                      <img
                        src={filtercross}
                        alt="filtercross"
                        className="filtercross-logoimage filter-casesaddAppplication-buttton"
                        onClick={openFilter}
                      />
                    }
                  >
                    <span style={{ marginRight: "30px" }}>Filters</span>
                    {isDropdownOpen ? (
                      <UpOutlined className="up-arrow" />
                    ) : (
                      <DownOutlined />
                    )}
                  </Button>
                </Dropdown>

                {/* <img
                    src={filtercross}
                    alt="filtercross"
                    className="filtercross-logoimage"
                    onClick={openFilter}
                  />
                  <span className="filtersname" onClick={openFilter}>
                    Filters
                  </span>
                  <img
                    src={filtericon}
                    alt="filtericon"
                    className="filtericon-logoimage "
                    onClick={openFilter}
                  />
                   {codeNumber ? (
                <ClientFilter
                  // setFilterValues={setFilterValues}
                  // filterValues={filterValues}
                  setCodeNumber={setCodeNumber}
                  isAddApplication={true}
                  // setCurrentFilterName={setCurrentFilterName}
                />
              ) : (
                ""
              )} */}
                {/* {codeNumber ? <Casefilters isCreateCase={true}/> : ""} */}
              </div>
              <div className="menuSections floatRight">
                <div className="date_borderText floatLeft">
                  <span className="info_span correctSpan">
                    Created Start Date
                  </span>

                  <DatePicker
                    bordered={false}
                    placeholder="12/23/2023"
                    className="callSche callDate cursor alignDateInBox"
                    format={"MM/DD/YYYY"}
                    renderExtraFooter={() => (
                      <div className="alignAnytime">
                        <Checkbox className="anyTime">Anytime</Checkbox>
                      </div>
                    )}
                    showToday={false}
                  />
                </div>
                <span className="toTextDate floatLeft"> to </span>

                <div className="date_borderText floatLeft">
                  <span className="info_span correctSpan">
                    Created End Date
                  </span>

                  <DatePicker
                    bordered={false}
                    placeholder="08/31/2023"
                    className="callSche callDate cursor alignDateInBox"
                    format={"MM/DD/YYYY"}
                    renderExtraFooter={() => (
                      <div className="alignAnytime">
                        <Checkbox className="anyTime">Anytime</Checkbox>
                      </div>
                    )}
                    showToday={false}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <Table
              rowSelection={{
                type: selectionType,
                ...rowSelection,
              }}
              columns={columns}
              dataSource={appTableJsonData.tableList}
              bordered
              pagination={false}
              scroll={{ x: 400 }}
            />
            <div id="pagnations">
              <div className="paginationSection floatLeft cursor marginTopPage">
                <img src={leftVector} alt="leftNav" className="" />
              </div>
              <div>
                <span className="pageNum">
                  1 to {appTableJsonData.tableList.length} of{" "}
                  {appTableJsonData.tableList.length}
                </span>
              </div>
              <div className="paginationSection floatRight cursor marginTopPage">
                <img src={rightVector} alt="rightNav" className="darkerImage" />
              </div>
            </div>
          </div>
        </div>
        <div className="CreateCase margin20">
          <hr className="separateTable"></hr>
          <div className="choseSectn">
            <Form
              form={form}
              onFinish={onCreateCaseFinish}
              scrollToFirstError={true}
            >
              <Row>
                <span className="headingChose choseBottom">Please choose</span>
              </Row>
              <Row>
                <span className="Type">Fraud</span>
                <span className="Type">AML</span>

                <span className="Type">OFAC</span>
              </Row>
              <Row>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 11 }}
                  lg={{ span: 11 }}
                  className="margin20"
                >
                  {" "}
                  <Space wrap className="fullWidth info_borderText">
                    <Select
                      placeholder="Category"
                      options={[
                        { value: "AML", label: "AML" },
                        { value: "Fraud", label: "Fraud" },
                        { value: "OFAC", label: "OFAC" },
                      ]}
                      onChange={handleCategoryChange}
                      value={category}
                    />
                  </Space>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 11 }}
                  lg={{ span: 11 }}
                  className="margin20"
                >
                  {" "}
                  <Space wrap className="fullWidth info_borderText">
                    <Select
                      placeholder="Assignee"
                      options={[
                        {
                          value: "john.smith@loquatinc.io",
                          label: "john.smith@loquatinc.io",
                        },
                        {
                          value: "james.smith@loquatinc.io",
                          label: "james.smith@loquatinc.io",
                        },
                        {
                          value: "bob.smith@loquatinc.io",
                          label: "bob.smith@loquatinc.io",
                        },
                      ]}
                      onChange={handleAssigneeChange}
                      value={assignee}
                    />
                  </Space>
                </Col>
              </Row>
              <Row>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 7 }}
                  lg={{ span: 7 }}
                  className="marginProp"
                >
                  {" "}
                  <Space wrap className="fullWidth info_borderText">
                    <Select
                      placeholder="Priority"
                      options={[
                        { value: "High", label: "High" },
                        { value: "Medium", label: "Medium" },
                        { value: "Low", label: "Low" },
                      ]}
                      onChange={handlePriorityChange}
                      value={priority}
                    />
                  </Space>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 8 }}
                  lg={{ span: 8 }}
                  className="marginTag"
                >
                  {" "}
                  <Space wrap className="fullWidth">
                    <Input
                      className="inputFilter"
                      placeholder="Add tag"
                      onChange={(e) => handleInputChange("tag", e.target.value)}
                      value={caseFormValues?.tag}
                    />
                  </Space>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 7 }}
                  lg={{ span: 7 }}
                  className="marginDue"
                >
                  <div className="">
                    <Space
                      direction="vertical"
                      className="fullWidth info_inputTextClass"
                    >
                      <DatePicker
                        bordered={false}
                        placeholder="Due Date"
                        format={dateFormat}
                        onChange={(value) =>
                          handleInputChange("dueDate", value)
                        }
                        value={caseFormValues?.dueDate}
                      />
                    </Space>
                  </div>
                </Col>
              </Row>
              <Row className="margin20">
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 23 }}
                  lg={{ span: 23 }}
                  className="marginDue"
                >
                  <TextArea
                    rows={3}
                    placeholder="Description"
                    maxLength={6}
                    style={{ resize: "none" }}
                    className="CreateDesc"
                    onChange={(e) => handleInputChange("desc", e.target.value)}
                    value={caseFormValues?.desc}
                  />
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CreateCaseTablePopup;
