import React, { useContext, useEffect, useState } from "react";
import {
  Layout,
  Space,
  Button,
  Row,
  Col,
  Input,
  Select,
  Form,
  Image,
} from "antd";
import "./CaseFilter.scss";

import SaveFilterPopup from "../SaveFilterPopup/SaveFilterPopup";
import { ApplicationContext } from "../../Context/ApplicationContextProvider";
import { InitialCaseFilterValue } from "../../Contracts/Contracts";
import applyimage from "../../Assets/Images/acceptBg.png";
import saveimage from "../../Assets/Images/saveFilterBg.png";
import clearimage from "../../Assets/Images/clearBg.png";
import { ReactComponent as IconArrow } from "../../Assets/Images/icons/ic_big_arrow_down.svg";

import {
  getValueFromLocalStorage,
  setValueInLocalStorage,
} from "../Utility/Utility";

const { Header, Footer, Sider, Content } = Layout;
const size = "large";
type FilterProps = {
  setFilterValues?: any;
  filterValues?: any;
  setCaseNumber?: any;
  setCurrentFilterName?: any;
  isCreateCase?: boolean;
  setIsDropdownOpen?: any;
};
const Casefilters = (props: FilterProps) => {
  const [showPopup, setShowPopup] = useState(false);
  const { setGlobalFillterStatus } = useContext(ApplicationContext);
  const [onSaveClick, setOnSaveClick] = useState(false);
  const [filterData, setFilterData] = useState(InitialCaseFilterValue);

  const updateFilterValue = (fieldName: string, value: any) => {
    setFilterData((prevFilterValues: any) => ({
      ...prevFilterValues,
      [fieldName]: value,
    }));
  };

  useEffect(() => {
    if (props.filterValues) {
      setFilterData(props.filterValues);
    }
  }, [props.filterValues]);

  const onFilterClick = () => {
    if (props.setFilterValues) {
      props.setFilterValues(filterData);
    }
    if (props.setCaseNumber) {
      props.setCaseNumber(false);
    }
    props.setIsDropdownOpen(false);
  };

  const onClearClick = () => {
    setFilterData(InitialCaseFilterValue);
  };

  useEffect(() => {
    if (onSaveClick) {
      if (props.setFilterValues) {
        props.setFilterValues(filterData);
      }
      const savedData = JSON.parse(
        getValueFromLocalStorage("caseTableFilterData") || "[]"
      );
      const filterId = savedData.length + 1;
      filterData.filterId = filterId;
      savedData.push(filterData);
      setValueInLocalStorage("caseTableFilterData", JSON.stringify(savedData));
      setGlobalFillterStatus(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSaveClick]);

  const handlePopup = () => {
    setShowPopup(true);
    props.setIsDropdownOpen(false);
  };
  return (
    <div className="CaseFilters" id="filterSection">
      <div className="wholeSection">
        <Row className="">
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
          >
            <div className="input-form__wrapper">
              <Input
                className="input-form"
                placeholder="Case ID"
                value={filterData.ID}
                onChange={(e) => updateFilterValue("ID", e.target.value)}
              />
              {filterData.ID && <span className="customLabel">Case ID</span>}
            </div>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
          >
            <div className="input-form__wrapper">
              <Select
                suffixIcon={<IconArrow />}
                className="input-form"
                mode="multiple"
                maxTagCount="responsive"
                size={size}
                placeholder="Creator"
                options={[
                  {
                    value: "John Smith",
                    label: "john.smith@loquatinc.io",
                  },
                  { value: "James Smith", label: "james.smith@loquatinc.io" },
                  {
                    value: "Bob Smith",
                    label: "bob.smith@loquatinc.io",
                  },
                ]}
                value={
                  filterData.Creat === "" || filterData.Creat === null
                    ? undefined
                    : filterData.Creat
                }
                // value={filterData.Creat}
                onChange={(value) => updateFilterValue("Creat", value)}
              />
              {filterData.Creat && <span className="customLabel">Creator</span>}
            </div>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
          >
            <div className="input-form__wrapper">
              <Select
                suffixIcon={<IconArrow />}
                className="input-form"
                mode="multiple"
                maxTagCount="responsive"
                size={size}
                placeholder="Priority"
                options={[
                  { value: "High", label: "High" },
                  { value: "Medium", label: "Medium" },
                  { value: "Low", label: "Low" },
                ]}
                value={
                  filterData.Priority === "" || filterData.Priority === null
                    ? undefined
                    : filterData.Priority
                }
                // value={filterData.Priority}
                onChange={(value) => updateFilterValue("Priority", value)}
              />
              {filterData.Priority && (
                <span className="customLabel">Priority</span>
              )}
            </div>
          </Col>
        </Row>

        <Row className="">
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
          >
            <div className="input-form__wrapper">
              <Select
                suffixIcon={<IconArrow />}
                className="input-form"
                mode="multiple"
                maxTagCount="responsive"
                size={size}
                placeholder="Category"
                options={[
                  { value: "AML", label: "AML" },
                  { value: "Fraud", label: "Fraud" },
                  { value: "OFAC", label: "OFAC" },
                ]}
                value={
                  filterData.Cat === "" || filterData.Cat === null
                    ? undefined
                    : filterData.Cat
                }
                // value={filterData.Cat}
                onChange={(value) => updateFilterValue("Cat", value)}
              />
              {filterData.Cat && <span className="customLabel">Category</span>}
            </div>
          </Col>

          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
          >
            <div className="input-form__wrapper">
              <Select
                suffixIcon={<IconArrow />}
                className="input-form"
                mode="multiple"
                maxTagCount="responsive"
                placeholder="Assignee"
                size={size}
                options={[
                  {
                    value: "john.smith@loquatinc.io",
                    label: "john.smith@loquatinc.io",
                  },
                  {
                    value: "james.smith@loquatinc.io",
                    label: "james.smith@loquatinc.io",
                  },
                  {
                    value: "bob.smith@loquatinc.io",
                    label: "bob.smith@loquatinc.io",
                  },
                ]}
                value={
                  filterData.assignee === "" || filterData.assignee === null
                    ? undefined
                    : filterData.assignee
                }
                // value={filterData.assignee}
                onChange={(value) => updateFilterValue("assignee", value)}
              />
              {filterData.assignee && (
                <span className="customLabel">Assignee</span>
              )}
            </div>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
          >
            <div className="input-form__wrapper">
              <Select
                suffixIcon={<IconArrow />}
                className="input-form"
                mode="multiple"
                maxTagCount="responsive"
                placeholder="Status"
                size={size}
                options={[
                  { value: "New", label: "New" },
                  { value: "In Progress", label: "In Progress" },
                  { value: "Closed-Non-Issue", label: "Closed Non-Issue" },
                  { value: "Closed-Issue", label: "Closed Issue" },
                  { value: "Closed-with-SAR", label: "Closed with SAR" },
                ]}
                value={
                  filterData.Status === "" || filterData.Status === null
                    ? undefined
                    : filterData.Status
                }
                // value={filterData.Status}
                onChange={(value) => updateFilterValue("Status", value)}
              />
              {filterData.Status && <span className="customLabel">Status</span>}
            </div>
          </Col>
        </Row>
        <Row className="floatRight marginTop42">
          <div className="btn-apply" onClick={onFilterClick}>
            Apply
          </div>

          {!props.isCreateCase && (
            <div className="btn-save" onClick={handlePopup}>
              Save
            </div>
          )}
          <div className="btn-clear" onClick={onClearClick}>
            Clear
          </div>
        </Row>
        <SaveFilterPopup
          showPopup={showPopup}
          setShowPopup={setShowPopup}
          setOnSaveClick={setOnSaveClick}
          filterType="case"
          setCaseNumber={props.setCaseNumber}
          setCurrentFilterName={props.setCurrentFilterName}
        ></SaveFilterPopup>
      </div>
    </div>
  );
};

export default Casefilters;
