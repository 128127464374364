import React, { useContext, useEffect, useState } from "react";
import { ApplicationContext } from "../../Context/ApplicationContextProvider";
import {
  Layout,
  Space,
  Button,
  Row,
  Col,
  Input,
  Select,
  Form,
  Collapse,
  CollapseProps,
  theme,
  DatePicker,
  Modal,
  Checkbox,
} from "antd";

import "./AddAttachementPopup.scss";
import circle from "../../Assets/Images/circle.png";
import addAttach from "../../Assets/Images/addAttachBg.png";
const { Header, Footer, Sider, Content } = Layout;
const { TextArea } = Input;

type FinalPopupProps = {
  showPopup: boolean;
  setShowPopup: (value: boolean) => void;
};
const AddAttachementPopup = (popupProps: FinalPopupProps) => {
  const handleOk = () => {
    popupProps.setShowPopup(false);
  };

  const handleCancel = () => {
    popupProps.setShowPopup(false);
  };

  return (
    <Modal
      centered
      open={popupProps.showPopup}
      onOk={handleOk}
      onCancel={handleCancel}
      width={900}
      title="Add attachment"
      className="passHeaderfix widthOfPopup"
      footer={[
        <div className="buttonBg floatRight cursor margin35" onClick={handleCancel}>
          <img src={addAttach} alt="addAttach" className="buttonWidthAddAttach" />
          <span className="buttonTextAddAttach">Add attachment +</span>
        </div>,
      ]}
    >
      <div className="add-attach-container">
        <Row>
          <Col md={24} xs={24}>
            <div className="uploadSection cursor">
              <input
                type="file"
                accept=".pdf, .doc, .docx, .jpeg, .jpg"
                style={{ display: "none" }}
              />
              <img
                className="circlecornerimg clickable-icon"
                src={circle}
                alt="Logo"
              />
              <span className="uploadText">
                Upload files from your computer (or drop your files here) <br />
                5 MB max
              </span>
            </div>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default AddAttachementPopup;
