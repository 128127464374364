import React, { useContext, useEffect, useState } from "react";
import "./PrivacyPolicy.scss";
import { ApplicationContext } from "../../Context/ApplicationContextProvider";
import { Layout, Space, Button, Row, Col } from "antd";


const { Header, Footer, Sider, Content } = Layout;

const PrivacyPolicy = () => {
  return (
    <Footer className="privacyfooter">
    <div className="privacybutton-container margin115">
      <div className="privacybutton-group">
        <span className="float-left left-footer-text">Loquat Inc. © 2023 </span>
        <span className="float-right right-footer-text ">
          <span className="margin-right"> Privacy Policy</span>
          <span className=""> Cookies</span>
        </span>
      </div>
    </div>
  </Footer>
  );
};

export default PrivacyPolicy;
