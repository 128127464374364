import React, { useContext, useEffect, useState } from "react";
// import { Layout, Table } from "antd";
import {
  Layout,
  Table,
  Space,
  Button,
  Row,
  Col,
  Image,
  Select,
  Input,
  DatePicker,
  Checkbox,
  Dropdown,
  Tooltip,
} from "antd";
import { useNavigate } from "react-router-dom";
import search from "../../Assets/Images/searchSvg.svg";
import filtericon from "../../Assets/Images/filtericonSvg.svg";
import filtercross from "../../Assets/Images/filterCrossSvg.svg";
import ascend from "../../Assets/Images/ascendSvg.svg";
import filtericonOpen from "../../Assets/Images/filtericonOpenSvg.svg";
import dayjs from "dayjs";
import moment from "moment";
import notFoundIcon from "../../Assets/Images/icons/ic_data_not_found.svg";

import "./CasesTable.scss";
import type { ColumnsType } from "antd/es/table";
import { setDefaultResultOrder } from "dns/promises";
import progress from "../../Assets/Images/Inprogress.png";
import high from "../../Assets/Images/High.png";

import exportIcon from "../../Assets/Images/exporticonSvg.svg";
import CaseFilters from "../CaseFilter/CaseFilter";
import PrivacyPolicy from "../PrivayPolicy/PrivacyPolicy";
import FixedHeaderCasesTable from "../FixedHeaderCasesTable/FixedHeaderCasesTable";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

import ic_clock from "../../Assets/Images/icons/ic_clock.svg";
import rightVector from "../../Assets/Images/rightvectorSvg.svg";
import leftVector from "../../Assets/Images/leftvectorSvg.svg";
import CreateCase from "../CreateCase/CreateCase";
import CreateCaseTablePopup from "../CreateCaseTablePopup/CreateCaseTablePopup";
import caseTableJsonData from "./CaseTableData.json";
import {
  ICaseTableFilterValues,
  ICaseTableFilterValuesWithKey,
  IFilterValues,
  InitialCaseFilterValue,
} from "../../Contracts/Contracts";
import {
  formatDate,
  getValueFromLocalStorage,
  setValueInStorage,
} from "../Utility/Utility";
import exportPDF from "../../Assets/Images/icons/ic_document.svg";
import ic_circle_cross from "../../Assets/Images/icons/ic_circle_cross.svg";
import filterIcon from "../../Assets/Images/filter.svg";
import ic_search from "../../Assets/Images/icons/ic_search.svg";
import ic_big_arrow_down from "../../Assets/Images/icons/ic_big_arrow_down.svg";
import ic_big_arrow_up from "../../Assets/Images/icons/ic_big_arrow_up.svg";
import FilteredTabs from "./FilteredTabs/FilteredTabs";
import { ApplicationContext } from "../../Context/ApplicationContextProvider";
import { ReactComponent as CalendarIcon } from "../../Assets/Images/icons/ic_calendar.svg";

type status = "NEW" | "IN PROGRESS" | "SUCCESSFULL";
type priority = "HIGH" | "MEDIUM" | "LOW";

const STATUS_TAG: Record<status, React.ReactNode> = {
  NEW: (
    <div className="newbg">
      <span className="newtext">NEW</span>
    </div>
  ),
  "IN PROGRESS": (
    <div className="newprogrss">
      <span className="progresstext">IN PROGRESS</span>
    </div>
  ),
  SUCCESSFULL: (
    <div className="newsuccess">
      <span className="successtext">SUCCESSFUL</span>
    </div>
  ),
};

const PRIORITY_TAG: Record<priority, React.ReactNode> = {
  HIGH: <div className="priority high">HIGH</div>,
  MEDIUM: <div className="priority medium">MEDIUM</div>,
  LOW: <div className="priority low">LOW</div>,
};

interface DataType {
  key: React.Key;
  ID: string;
  Cdate: any;
  Udate: any;
  Cat: string;
  Creat: string;
  DueDate: string;
  Priority: string;
  Status: string;
  Assignee?: string;
  Tag?: string;
  Description?: string;
}
const { RangePicker } = DatePicker;
const dateFormat = "MM/DD/YYYY";

let sortedColumn: any;
// rowSelection object indicates the need for row selection
const rowSelection = {
  onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
};

const CasesTable = () => {
  const [filterValues, setFilterValues] =
    useState<ICaseTableFilterValuesWithKey>(InitialCaseFilterValue);
  const [caseNumber, setCaseNumber] = useState(false);
  const [selectionType, setSelectionType] = useState<"checkbox" | "radio">(
    "checkbox"
  );
  const [currentFilterName, setCurrentFilterName] = useState({
    id: "",
    name: "",
  });
  const [deleteFilter, setDeleteFilter] = useState(false);
  const [deletedFilterKey, setDeletedFilterKey] = useState<string | null>(null);
  const { setGlobalFillterStatus, globalFilterStatus } =
    useContext(ApplicationContext);
  const data = caseTableJsonData.createTableList;

  const saveCreatedData = (caseData: DataType) => {
    var result = tableData;
    result.unshift(caseData);
    // data.unshift(caseDate);
    // tableData.push(caseDate);
    setTableData([...result]);
  };

  const [showCreateCasePopup, setShowCreateCasePopup] = useState(false);

  const useSortOrderState = (initialValue: any) => {
    return useState<"ascend" | "descend" | null>(initialValue);
  };

  const [column1SortOrder, setColumn1SortOrder] = useSortOrderState(null);
  const [column2SortOrder, setColumn2SortOrder] = useSortOrderState(null);
  const [column3SortOrder, setColumn3SortOrder] = useSortOrderState(null);
  const [column4SortOrder, setColumn4SortOrder] = useSortOrderState(null);
  const [column5SortOrder, setColumn5SortOrder] = useSortOrderState(null);
  const [column6SortOrder, setColumn6SortOrder] = useSortOrderState(null);
  const [column7SortOrder, setColumn7SortOrder] = useSortOrderState(null);
  const [column8SortOrder, setColumn8SortOrder] = useSortOrderState(null);

  const handleCreateCasePopup = () => {
    setShowCreateCasePopup(true);
  };
  const openFilter = () => {
    setCaseNumber(!caseNumber);
  };
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate("/opencase");
  };

  useEffect(() => {
    const savedFilterValues = JSON.parse(
      getValueFromLocalStorage("caseTableFilterData") || "[]"
    );
    const caseDefaultChecked = JSON.parse(
      getValueFromLocalStorage("caseDefaultChecked?") || "{}"
    );

    // setColumn3SortOrder("ascend");
    if (caseDefaultChecked.makeDefault === "true") {
      const matchingFilter = savedFilterValues.find(
        (item: { filterId: any }) =>
          item.filterId.toString() === caseDefaultChecked.id
      );
      if (matchingFilter) {
        setFilterValues(matchingFilter);
      } else {
        setFilterValues(InitialCaseFilterValue);
      }
    }
    // else if (savedFilterValues.length > 0) {
    //   setFilterValues(savedFilterValues[savedFilterValues.length - 1]);
    // }
    else {
      setFilterValues(InitialCaseFilterValue);
    }
  }, []);

  const toggleSortOrder = (column: string) => {
    const sortOrderMapping: any = {
      ID: [column1SortOrder, setColumn1SortOrder],
      Cdate: [column2SortOrder, setColumn2SortOrder],
      Udate: [column3SortOrder, setColumn3SortOrder],
      Cat: [column4SortOrder, setColumn4SortOrder],
      Creat: [column5SortOrder, setColumn5SortOrder],
      DueDate: [column6SortOrder, setColumn6SortOrder],
      priority: [column7SortOrder, setColumn7SortOrder],
      Status: [column8SortOrder, setColumn8SortOrder],
    };

    for (const columnName in sortOrderMapping) {
      const [sortOrder, setSortOrder] = sortOrderMapping[columnName];
      if (column === columnName) {
        if (sortOrder === "ascend") {
          setSortOrder("descend");
        } else {
          setSortOrder("ascend");
        }
      } else {
        setSortOrder(null);
      }
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      // title: "Case ID",
      dataIndex: "ID",
      sorter: (a, b) => moment(a.Udate).unix() - moment(b.Udate).unix(),
      sortOrder: column1SortOrder,
      onHeaderCell: () => ({
        onClick: () => toggleSortOrder("ID"),
      }),
      title: (titleProps) => {
        titleProps.sortColumns?.map((result) => {
          if (result.column.dataIndex == "ID") sortedColumn = result.order;
          else sortedColumn = "";
        });
        return getColumnLabel(column1SortOrder, "Case ID");
      },
      render: (text: string, row) => {
        return (
          <div style={{ justifyContent: "spaceBetween" }}>
            {text ? (
              <span className="">{text}</span>
            ) : (
              <Tooltip title="No data provided yet" arrow={false}>
                <img width={20} height={20} src={ic_clock} alt="no data" />
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      dataIndex: "Cdate",
      sorter: (a, b) => moment(a.Cdate).unix() - moment(b.Cdate).unix(),
      sortOrder: column2SortOrder,
      onHeaderCell: () => ({
        onClick: () => toggleSortOrder("Cdate"),
      }),
      title: (titleProps) => {
        titleProps.sortColumns?.map((result) => {
          if (result.column.dataIndex == "Cdate") sortedColumn = result.order;
          else sortedColumn = "";
        });
        return getColumnLabel(column2SortOrder, "Created");
      },
      render: (text: string, row) =>
        text || (
          <Tooltip title="No data provided yet" arrow={false}>
            <img width={20} height={20} src={ic_clock} alt="no data" />
          </Tooltip>
        ),
    },
    {
      dataIndex: "Udate",
      sorter: (a, b) => moment(a.Udate).unix() - moment(b.Udate).unix(),
      sortOrder: column3SortOrder,
      onHeaderCell: () => ({
        onClick: () => toggleSortOrder("Udate"),
      }),
      title: (titleProps) => {
        titleProps.sortColumns?.map((result) => {
          if (result.column.dataIndex == "Udate") sortedColumn = result.order;
          else sortedColumn = "";
        });
        return getColumnLabel(column3SortOrder, "Last Updated");
      },
      defaultSortOrder: "ascend",
      render: (text: string, row) =>
        text || (
          <Tooltip title="No data provided yet" arrow={false}>
            <img width={20} height={20} src={ic_clock} alt="no data" />
          </Tooltip>
        ),
    },
    {
      dataIndex: "Cat",
      sorter: (a, b) => a.Cat.localeCompare(b.Cat),
      sortOrder: column4SortOrder,
      onHeaderCell: () => ({
        onClick: () => toggleSortOrder("Cat"),
      }),
      title: (titleProps) => {
        titleProps.sortColumns?.map((result) => {
          if (result.column.dataIndex == "Cat") sortedColumn = result.order;
          else sortedColumn = "";
        });
        return getColumnLabel(column4SortOrder, "Category");
      },
      render: (text: string, row) =>
        text || (
          <Tooltip title="No data provided yet" arrow={false}>
            <img width={20} height={20} src={ic_clock} alt="no data" />
          </Tooltip>
        ),
    },
    {
      dataIndex: "Creat",
      sorter: (a, b) => a.Creat.localeCompare(b.Creat),
      sortOrder: column5SortOrder,
      onHeaderCell: () => ({
        onClick: () => toggleSortOrder("Creat"),
      }),
      title: (titleProps) => {
        titleProps.sortColumns?.map((result) => {
          if (result.column.dataIndex == "Creat") sortedColumn = result.order;
          else sortedColumn = "";
        });
        return getColumnLabel(column5SortOrder, "Creator");
      },
      render: (text: string, row) =>
        text || (
          <Tooltip title="No data provided yet" arrow={false}>
            <img width={20} height={20} src={ic_clock} alt="no data" />
          </Tooltip>
        ),
    },
    {
      dataIndex: "DueDate",
      sorter: (a, b) => moment(a.DueDate).unix() - moment(b.DueDate).unix(),
      sortOrder: column6SortOrder,
      onHeaderCell: () => ({
        onClick: () => toggleSortOrder("DueDate"),
      }),
      title: (titleProps) => {
        titleProps.sortColumns?.map((result) => {
          if (result.column.dataIndex == "DueDate") sortedColumn = result.order;
          else sortedColumn = "";
        });
        return getColumnLabel(column6SortOrder, "Due Date");
      },
      render: (text: string, row) =>
        text || (
          <Tooltip title="No data provided yet" arrow={false}>
            <img width={20} height={20} src={ic_clock} alt="no data" />
          </Tooltip>
        ),
    },
    {
      dataIndex: "priority",
      sorter: (a, b) => a.Priority.localeCompare(b.Priority),
      sortOrder: column7SortOrder,
      onHeaderCell: () => ({
        onClick: () => toggleSortOrder("priority"),
      }),
      title: (titleProps) => {
        titleProps.sortColumns?.map((result) => {
          if (result.column.dataIndex == "priority")
            sortedColumn = result.order;
          else sortedColumn = "";
        });
        return getColumnLabel(column7SortOrder, "Priority");
      },
      render: (text: string, row) => {
        return (
          <div style={{ justifyContent: "spaceBetween" }}>
            {PRIORITY_TAG[row.Priority as priority]}
          </div>
        );
      },
    },
    {
      dataIndex: "Status",
      sorter: (a, b) => a.Status.localeCompare(b.Status),
      sortOrder: column8SortOrder,
      onHeaderCell: () => ({
        onClick: () => toggleSortOrder("Status"),
      }),
      title: (titleProps) => {
        titleProps.sortColumns?.map((result) => {
          if (result.column.dataIndex == "Status") sortedColumn = result.order;
          else sortedColumn = "";
        });
        return getColumnLabel(column8SortOrder, "Status");
      },
      render: (text: string, row) => {
        return (
          <div style={{ justifyContent: "spaceBetween" }}>
            {STATUS_TAG[row.Status as status]}
          </div>
        );
      },
    },
  ];

  //initialise search value
  const [tableData, setTableData] = useState<DataType[]>([]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    setTableData(data);
  }, []);

  useEffect(() => {
    setValueInStorage("openCasesLength", tableData.length.toString());
  }, [tableData]);

  useEffect(() => {
    if (globalFilterStatus) {
      // Reset the state when globalFilterStatus changes to true
      setFilterValues(InitialCaseFilterValue);
    } else {
      setSearchText("");
    }
  }, [globalFilterStatus]);

  useEffect(() => {
    if (deletedFilterKey) {
      const updatedFilterValues = { ...filterValues };
      if (deletedFilterKey in updatedFilterValues) {
        delete updatedFilterValues[deletedFilterKey];
        setFilterValues(updatedFilterValues);
      }
    }
    setDeletedFilterKey(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletedFilterKey]);

  useEffect(() => {
    if (deleteFilter) {
      setFilterValues(InitialCaseFilterValue);
    }
    setDeleteFilter(false);
  }, [deleteFilter]);

  useEffect(() => {
    // Filter the data based on filterValues
    const filteredData = data.filter((item: any) => {
      for (const key in filterValues) {
        if (
          filterValues[key as keyof ICaseTableFilterValues] &&
          key !== "filterId"
        ) {
          if (key === "dob") {
            const formattedDate = formatDate(
              filterValues[key as keyof ICaseTableFilterValues] as string
            );
            if (!item[key]?.includes(formattedDate)) {
              return false;
            }
          } else {
            if (
              !String(filterValues[key as keyof ICaseTableFilterValues])
                ?.toLowerCase()

                .includes(item[key]?.toLowerCase())
            ) {
              return false;
            }
          }
        }
      }
      return true;
    });
    // if (startDate && endDate) {
    //   var result = getDateResult(data);
    //   if (searchText) {
    //     var temp = getSearchResults(result, searchText);
    //     setTableData(temp);
    //   } else {
    //     setTableData(result);
    //   }
    // }
    if (startDate && endDate) {
      const sDate = moment(startDate);
      const eDate = moment(endDate);
      // const dateFilteredData = filteredData.filter(
      //   (value) => moment(value.Cdate) >= sDate && moment(value.Udate) <= eDate
      // );
      const dateFilteredData = filteredData.filter(
        (value) =>
          moment(value.Cdate)
            .startOf("day")
            .isSameOrAfter(sDate.startOf("day")) &&
          moment(value.Cdate)
            .startOf("day")
            .isSameOrBefore(eDate.startOf("day"))
      );
      setTableData(dateFilteredData);
    } else {
      setTableData(filteredData);
    }
  }, [filterValues, startDate, endDate]);

  const getColumnLabel = (sortedColumn: any, text: string) => {
    return (
      <div style={{ justifyContent: "spaceBetween" }}>
        {text}

        {(() => {
          if (sortedColumn == "ascend") {
            return (
              <img
                src={ascend}
                alt="addcase"
                className="ascendImg cursor"
                style={{ width: 6, height: 10 }}
              />
            );
          } else if (sortedColumn == "descend") {
            return (
              <img
                src={ascend}
                alt="addcase"
                className="ascendImg cursor"
                style={{ width: 6, height: 10, transform: "rotate(180deg)" }}
              />
            );
          }
        })()}
      </div>
    );
  };

  const getDateResult = (list: DataType[]) => {
    var sDate = moment(startDate);
    var eDate = moment(endDate);
    // var result = list.filter(
    //   (value) => moment(value.Cdate) >= sDate && moment(value.Udate) <= eDate
    // );
    var result = list.filter(
      (value) =>
        moment(value.Cdate)
          .startOf("day")
          .isSameOrAfter(sDate.startOf("day")) &&
        moment(value.Cdate).startOf("day").isSameOrBefore(eDate.startOf("day"))
    );
    return result;
  };

  const handleSearch = (searchText: string) => {
    setSearchText(searchText);

    var result: DataType[] = [];

    if (!searchText) {
      setSearchText("");
      result = data;
    } else {
      result = getSearchResults(data, searchText);
      setGlobalFillterStatus(true);
    }

    if (startDate && endDate) {
      result = getDateResult(result);
    }

    setTableData(result);
  };

  const getSearchResults = (list: DataType[], text: string) => {
    let result: any = [];
    if (text?.length) {
      result = list.filter(
        (value) =>
          value.Cat.toLowerCase().includes(text.toLowerCase()) ||
          value.ID.toLowerCase().includes(text.toLowerCase()) ||
          value.Status.toLowerCase().includes(text.toLowerCase()) ||
          value.Priority.toLowerCase().includes(text.toLowerCase()) ||
          value.Creat.toLowerCase().includes(text.toLowerCase())
      );
    }
    return result;
  };

  const handleSelect = (date: any, type: string) => {
    if (!date) {
      if (type == "StartDate") {
        setStartDate(null);
      } else if (type == "EndDate") {
        setEndDate(null);
      }
      if (searchText) {
        var result = getSearchResults(data, searchText);
        setTableData(result);
      } else {
        setTableData(data);
      }

      return;
    }

    if (type == "StartDate") {
      setStartDate(date.format());
    } else {
      setEndDate(date.format());
    }
  };

  const handleDropdownVisibleChange = (visible: boolean) => {
    setIsDropdownOpen(visible);
  };

  const takeActionmenu = (
    <CaseFilters
      setFilterValues={setFilterValues}
      filterValues={filterValues}
      setCaseNumber={setCaseNumber}
      setCurrentFilterName={setCurrentFilterName}
      setIsDropdownOpen={setIsDropdownOpen}
    ></CaseFilters>
  );

  const countKeysWithValues = (filterValues: any) => {
    let count = 0;
    for (const key in filterValues) {
      if (
        key !== "filterId" &&
        filterValues[key] !== null &&
        filterValues[key] !== undefined &&
        filterValues[key] !== ""
      ) {
        count++;
      }
    }
    return count;
  };

  const filterCount = countKeysWithValues(filterValues);

  return (
    <div>
      <FixedHeaderCasesTable
        currentFilterName={currentFilterName}
        setFilterValues={setFilterValues}
        setDeleteFilter={setDeleteFilter}
      />
      <div className="caseTable margin50">
        <Input
          placeholder="Search"
          className="search-box"
          onChange={(e) => handleSearch(e.target.value)}
          value={searchText}
          prefix={
            <img src={ic_search} alt="search" style={{ marginRight: "6px" }} />
          }
          suffix={
            <img
              src={ic_circle_cross}
              alt="search"
              style={{
                cursor: "pointer",
                display: searchText ? "block" : "none",
              }}
              onClick={() => handleSearch("")}
              className="search-clear-icon"
            />
          }
        />
        <div className="filter-box">
          <div className="onlyFilterSection">
            <div className="filterleft cursor floatLeft">
              <Dropdown
                overlay={takeActionmenu}
                trigger={["click"]}
                open={isDropdownOpen}
                className="dropdown-filters"
                onOpenChange={handleDropdownVisibleChange}
              >
                <Button
                  icon={
                    <img
                      src={filterIcon}
                      alt="filterIcon"
                      className="filter-appl-buttton"
                      onClick={openFilter}
                    />
                  }
                >
                  <span style={{ marginRight: "30px" }}>
                    Filters{" "}
                    {!!filterCount && (
                      <span style={{ color: "#7A8EBA" }}>{filterCount}</span>
                    )}
                  </span>
                  <img
                    src={isDropdownOpen ? ic_big_arrow_up : ic_big_arrow_down}
                    alt="arrow icon"
                    className="filterIcon"
                  />
                </Button>
              </Dropdown>
            </div>
            <div className="menuSections floatRight">
              <div className="date_borderText floatLeft">
                <span className="info_span correctSpan">
                  Created Start Date
                </span>

                <DatePicker
                  suffixIcon={<CalendarIcon />}
                  bordered={false}
                  // placeholder="12/23/2023"
                  className="callSche callDate cursor alignDateInBox"
                  format={"MM/DD/YYYY"}
                  onChange={(e) => {
                    handleSelect(e, "StartDate");
                  }}
                  renderExtraFooter={() => (
                    <div className="alignAnytime">
                      <Checkbox className="anyTime">Anytime</Checkbox>
                    </div>
                  )}
                  showToday={false}
                />
              </div>
              <span className="toTextDate floatLeft"> to </span>

              <div className="date_borderText floatLeft">
                <span className="info_span correctSpan">Created End Date</span>

                <DatePicker
                  suffixIcon={<CalendarIcon />}
                  bordered={false}
                  // placeholder="08/31/2023"
                  className="callSche callDate cursor alignDateInBox"
                  format={"MM/DD/YYYY"}
                  onChange={(e) => {
                    handleSelect(e, "EndDate");
                  }}
                  renderExtraFooter={() => (
                    <div className="alignAnytime">
                      <Checkbox className="anyTime">Anytime</Checkbox>
                    </div>
                  )}
                  showToday={false}
                />
              </div>
            </div>
          </div>
          <Row className="FilteredListWidth">
            <FilteredTabs
              filterValues={filterValues}
              setDeletedFilterKey={setDeletedFilterKey}
            />
          </Row>
        </div>
        <div>
          <div className="headingTable marginTop20">
            <div className="resultSec marginTop20">
              <span className="results">
                {tableData.length}{" "}
                {tableData.length == 1 ? "Result" : "Results"}
              </span>
            </div>
            <div className="exportSec cursor">
              <div
                className="buttonBg floatLeft createButton marginRight20"
                // onClick={handleCreateCasePopup}
              >
                <span className="buttonText">Create Case</span>
              </div>
              <div className="floatRight marginTop20">
                <span className="exportText">Export to PDF</span>
                <Image
                  src={exportPDF}
                  alt="export"
                  loading="lazy"
                  preview={false}
                  className="excelImage"
                />
              </div>
            </div>
          </div>
          {/**   <hr className="separateTable"></hr>*/}
          <Table
            rowSelection={{
              type: selectionType,
              ...rowSelection,
            }}
            columns={columns}
            dataSource={tableData}
            bordered
            pagination={false}
            scroll={{ x: 1100 }}
            showSorterTooltip={false}
            locale={{
              emptyText: (
                <span className="emptyTableText">
                  <img src={notFoundIcon} alt="no data" />
                  You have no applications to display
                </span>
              ),
            }}
          />
          <div id="pagnations">
            <div className="paginationSection floatLeft cursor marginTopPage">
              <img src={leftVector} alt="leftNav" className="" />
            </div>
            <div>
              <span className="pageNum">
                1 to {tableData.length} of {tableData.length}
              </span>
            </div>
            <div className="paginationSection floatRight cursor marginTopPage">
              <img src={rightVector} alt="rightNav" className="darkerImage" />
            </div>
          </div>
        </div>
        <div className={tableData.length == 0 ? "reduceMarginFooter" : ""}>
          <PrivacyPolicy></PrivacyPolicy>
        </div>
      </div>
      {showCreateCasePopup && (
        <CreateCaseTablePopup
          showPopup={showCreateCasePopup}
          setShowPopup={setShowCreateCasePopup}
          saveCreatedData={saveCreatedData}
          data={tableData}
        />
      )}
    </div>
  );
};

export default CasesTable;
