import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Layout, Button, Input, notification } from "antd";
import loquatlogo from "../../Assets/Images/NewLoquatLogo.svg";
import "./Login.scss";

const { Content } = Layout;

const StartPage = () => {
  const navigate = useNavigate();

  const OnClick = () => {
    if (!loginDetails.userName) {
      notification.error({
        message: "Please enter username.",
      });

      return;
    } else if (!loginDetails.passWord) {
      notification.error({
        message: "Please enter password.",
      });

      return;
    }

    if (
      loginDetails.userName == "test@loquatinc.io" &&
      loginDetails.passWord == "test@789"
    ) {
      navigate("/applications");
    } else {
      notification.error({
        message: "Invalid credentials.",
      });
    }
  };

  // const [loginDetails, setLoginDetails] = useState<any>({});
  // mock user
  const [loginDetails, setLoginDetails] = useState<any>({
    userName: "test@loquatinc.io",
    passWord: "test@789",
  });

  const handleChange = (type: string, value: string) => {
    loginDetails[type] = value;
    setLoginDetails(loginDetails);
  };

  return (
    <Layout className="login-page-container login-layout">
      <Content className="login-content">
        <div className="login-group">
          <div className="header-content login-header">
            <img src={loquatlogo} className="header-img" alt="Logo" />
          </div>
          <div className="button-container">
            <div className="button-group">
              <div className="info_inputTextClass info_inputTextClass_email removecontent">
                <Input
                  className="removecontent1"
                  bordered={false}
                  placeholder="Email"
                  required
                  value={"test@loquatinc.io"}
                  onChange={(e) => {
                    handleChange("userName", e.target.value);
                  }}
                />
                <span className="passwordname">Email</span>
              </div>
              <div className="info_inputTextClass removecontent">
                <Input.Password
                  bordered={false}
                  className=" removecontent1"
                  placeholder="Password"
                  value={"test@789"}
                  onChange={(e) => {
                    handleChange("passWord", e.target.value);
                  }}
                />
                <span className="passwordname">Password</span>
              </div>
              <span className="forgetpassword">Forgot Password?</span>
              <Button className="button" block onClick={OnClick}>
                Log in
              </Button>
            </div>
          </div>
        </div>
      </Content>
    </Layout>
  );
};

export default StartPage;
