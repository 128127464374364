import React, { useState } from "react";
import { Space, Select, Input, DatePicker, Form } from "antd";
import "./CreateCase.scss";
import { Modal, Row, Col } from "antd";
import cancel from "../../Assets/Images/cancelBg.png";
import confirm from "../../Assets/Images/createBg.png";
import { CommonValues } from "../Common/Base";
import { ICreateCaseFormValues } from "../../Contracts/Contracts";
import * as Base from "../Common/Base";

const { TextArea } = Input;

type FinalPopupProps = {
  showPopup: boolean;
  setShowPopup: (value: boolean) => void;
  saveCreatedData?: any;
};
const CreateCase = (popupProps: FinalPopupProps) => {
  const [form] = Form.useForm();
  const dateFormat = CommonValues.dateFormat;
  const [caseFormValues, setCaseFormValues] = useState<
    ICreateCaseFormValues | undefined
  >();
  const [category, setCategory] = useState<string | undefined>(undefined);
  const [assignee, setAssignee] = useState<string | undefined>(undefined);
  const [priority, setPriority] = useState<string | undefined>(undefined);

  const handleOk = () => {
    popupProps.setShowPopup(false);
  };

  const handleCancel = () => {
    popupProps.setShowPopup(false);
  };

  const handleInputChange = (fieldName: string, value: any) => {
    let updatedValues: ICreateCaseFormValues | undefined;
    if (caseFormValues) {
      updatedValues = {
        ...caseFormValues,
        [fieldName]: value,
      };
    } else {
      updatedValues = {
        // category: "",
        // assignee: "",
        // priority: "",
        tag: "",
        dueDate: null,
        desc: "",
        [fieldName]: value,
      };
    }
    form.setFieldsValue(updatedValues);
    setCaseFormValues(updatedValues);
  };

  const handleCategoryChange = (value: string) => {
    setCategory(value);
  };

  const handleAssigneeChange = (value: string) => {
    setAssignee(value);
  };

  const handlePriorityChange = (value: string) => {
    setPriority(value);
  };

  function formatDate(dateString: string | number | Date) {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    const formattedDate = new Date(dateString).toLocaleDateString(
      "en-US",
      options as Intl.DateTimeFormatOptions
    );
    return formattedDate;
  }
  const onCreateCaseFinish = async () => {
    const dueDate = caseFormValues?.dueDate
      ? new Date(caseFormValues.dueDate)
      : new Date();
    const currentDate = new Date();

    const formattedCurrentDate = currentDate.toLocaleString("en-US", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    // const formattedDate = dueDate.toLocaleString("en-US", {
    //   year: "numeric",
    //   month: "numeric",
    //   day: "numeric",
    //   hour: "numeric",
    //   minute: "numeric",
    //   hour12: true,
    // });
    const formattedDueDate = formatDate(caseFormValues?.dueDate);
    const randomNumber = Math.floor(Math.random() * 900000000) + 100000000;

    const postData = {
      Cat: category,
      Assignee: assignee,
      Priority: priority,
      Tag: caseFormValues?.tag,
      // dueDate: formattedDueDate,
      DueDate: caseFormValues?.dueDate ? formattedDueDate : "",
      Description: caseFormValues?.desc,
      Cdate: formattedCurrentDate,
      Udate: formattedCurrentDate,
      ID: "FRD-" + randomNumber,
      Creat: "John Smith",
      Status: "UNDECIDED",
    };
    popupProps.saveCreatedData(postData);
    handleCancel();
  };

  return (
    // <Layout className="final-popup-layout">
    //   <Content className="confirm-content confirm-border">
    //     <Button type="primary" onClick={showModal}>
    //       Open Modal
    //     </Button>

    <Modal
      centered
      open={popupProps.showPopup}
      title="Create Case"
      width={800}
      onOk={handleOk}
      onCancel={handleCancel}
      bodyStyle={{ height: 400 }}
      footer={[
        <Row className="popBtns">
          <div className="leftBtn">
            <div className="buttonBg floatLeft cursor" onClick={handleCancel}>
              <img
                src={cancel}
                alt="cancel"
                className="buttonWidthCreateCancel"
              />
              <span className="buttonTextCreateCancel">Cancel</span>
            </div>
          </div>
          <div className="rightBtn">
            <div
              className="buttonBg floatLeft cursor"
              onClick={onCreateCaseFinish}
            >
              <img src={confirm} alt="confirm" className="buttonWidthCreate" />
              <span className="buttonTextCreate">Create</span>
            </div>
          </div>
        </Row>,
      ]}
    >
      <div className="ContentCreate margin20">
        <Form
          form={form}
          onFinish={onCreateCaseFinish}
          scrollToFirstError={true}
        >
          <Row>
            <span className="Type">Fraud</span>
            <span className="Type">AML</span>

            <span className="Type">OFAC</span>
          </Row>
          <Row>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 11 }}
              lg={{ span: 11 }}
              className="margin20"
            >
              {/* <Form.Item
                name="category"
                rules={[{ required: true, message: "Category is required" }]}
              > */}{" "}
              <Space wrap className="fullWidth info_borderText">
                <Select
                  placeholder="Category"
                  options={[
                    { value: "AML", label: "AML" },
                    { value: "Fraud", label: "Fraud" },
                    { value: "OFAC", label: "OFAC" },
                  ]}
                  onChange={handleCategoryChange}
                  value={category}
                />
              </Space>
              {/* </Form.Item> */}
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 11 }}
              lg={{ span: 11 }}
              className="margin20"
            >
              {" "}
              {/* <Form.Item
                name="Assignee"
                rules={[{ required: true, message: "Assignee is required" }]}
              > */}
              <Space wrap className="fullWidth info_borderText">
                <Select
                  placeholder="Assignee"
                  options={[
                    {
                      value: "john.smith@loquatinc.io",
                      label: "john.smith@loquatinc.io",
                    },
                    {
                      value: "james.smith@loquatinc.io",
                      label: "james.smith@loquatinc.io",
                    },
                    {
                      value: "bob.smith@loquatinc.io",
                      label: "bob.smith@loquatinc.io",
                    },
                  ]}
                  onChange={handleAssigneeChange}
                  value={assignee}
                />
              </Space>
              {/* </Form.Item> */}
            </Col>
          </Row>
          <Row>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 7 }}
              lg={{ span: 7 }}
              className="marginProp"
            >
              {/* <Form.Item
                name="priority"
                rules={[{ required: true, message: "Priority is required" }]}
              > */}{" "}
              <Space wrap className="fullWidth info_borderText">
                <Select
                  placeholder="Priority"
                  options={[
                    { value: "High", label: "High" },
                    { value: "Medium", label: "Medium" },
                    { value: "Low", label: "Low" },
                  ]}
                  onChange={handlePriorityChange}
                  value={priority}
                />
              </Space>
              {/* </Form.Item> */}
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 7 }}
              lg={{ span: 7 }}
              className="marginTag"
            >
              {" "}
              {/* <Form.Item
                name="tag"
                rules={[{ required: true, message: "Tag is required" }]}
              > */}
              <Space wrap className="fullWidth">
                <Input
                  className="inputFilter"
                  placeholder="Add tag"
                  onChange={(e) => handleInputChange("tag", e.target.value)}
                  value={caseFormValues?.tag}
                />
              </Space>
              {/* </Form.Item> */}
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 7 }}
              lg={{ span: 8 }}
              className="marginDue"
            >
              <div className="">
                {/* <Form.Item
                  name="dueDate"
                  // rules={[{ required: true, message: "Date is required" }]}
                > */}
                <Space
                  direction="vertical"
                  className="fullWidth info_inputTextClass"
                >
                  <DatePicker
                    bordered={false}
                    placeholder="Due Date"
                    format={dateFormat}
                    onChange={(value) => handleInputChange("dueDate", value)}
                    value={caseFormValues?.dueDate}
                  />
                </Space>
                {/* </Form.Item> */}
              </div>
            </Col>
          </Row>
          <Row className="margin20">
            <TextArea
              rows={4}
              placeholder="Description"
              maxLength={6}
              style={{ resize: "none" }}
              className="CreateDesc"
              onChange={(e) => handleInputChange("desc", e.target.value)}
              value={caseFormValues?.desc}
            />
          </Row>
        </Form>
      </div>
    </Modal>
    //   </Content>
    // </Layout>
  );
};

export default CreateCase;
