import React, { useContext, useEffect, useState } from "react";
import { ApplicationContext } from "../../Context/ApplicationContextProvider";
import {
  Layout,
  Space,
  Button,
  Row,
  Col,
  Input,
  Select,
  Form,
  Collapse,
  CollapseProps,
  theme,
  DatePicker,
  Modal,
  Checkbox,
} from "antd";

import "./AddAnotePopup.scss";

import addNote from "../../Assets/Images/addNoteBg.png";
const { Header, Footer, Sider, Content } = Layout;
const { TextArea } = Input;

type FinalPopupProps = {
  showPopup: boolean;
  setShowPopup: (value: boolean) => void;
};
const AddaNotePopup = (popupProps: FinalPopupProps) => {
  const handleOk = () => {
    popupProps.setShowPopup(false);
  };

  const handleCancel = () => {
    popupProps.setShowPopup(false);
  };

  return (
    <Modal
      centered
      open={popupProps.showPopup}
      onOk={handleOk}
      onCancel={handleCancel}
      width={900}
      title="Add note"
      className="passHeaderfix widthOfPopup"
      footer={[

          <div className="buttonBg floatRight cursor margin35" onClick={handleCancel}>
          <img src={addNote} alt="addNote" className="buttonWidthAddNote" />
          <span className="buttonTextAddNote">Add note +</span>
        </div>,
      ]}
    >
      <div className="add-container">
        <Row>
          <Col md={24} xs={24}>
            <Form.Item
              name="firstname"
              rules={[
                {
                  required: true,
                  message: "First Name is required",
                },
              ]}
            >
              {" "}
              <div className="info_reportText notesTextarea">
                <TextArea
                  bordered={false}
                  placeholder="Note, 6000 characters remaining"
                  className="detaistextarea"
                  rows={4}
                  style={{ resize: "none" }}
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default AddaNotePopup;
