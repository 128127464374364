import { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { Detector } from "react-detect-offline";

import { ApplicationContextProvider } from "./Web/Context/ApplicationContextProvider";

import AppRoute from "./Web/Route/AppRoute";
import NetworkError from "./Web/Components/ErrorPages/NetworkError";
import { IDynamicConfig, DEFAULT_CONFIG } from "./Web/Configuration/Config";

import  "./Web/Assets/Scss/base.scss"

function App() {
  const [config, setConfig] = useState<IDynamicConfig>(DEFAULT_CONFIG);
  
  window.onscroll = function() {
    var header = document.getElementById("myHeadercases2");
    var container = document.getElementById("headerContainer");
  if(header!.offsetTop !=null){
    var sticky = header!.offsetHeight;
    if (window.scrollY >= sticky) {
      container!.classList.add("sticky2");
    } else {
      container!.classList.remove("sticky2");
  
    }
  }
    
  };
  
  return (
    <>
      <Detector
        render={({ online }: any) => (
          <div className="mainpage" id="headerContainer">
            <ApplicationContextProvider config={config}>
                <BrowserRouter>
                  <AppRoute online={online} />
                </BrowserRouter>
              </ApplicationContextProvider>
          </div>
        )}
      />
    </>
  );
}

export default App;
