import { Col, Flex, Radio, Row } from "antd";
import "./BIQuestionsBlock.scss";

const questions = [
  "Is this business registered in a country outside the US?",
  "Are you registered as a Money Service Business or do you cash checks made payable to your customers?",
  "Will this business include the retail sale, creation, marketing or testing of marijuana or marijuana-related products (hemp, CBD, CBD oils)?",
  "Is this an Adult Entertainment Business?",
  "Does this business engage in internet gambling?",
];

const BIQuestionsBlock = () => {
  return (
    <Flex className="BIQuestions-container" gap='12px' vertical>

      {questions.map((question, index) => (
        <Flex key={index} justify='space-between'>
          <Flex>
            <span className="text">{question}</span>
          </Flex>
          <Flex style={{minWidth: '130px'}}>
            <Radio.Group value={index === 0 ? 1 : 2} disabled>
              <Radio value={1}>Yes</Radio>
              <Radio value={2}>No</Radio>
            </Radio.Group>
          </Flex>
        </Flex>
      ))}

      <Row gutter={2}>
        <Col xs={{ span: 16 }} sm={{ span: 18 }} md={{ span: 20 }}>
          <span className="text">What is the anticipated dollar amount of currency transactions per month?</span>
        </Col>
        <Col xs={{ span: 8 }} sm={{ span: 6 }} md={{ span: 4 }}>
          <div className="priceBlock">10000$</div>
        </Col>
      </Row>
    </Flex>
  );
};

export default BIQuestionsBlock;
