import React, { useMemo, useState } from "react";
import { ICaseTableFilterValues } from "../../../Contracts/Contracts";
import close from "../../../Assets/Images/filterCloseSvg.svg";
import { formatDate } from "../../Utility/Utility";
import { ReactComponent as CrossIcon } from "../../../Assets/Images/icons/ic_cross.svg";
import { ReactComponent as ArrowUp } from "../../../Assets/Images/icons/ic_big_arrow_up.svg";
import { Tooltip } from "antd";

type FilterProps = {
  filterValues: ICaseTableFilterValues;
  setDeletedFilterKey: (value: string | null) => void;
};

const customKeyNames: Record<string, string> = {
  ID: "Case ID",
  Cat: "Category",
  Creat: "Creator",
  Priority: "Priority",
  assignee: "Assignee",
  filterId: "",
};

const FilteredTabs = (props: FilterProps) => {
  const [isShowAllTags, setShowAllTags] = useState(false);
  const filteredDivs: React.ReactNode[] = [];

  function formatKey(key: string): string {
    return customKeyNames[key] || key;
  }

  const handleDeleteClick = (key: string) => {
    props.setDeletedFilterKey(key);
  };

  for (const key in props.filterValues) {
    if (
      props.filterValues[key as keyof ICaseTableFilterValues] &&
      key !== "filterId"
    ) {
      const value =
        key === "dob"
          ? formatDate(
              props.filterValues[key as keyof ICaseTableFilterValues] as string
            )
          : props.filterValues[key as keyof ICaseTableFilterValues];
      filteredDivs.push(
        <div className="filtered_tag" key={key}>
          <div className="filtered_tag__label">{formatKey(key)}</div>
          <Tooltip title={`${value}`} arrow={false}>
            <div className="filtered_tag__value">
              <div>{`${value}`}</div>
              <CrossIcon onClick={() => handleDeleteClick(key)} />
            </div>
          </Tooltip>
        </div>
      );
    }
  }

  const showFilteredValue = useMemo(() => {
    if (isShowAllTags)
      return filteredDivs.concat(
        <div className="filtered_tag" onClick={() => setShowAllTags(false)}>
          <div className="filtered_tag__value more">
            <ArrowUp />
          </div>
        </div>
      );
    if (filteredDivs.length > 3) {
      return filteredDivs.slice(0, 3).concat(
        <div className="filtered_tag" onClick={() => setShowAllTags(true)}>
          <div className="filtered_tag__value more">
            <div>{`+ ${filteredDivs.length - 3}`}</div>
          </div>
        </div>
      );
    }
    return filteredDivs;
  }, [filteredDivs, isShowAllTags]);

  return <div className="filtered_tags">{showFilteredValue}</div>;
};

export default FilteredTabs;
