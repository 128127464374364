import { useState, useEffect } from "react";
import {
  Layout,
  Space,
  Row,
  Col,
  Select,
  DatePicker,
  Modal,
  Checkbox,
} from "antd";

import "./NotificationsAll.scss";
import notifyclock from "../../Assets/Images/clockSvg.svg";
import notifydot from "../../Assets/Images/pinkdotSvg.svg";
import whitedot from "../../Assets/Images/whitedotSvg.svg";
import leftvector from "../../Assets/Images/leftvectorSvg.svg";
import rightvector from "../../Assets/Images/rightvectorSvg.svg";
import markread from "../../Assets/Images/markreadSvg.svg";
import notificationList from "./NotificationDataList.json";
import moment from "moment";

interface DataType {
  key: React.Key;
  Cdate: any;
  lastUpdated: string;
  Status: string;
}

const { RangePicker } = DatePicker;
const dateFormat = "MM/DD/YYYY";
const { Header, Footer, Sider, Content } = Layout;
type FinalPopupProps = {
  showPopup: boolean;
  setShowPopup: (value: boolean) => void;
};

const NotificationsAll = (popupProps: FinalPopupProps) => {
  const notificationDataList = notificationList.notifyList;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [notificationData, setnotificationDataList] = useState<DataType[]>([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    // Filter the data based on date range
    // console.log("startDate && endDate :", startDate + " && " + endDate);
    if (startDate && endDate) {
      const sDate = moment(startDate);
      const eDate = moment(endDate);
      // const dateFilteredData = notificationData.filter(
      //   (value) => moment(value.Cdate) >= sDate && moment(value.Cdate) <= eDate
      // );
      const dateFilteredData = notificationData.filter(
        (value) => moment(value.Cdate).startOf('day').isSameOrAfter(sDate.startOf('day')) &&
          moment(value.Cdate).startOf('day').isSameOrBefore(eDate.startOf('day'))
      );
      setnotificationDataList(dateFilteredData);
    } else {
      setnotificationDataList(notificationDataList);
    }
  }, [startDate, endDate]);

  const handleOk = () => {
    popupProps.setShowPopup(false);
  };

  const handleCancel = () => {
    popupProps.setShowPopup(false);
  };

  const handleSelect = (date: any, type: string) => {
    console.log("type value :", date);
    if (!date) {
      if (type == "StartDate") {
        setStartDate(null);
      } else if (type == "EndDate") {
        setEndDate(null);
      }
      return;
    }

    if (type == "StartDate") {
      setStartDate(date.format());
    } else {
      setEndDate(date.format());
    }
  };

  return (
    <Modal
      centered
      open={popupProps.showPopup}
      onOk={handleOk}
      onCancel={handleCancel}
      width={1200}
      title="Notifications"
      className="notiModal"
      footer={[]}
    >
      <div className="notification-container">
        <Row className="filterSection">
          <Col xs={24} sm={24} md={6} lg={5} className="filter-container ">
            <div className="notiDropdown">
              <Space wrap className="fullWidth">
                <Select
                  size="large"
                  placeholder="Show all"
                  options={[
                    {
                      value: 0,
                      label: "Show all",
                    },
                    {
                      value: 1,
                      label: "10",
                    },
                  ]}
                  className="testWidth"
                />
              </Space>
            </div>
          </Col>
          <Col xs={24} sm={24} md={8} lg={5} className="filter-container marginDateLeft">
            <div className="date_borderText">
              <span className="info_span correctSpan">Pushed Start Date</span>

              <DatePicker
                bordered={false}
                format={"MM/DD/YYYY"}
                className="callSche callDate cursor alignDateInBox"
                onChange={(e) => { handleSelect(e, "StartDate") }}
                renderExtraFooter={() => (
                  <div className="alignAnytime">
                    <Checkbox className="anyTime">Anytime</Checkbox>
                  </div>
                )}
                showToday={false}
              />
            </div>
          </Col>
          <span className="toTextDate"> to </span>
          <Col xs={24} sm={24} md={8} lg={5} className="filter-container marginNegLeft">
            <div className="date_borderText">
              <span className="info_span correctSpan">Pushed End Date</span>
              <DatePicker
                bordered={false}
                format={"MM/DD/YYYY"}
                className="callSche callDate cursor alignDateInBox"
                onChange={(e) => { handleSelect(e, "EndDate") }}
                renderExtraFooter={() => (
                  <div className="alignAnytime">
                    <Checkbox className="anyTime">Anytime</Checkbox>
                  </div>
                )}
                showToday={false}
              />
            </div>
          </Col>
          <Col xs={24} sm={24} md={20} lg={6} className="navNotification">
            <div>
              <div className="vectorLeftSec floatLeft cursor">
                <img
                  src={leftvector}
                  alt="search"
                  className="search-logoimage "
                />
              </div>
              <div>
                {
                  notificationData.length > 0 ?
                    <span className="navNoti"> 1-{notificationData.length} of {notificationData.length}</span>
                    :
                    <span className="navNoti"> {notificationData.length}-{notificationData.length} of {notificationData.length}</span>
                }
              </div>
              <div className="vectorLeftSec floatLeft cursor">
                <img
                  src={rightvector}
                  alt="search"
                  className="search-logoimage"
                />
              </div>
            </div>
            <div className="markReadTop">
              <div className="marksec cursor">
                <span className="markread">Mark all as read</span>
                <img className="markreadimg" src={markread} alt="markread" />
              </div>{" "}
            </div>
          </Col>
        </Row>
        {/* ===================== */}
        <div className="contentScroll">
          <div className="scrollDetails">
            {notificationData.map(({ key, Cdate, lastUpdated, Status }) => {
              return (

                <Row className="">
                  <Col
                    xs={24}
                    sm={24}
                    md={16}
                    lg={16}
                    className="asssign-container notify_porta_asssign"
                  >
                    <h3>{Status}</h3>
                    <div className="asssignmarksec">
                      <img
                        className="notifyclockimg"
                        src={notifyclock}
                        alt="notifyclock"
                      />
                      <span className="asssignmarkread">{lastUpdated + " "} ago</span>
                    </div>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={7}
                    lg={8}
                    className="notify-dot notify_porta_asssign"
                  >
                    <div className="notifydotsec">
                      <img
                        className="notifydotimg"
                        src={notifydot}
                        alt="notifydot"
                      />
                      <span className="notifydate">{Cdate}</span>
                    </div>
                  </Col>
                </Row>
              );
            })}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default NotificationsAll;
