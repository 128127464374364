import {
  IPersonalInfoPostDetails,
  ISendOtp
} from "../../Web/Contracts/Contracts";
import { ApiEndpoints } from "../APIEndPoints";
import { ILoquatServiceClient } from "../LoquatClient";

import { ServiceBase } from "../ServiceBase";

export class ProfileService extends ServiceBase implements IProfileService {
  constructor(private client: ILoquatServiceClient) {
    super(client.getAxiosInstance());
  }

  async getPersonalInfo() {
    let url = ApiEndpoints.Profile.getPersonalInfo();
    const response: any = await this.doGet(url);
    return response;
  }

  async postPersonalInfo(body: IPersonalInfoPostDetails) {
    let url = ApiEndpoints.Profile.postPersonalInfo();
    const response: any = await this.doPost(url, body);
    return response;
  }

  async getUserApplicationStatus(body:ISendOtp){
    let url=ApiEndpoints.Profile.getUserAppStatus();
    const response:any =await this.doPost(url,body);
    return response;
  }
}

export interface IProfileService {
  getPersonalInfo(): Promise<any>;
  postPersonalInfo(body: IPersonalInfoPostDetails): Promise<any>;
  getUserApplicationStatus(body:ISendOtp):Promise<any>
}
