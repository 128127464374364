import { useEffect, useState } from "react";
import {
  Space,
  Row,
  Col,
  Input,
  Select,
  Form,
  Collapse,
  CollapseProps,
  DatePicker,
  Flex,
  Checkbox,
} from "antd";

import rightsymbol from "../../Assets/Images/rightSymbolSvg.svg";
import wrongsymbol from "../../Assets/Images/wrongSvg.svg";
// import selfie from "../../Assets/Images/selfie.png";
import person from "../../Assets/Images/person.png";
import identificationFront from "../../Assets/Images/identificationFront.png";
import identificationBack from "../../Assets/Images/identificationBack.png";
import doc1 from "../../Assets/Images/doc1.png";
import doc2 from "../../Assets/Images/doc2.png";
import doc3 from "../../Assets/Images/doc3.png";
import doc4 from "../../Assets/Images/doc4.png";
import selfiewPreview from "../../Assets/Images/selfiePreview.png";
import selfiewPreview2 from "../../Assets/Images/selfiePreview2.png";
import selfiewPreview3 from "../../Assets/Images/selfiePreview3.png";

import articleimg from "../../Assets/Images/articleimg.png";
import noArticleImg from "../../Assets/Images/noArticleSvg.svg";

import "./OpenedApplicationTab.scss";
import BeneficialOwnerList from "../BeneficialOwnersList/BeneficialOwnerList";
import BIQuestionsBlock from "./components/BIQuestionsBlock"
import ResponsibleIndividuals from "./components/ResponsibleIndividuals"
import BeneficialOwners from "./components/BeneficialOwners"
import AuthorizedSigners from "./components/AuthorizedSigners"
import ic_clock from "../../Assets/Images/icons/ic_clock.svg";

const size = "large";
type ExpandIconPosition = "start" | "end";

interface BeneficialOwner {
  name: string;
  children: React.ReactNode;
  status: boolean;
}

const InformationExtractedTop = {
  "City Name": "Miami",
  "State Name": "FL",
  "Country Name": "United States",
  "Device Type": "iPhone (iOS)",
}

const InformationExtractedMiddle = {
  "First Name": "Adam",
  "Last Name": "Smith",
  "Document": "Drivers License",
  "Document Expiration": "05/14/2028",
  "Status": "Not tampered",
  "Document Quality": "Good",
  "Address 1": "520 Florida NE",
  "Address 2": "-",
  "Date of Birth": "",
  "Document Number": "D00000000",
  "Expiration Date": "05/14/2028",
  "ID Country": "+1 (001)",
  "Document Type": "Drivers License",
  "Document Number #2": "-",
  "Place of Birth": "Florida",
  "Valid From Date": "07/13/2020",
  "Nationality": "US",
  "Issuing State": "Florida",
  "Issuing Authority or Organization": "Florida",
  "Place of Issue": "Florida",
  "Date of Issue": "07/13/2020",
}

const InformationExtractedBottom = {
  "Liveness": "Verified",
}


const responsibleIndividualsList: BeneficialOwner[] = [
  {
    name: "John Smith",
    children: ResponsibleIndividuals,
    status: true,
  }
];

const beneficialOwnerList: BeneficialOwner[] = [
  {
    name: "Jack Smith",
    children: BeneficialOwners,
    status: true,
  }
];

const authorizedSignersList: BeneficialOwner[] = [
  {
    name: "Jane Smith",
    children: AuthorizedSigners,
    status: true,
  }
];

const OpenedApplicationTab = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const [expandIconPosition, setExpandIconPosition] =
    useState<ExpandIconPosition>("end");
  return (
    <div className="doc_details_sec">
      <div className="finaldoc">
        <div className="firsrrowsection">
          <Row className="main_client_filter">
            <Col xs={24} sm={24} md={18} lg={10} className="">
              <span className="headingTexts">User Application</span>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={8}
              lg={8}
              className="extraxtLeft onlyLarge"
            >
              <span className="headingTexts">Information Extracted</span>
            </Col>
          </Row>

          <Row className="main_client_filter">
            <Col
              xs={24}
              sm={24}
              md={18}
              lg={10}
              className="main_filter-container"
            >
              <div className="doc_info_borderText">
                <span className="phonenumer">Phone</span>
                <Input
                  className="doc_inputTextClass"
                  bordered={false}
                  placeholder="(000) 000-0000"
                />
              </div>
              <div className="idendoc">
                <h3>Identification document</h3>
                <img
                  src={identificationFront}
                  alt="licensefront"
                  className="licensefront-logo"
                />
                <img
                  src={identificationBack}
                  alt="licenseback"
                  className="licensefront-logo"
                />
                <h3>Selfie</h3>
                <img src={person} alt="selfie" className="selfie-logo" />
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={18}
              lg={8}
              className="second_filter-container"
            >
              <div className="info-extracted-list top">
                <Row gutter={[24, 8]}>
                  {Object.entries(InformationExtractedTop).map(([key, value]) =>
                    <>
                      <Col span={12} className="info-extracted-key">{key}</Col>
                      <Col span={12} className="info-extracted-value">{value}</Col>
                    </>
                  )}
                </Row>
              </div>
              <div className="info-extracted-list middle">
                <Row gutter={[24, 8]}>
                  {Object.entries(InformationExtractedMiddle).map(([key, value]) =>
                  <Col span={24}>
                    <Row gutter={[24, 8]} className={`${key === 'Date of Birth' ? 'redBg' : ''}`}>
                      <Col span={12} className={`info-extracted-key `}>{key}</Col>
                      <Col span={12} className={  `info-extracted-value`}>{value}</Col>
                    </Row>
                    </Col>
                  )}
                </Row>
              </div>
              <div className="info-extracted-list bottom">
                <Row gutter={[24, 8]}>
                  {Object.entries(InformationExtractedBottom).map(([key, value]) =>
                    <>
                      <Col span={12} className="info-extracted-key">{key}</Col>
                      <Col span={12} className="info-extracted-value">{value}</Col>
                    </>
                  )}
                </Row>
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={18}
              lg={4}
              className="third_filter-container"
            >
              <div className="rightdetails geolocation-verified">
                <span className="geolocationimg">
                  <img
                    src={rightsymbol}
                    alt="rightsymbol"
                    className="rightheader-logo"
                  />
                </span>
                <span className="geolocation">Geolocation verified</span>
              </div>


              <div className="rightdetails">
                <span className="geolocationimg">
                  <img
                    src={rightsymbol}
                    alt="rightsymbol"
                    className="rightheader-logo"
                  />
                </span>
                <span className="geolocation">ID authenticated</span>
              </div>
              <div className="rightdetails">
                <span className="geolocationimg">
                  <img
                    src={rightsymbol}
                    alt="rightsymbol"
                    className="rightheader-logo"
                  />
                </span>
                <span className="geolocation">Images processed & quality checked</span>
              </div>
              <div className="rightdetails">
                <span className="geolocationWrongimg">
                  <img
                    src={wrongsymbol}
                    alt="wrongsymbol"
                    className="rightheader-logo"
                  />
                </span>
                <span className="geolocation">
                  Public records
                </span>
              </div>
              <div className="rightdetails">
                <span className="geolocationimg">
                  <img
                    src={rightsymbol}
                    alt="rightsymbol"
                    className="rightheader-logo"
                  />
                </span>
                <span className="geolocation">Document type match</span>
              </div>
              <div className="rightdetails">
                <span className="geolocationimg">
                  <img
                    src={rightsymbol}
                    alt="rightsymbol"
                    className="rightheader-logo"
                  />
                </span>
                <span className="geolocation">Face match</span>
              </div>
              <div className="rightdetails">
                <span className="geolocationimg">
                  <img
                    src={rightsymbol}
                    alt="rightsymbol"
                    className="rightheader-logo"
                  />
                </span>
                <span className="geolocation">Date of birth matched</span>
              </div>
              <div className="rightdetails">
                <span className="geolocationimg">
                  <img
                    src={rightsymbol}
                    alt="rightsymbol"
                    className="rightheader-logo"
                  />
                </span>
                <span className="geolocation">Address match</span>
              </div>
              <div className="rightdetails">
                <span className="geolocationimg">
                  <img
                    src={rightsymbol}
                    alt="rightsymbol"
                    className="rightheader-logo"
                  />
                </span>
                <span className="geolocation">Document country match</span>
              </div>


              <div className="rightdetails live-human">
                <span className="geolocationimg">
                  <img
                    src={rightsymbol}
                    alt="rightsymbol"
                    className="rightheader-logo"
                  />
                </span>
                <span className="geolocation">Live human</span>
              </div>
            </Col>
          </Row>
          <Row className="main_client_filter secondrowdetails">
            <Col
              span={20}
              className="row_filter-container"
            >
              <div className="idendoc">
                <h3>Personal information</h3>
                <div className="personal-information-container">
                  <Form className="personalinfo-form">
                    <Row>
                      <Col span={8}>
                        <Form.Item name="firstname">
                          <div className={`info_borderText application-inputBox`}>
                            <span className="info_span">First Name</span>
                            <Input
                              className="info_inputTextClass"
                              placeholder="First Name"
                              bordered={false}
                              value={"Adam"}
                              required
                            />
                          </div>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item name="middlename" >
                          <div className={`info_borderText application-inputBox`}>
                            <span className="info_span placeholder">Middle Name</span>
                            <Input
                              className="info_inputTextClass"
                              placeholder="-"
                              bordered={false}
                              value={"-"}
                              required
                            />
                          </div>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item name="lastname" >
                          <div className={`info_borderText application-inputBox`}>
                            <span className="info_span placeholder">Last Name</span>
                            <Input
                              className="info_inputTextClass"
                              placeholder="-"
                              bordered={false}
                              value={"Smith"}
                              required
                            />
                          </div>
                        </Form.Item>
                      </Col>
                    </Row>
                    
                    <Row>
                      <Col span={8}>
                        <Form.Item name="dateofbirth">
                          <div className={`info_borderText application-inputBox `}>
                            <span className="info_span placeholder">Date Of Birth</span>
                            <Input
                              className="info_inputTextClass"
                              placeholder="Date Of Birth"
                              bordered={false}
                              value={"05/14/1980"}
                              required
                            />
                          </div>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item name="ssn" >
                          <div className={`info_borderText application-inputBox error`}>
                            <span className="info_span placeholder">SSN</span>
                            <Input
                              className="info_inputTextClass"
                              placeholder="SSN"
                              bordered={false}
                              value={"XXX-XX-0000"}
                              required
                            />
                          </div>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item name="email" >
                          <div className={`info_borderText application-inputBox`}>
                            <span className="info_span placeholder">Email</span>
                            <Input
                              className="info_inputTextClass"
                              placeholder="Email"
                              bordered={false}
                              value={"adam_smith@gmail.com"}
                              required
                            />
                          </div>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={12}>
                        <Form.Item name="city">
                          <div className={`info_borderText application-inputBox `}>
                            <span className="info_span placeholder">Street Address 1</span>
                            <Input
                              className="info_inputTextClass"
                              placeholder="streetaddress1"
                              bordered={false}
                              value={"520 Florida NE"}
                              required
                            />
                          </div>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item name="streetaddress2" >
                          <Flex vertical className={`info_borderText application-inputBox`}>
                            <span className="info_span placeholder">Street Address 2</span>
                            {/* <Input
                              className="info_inputTextClass"
                              placeholder="Street Address 2"
                              bordered={false}
                              value={""}
                              required
                            /> */}
                            <img src={ic_clock} alt="" style={{width: '20px', margin: '10px'}}/>
                          </Flex>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={14}>
                        <Form.Item name="city">
                          <div className={`info_borderText application-inputBox `}>
                            <span className="info_span placeholder">City</span>
                            <Input
                              className="info_inputTextClass"
                              placeholder="City"
                              bordered={false}
                              value={"Miami"}
                              required
                            />
                          </div>
                        </Form.Item>
                      </Col>
                      <Col span={5}>
                        <Form.Item name="state" >
                          <div className={`info_borderText application-inputBox`}>
                            <span className="info_span placeholder">State</span>
                            <Input
                              className="info_inputTextClass"
                              placeholder="State"
                              bordered={false}
                              value={"FL"}
                              required
                            />
                          </div>
                        </Form.Item>
                      </Col>
                      <Col span={5}>
                        <Form.Item name="zip" >
                          <div className={`info_borderText application-inputBox`}>
                            <span className="info_span placeholder">ZIP</span>
                            <Input
                              className="info_inputTextClass"
                              placeholder="ZIP"
                              bordered={false}
                              value={"33156"}
                              required
                            />
                          </div>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={12}>
                        <Form.Item name="employer">
                          <div className={`info_borderText application-inputBox `}>
                            <span className="info_span placeholder">Employer</span>
                            <Input
                              className="info_inputTextClass"
                              placeholder="Employer"
                              bordered={false}
                              value={"Adam’s Coffee House LLC"}
                              required
                            />
                          </div>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item name="jobtitle" >
                          <div className={`info_borderText application-inputBox`}>
                            <span className="info_span placeholder">Job title / Position with the company you are applying for</span>
                            <Input
                              className="info_inputTextClass"
                              placeholder="Job title"
                              bordered={false}
                              value={"Store manager"}
                              required
                            />
                          </div>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={18}
              lg={4}
              className="third_filter-container"
            >
              <div className="rightdetails personal-information-rightinfo">
                <span className="geolocationimg">
                  <img
                    src={rightsymbol}
                    alt="rightsymbol"
                    className="rightheader-logo"
                  />
                </span>
                <span className="geolocation">Provided information is verified</span>
              </div>
              <div className="rightdetails">
                <span className="geolocationimg">
                  <img
                    src={rightsymbol}
                    alt="rightsymbol"
                    className="rightheader-logo"
                  />
                </span>
                <span className="geolocation">Associated person match</span>
              </div>
              <div className="rightdetails">
                <span className="geolocationimg">
                  <img
                    src={rightsymbol}
                    alt="rightsymbol"
                    className="rightheader-logo"
                  />
                </span>
                <span className="geolocation">Name match</span>
              </div>
              <div className="rightdetails">
                <span className="geolocationWrongimg">
                  <img
                    src={wrongsymbol}
                    alt="wrongsymbol"
                    className="rightheader-logo"
                  />
                </span>
                <span className="geolocation">SSN not matched</span>
              </div>
              <div className="rightdetails">
                <span className="geolocationimg">
                  <img
                    src={rightsymbol}
                    alt="rightsymbol"
                    className="rightheader-logo"
                  />
                </span>
                <span className="geolocation">City and state match</span>
              </div>
              <div className="rightdetails">
                <span className="geolocationimg">
                  <img
                    src={rightsymbol}
                    alt="rightsymbol"
                    className="rightheader-logo"
                  />
                </span>
                <span className="geolocation">
                  Text about address
                </span>
              </div>
              <div className="rightdetails">
                <span className="geolocationimg">
                  <img
                    src={rightsymbol}
                    alt="rightsymbol"
                    className="rightheader-logo"
                  />
                </span>
                <span className="geolocation">
                  EIN and name information available
                </span>
              </div>
            </Col>
          </Row>


          <Row className="main_business_info">
            <Col
              span={20}
              className="row_filter-container"
            >
              <div className="idendoc">
                <h3>Business information</h3>
                <div className="personal-information-container">
                  <Form className="businessinfo-form">
                    <Row>
                      <Col span={12}>
                        <Form.Item name="businessname">
                          <div className={`info_borderText application-inputBox`}>
                            <span className="info_span">Business Name</span>
                            <Input
                              className="info_inputTextClass"
                              placeholder="Business Name"
                              bordered={false}
                              value={"Adam's Coffee House LLC"}
                              required
                            />
                          </div>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item name="doingbusiness" >
                          <div className={`info_borderText application-inputBox`}>
                            <span className="info_span placeholder">Doing Business As (DBA)</span>
                            <Input
                              className="info_inputTextClass"
                              placeholder=""
                              bordered={false}
                              value={"Adam’s Coffee House LLC"}
                              required
                            />
                          </div>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={12}>
                        <Form.Item name="ein">
                          <div className={`info_borderText application-inputBox `}>
                            <span className="info_span placeholder">EIN/TIN</span>
                            <Input
                              className="info_inputTextClass"
                              placeholder="EIN/TIN"
                              bordered={false}
                              value={"XX-XXX0000"}
                              required
                            />
                          </div>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item name="legaltype" >
                          <div className={`info_borderText application-inputBox`}>
                            <span className="info_span placeholder">Legal Type</span>
                            <Input
                              className="info_inputTextClass"
                              placeholder="Legal Type"
                              bordered={false}
                              value={"LLC (Limited Liability Company)"}
                              required
                            />
                          </div>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={12}>
                        <Form.Item name="industry">
                          <div className={`info_borderText application-inputBox `}>
                            <span className="info_span placeholder">My Industry</span>
                            <Input
                              className="info_inputTextClass"
                              placeholder="My Industry"
                              bordered={false}
                              value={"Accommodation and Food Services"}
                              required
                            />
                          </div>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item name="subindustry" >
                          <div className={`info_borderText application-inputBox`}>
                            <span className="info_span placeholder">Sub Industry</span>
                            <Input
                              className="info_inputTextClass"
                              placeholder="Sub Industry"
                              bordered={false}
                              value={"Snack and Non Alcoholic Beverages Bars"}
                              required
                            />
                          </div>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={18}>
                        <Form.Item name="purpose">
                          <div className={`info_borderText application-inputBox `}>
                            <span className="info_span placeholder">What is the primary purpose or function of this business?</span>
                            <Input
                              className="info_inputTextClass"
                              placeholder="What is the primary purpose or function of this business?"
                              bordered={false}
                              value={"Sale of drinks and pastries to the guests of the establishment"}
                              required
                            />
                          </div>
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item name="numberofemployees" >
                          <div className={`info_borderText application-inputBox`}>
                            <span className="info_span placeholder">Number Of Employees</span>
                            <Input
                              className="info_inputTextClass"
                              placeholder="Number Of Employees"
                              bordered={false}
                              value={"10"}
                              required
                            />
                          </div>
                        </Form.Item>
                      </Col>         
                    </Row>
      
                      <BIQuestionsBlock/>

                    <p className="inner-title phisical-address">Physical address of business</p>

                    <Row>
                      <Col span={12}>
                        <Form.Item name="city">
                          <div className={`info_borderText application-inputBox `}>
                            <span className="info_span placeholder">Street Address 1</span>
                            <Input
                              className="info_inputTextClass"
                              placeholder="streetaddress1"
                              bordered={false}
                              value={"520 Florida Ave NE"}
                              required
                            />
                          </div>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item name="streetaddress2" >
                          {/* <div className={`info_borderText application-inputBox`}>
                            <span className="info_span placeholder">Street Address 2</span>
                            <Input
                              className="info_inputTextClass"
                              placeholder="Street Address 2"
                              bordered={false}
                              value={""}
                              required
                            />
                          </div> */}


                          <Checkbox checked={true} className="checkbox" style={{marginTop: '40px'}}> Mailing address and physical address are the same</Checkbox>

                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={14}>
                        <Form.Item name="city">
                          <div className={`info_borderText application-inputBox `}>
                            <span className="info_span placeholder">City</span>
                            <Input
                              className="info_inputTextClass"
                              placeholder="City"
                              bordered={false}
                              value={"Miami"}
                              required
                            />
                          </div>
                        </Form.Item>
                      </Col>
                      <Col span={5}>
                        <Form.Item name="state" >
                          <div className={`info_borderText application-inputBox`}>
                            <span className="info_span placeholder">State</span>
                            <Input
                              className="info_inputTextClass"
                              placeholder="State"
                              bordered={false}
                              value={"FL"}
                              required
                            />
                          </div>
                        </Form.Item>
                      </Col>
                      <Col span={5}>
                        <Form.Item name="zip" >
                          <div className={`info_borderText application-inputBox`}>
                            <span className="info_span placeholder">ZIP</span>
                            <Input
                              className="info_inputTextClass"
                              placeholder="ZIP"
                              bordered={false}
                              value={"33156"}
                              required
                            />
                          </div>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={12}>
                        <h3>Articles of Organization</h3>
                        <img
                          src={doc1}
                          alt="doc1"
                          className="articleimg-logo"
                        />
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={18}
              lg={4}
              className="third_filter-container business-information-rightinfo"
            >
              <div className="rightdetails">
                <span className="geolocationimg">
                  <img
                    src={rightsymbol}
                    alt="rightsymbol"
                    className="rightheader-logo"
                  />
                </span>
                <span className="geolocation">
                  Provided information is verified
                </span>
              </div>
              <div className="rightdetails">
                <span className="geolocationimg">
                  <img
                    src={rightsymbol}
                    alt="rightsymbol"
                    className="rightheader-logo"
                  />
                </span>
                <span className="geolocation">
                  Associated person <br /> match
                </span>
              </div>
              <div className="rightdetails">
                <span className="geolocationimg">
                  <img
                    src={rightsymbol}
                    alt="rightsymbol"
                    className="rightheader-logo"
                  />
                </span>
                <span className="geolocation">Business name match</span>
              </div>
              <div className="rightdetails">
                <span className="geolocationimg">
                  <img
                    src={rightsymbol}
                    alt="rightsymbol"
                    className="rightheader-logo"
                  />
                </span>
                <span className="geolocation">Business is active</span>
              </div>
              <div className="rightdetails">
                <span className="geolocationimg">
                  <img
                    src={rightsymbol}
                    alt="rightsymbol"
                    className="rightheader-logo"
                  />
                </span>
                <span className="geolocation">
                  Business in multiple <br /> states (US){" "}
                </span>
              </div>
              <div className="rightdetails">
                <span className="geolocationimg">
                  <img
                    src={rightsymbol}
                    alt="rightsymbol"
                    className="rightheader-logo"
                  />
                </span>
                <span className="geolocation">Company not on OFAC list</span>
              </div>
              <div className="rightdetails">
                <span className="geolocationimg">
                  <img
                    src={rightsymbol}
                    alt="rightsymbol"
                    className="rightheader-logo"
                  />
                </span>
                <span className="geolocation">
                  Incorporation date <br /> match
                </span>
              </div>
              <div className="rightdetails">
                <span className="geolocationimg">
                  <img
                    src={rightsymbol}
                    alt="rightsymbol"
                    className="rightheader-logo"
                  />
                </span>
                <span className="geolocation">
                  User associated with the provided business name
                </span>
              </div>
              <div className="rightdetails">
                <span className="geolocationimg">
                  <img
                    src={rightsymbol}
                    alt="rightsymbol"
                    className="rightheader-logo"
                  />
                </span>
                <span className="geolocation">City and state match</span>
              </div>
              <div className="rightdetails">
                <span className="geolocationimg">
                  <img
                    src={rightsymbol}
                    alt="rightsymbol"
                    className="rightheader-logo"
                  />
                </span>
                <span className="geolocation">Address match</span>
              </div>
              <div className="rightdetails">
                <span className="geolocationimg">
                  <img
                    src={rightsymbol}
                    alt="rightsymbol"
                    className="rightheader-logo"
                  />
                </span>
                <span className="geolocation">
                  TIN/EIN and name <br /> match
                </span>
              </div>
              <div className="rightdetails">
                <span className="geolocationimg">
                  <img
                    src={rightsymbol}
                    alt="rightsymbol"
                    className="rightheader-logo"
                  />
                </span>
                <span className="geolocation">
                  Possible death master file match found
                </span>
              </div>
              <div className="rightdetails">
                <span className="geolocationimg">
                  <img
                    src={rightsymbol}
                    alt="rightsymbol"
                    className="rightheader-logo"
                  />
                </span>
                <span className="geolocation">
                  EIN and name <br />
                  information available
                </span>
              </div>
              <div className="rightdetails">
                <span className="geolocationimg">
                  <img
                    src={rightsymbol}
                    alt="rightsymbol"
                    className="rightheader-logo"
                  />
                </span>
                <span className="geolocation">Lists No match</span>
              </div>
              <div className="rightdetails">
                <span className="geolocationimg">
                  <img
                    src={rightsymbol}
                    alt="rightsymbol"
                    className="rightheader-logo"
                  />
                </span>
                <span className="geolocation">Name and SSN match</span>
              </div>
            </Col>
          </Row>
          <div className="beneficialowner">
            <Row className="beneficialownerorgazationsecond">
              <Col
                span={20}
                className="beneficialowner-container"
              >
                <h2>Beneficial ownership</h2>

                <div className="beneficial-ownership">
                  <Row>
                    <Col span={18}>
                     <p className="i-am-responsible">
                        I am a Responsible Individual and there are other owners who own 25% and more
                      </p>
                    </Col>
                    <Col span={6}>
                      <Form.Item name="iown" >
                        <div className={`info_borderText application-inputBox`}>
                          <span className="info_span placeholder">I own (up to 75%)</span>
                          <Input
                            className="info_inputTextClass"
                            placeholder="Ownership"
                            bordered={false}
                            value={"25%"}
                            required
                          />
                        </div>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
          <div className="beneficialowner detailsBen">
            <Row className="beneficialownerorgazationsecond">
              <Col
                xs={24}
                sm={24}
                md={18}
                lg={24}
                className="beneficialowner-container"
              >
                <p className="other-persons-header">Responsible Individual/s</p>
                <BeneficialOwnerList owners={responsibleIndividualsList} />
              </Col>
            </Row>
          </div>

          <div className="beneficialowner detailsBen">
            <Row className="beneficialownerorgazationsecond">
              <Col
                xs={24}
                sm={24}
                md={18}
                lg={24}
                className="beneficialowner-container"
              >
                <p className="other-persons-header">Beneficial Owner/s</p>
                <BeneficialOwnerList owners={beneficialOwnerList} />
              </Col>
            </Row>
          </div>

          <div className="beneficialowner detailsBen">
            <Row className="beneficialownerorgazationsecond">
              <Col
                xs={24}
                sm={24}
                md={18}
                lg={24}
                className="beneficialowner-container"
              >
                <p className="other-persons-header">Authorized Signer/s</p>
                <BeneficialOwnerList owners={authorizedSignersList} />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpenedApplicationTab;
