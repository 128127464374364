import React, { ClassAttributes, ThHTMLAttributes, useContext, useEffect, useState } from "react";
// import { Layout, Table } from "antd";
import {
  Table,
  Row,
  Col,
  Image,
  Input,
  DatePicker,
  Checkbox,
  Dropdown,
  Button,
  Empty,
  Tooltip,
  Flex,
} from "antd";
import { useNavigate } from "react-router-dom";

import ic_search from "../../Assets/Images/icons/ic_search.svg";
import ascend from "../../Assets/Images/ascendSvg.svg";
import rightVector from "../../Assets/Images/rightvectorSvg.svg";
import exportPDF from "../../Assets/Images/icons/ic_document.svg";
import leftVector from "../../Assets/Images/leftvectorSvg.svg";
import createapplnBg from "../../Assets/Images/createapplnBg.png";
import filterIcon from "../../Assets/Images/filter.svg";
import ic_big_arrow_down from "../../Assets/Images/icons/ic_big_arrow_down.svg";
import ic_big_arrow_up from "../../Assets/Images/icons/ic_big_arrow_up.svg";
import ic_clock from "../../Assets/Images/icons/ic_clock.svg";
import filtericonOpen from "../../Assets/Images/filtericonOpenSvg.svg";
import ic_circle_cross from "../../Assets/Images/icons/ic_circle_cross.svg";
import "./ApplicationTable.scss";
import type { ColumnsType } from "antd/es/table";
import notFoundIcon from "../../Assets/Images/icons/ic_data_not_found.svg";
import ClientFilter from "../../Components/ClientsFilter/ClientFilters";
import PrivacyPolicy from "../PrivayPolicy/PrivacyPolicy";
import FixedHeaderApplnTable from "../FixedHeaderApplnTable/FixedHeaderApplnTable";
import tableJsonData from "./ApplicationTableData.json";
import moment from "moment";
import {
  IAppTableFilterValuesWithKey,
  IFilterValues,
  ISelectedRows,
  InitialApplicationFilterValue,
} from "../../Contracts/Contracts";
import ic_arrow_left from "../../Assets/Images/icons/ic_arrow_left.svg";
import ic_arrow_right from "../../Assets/Images/icons/ic_arrow_right.svg";
import { ReactComponent as CalendarIcon } from "../../Assets/Images/icons/ic_calendar.svg";
import { ApplicationContext } from "../../Context/ApplicationContextProvider";
import dayjs from "dayjs";
import FilteredTabs from "./FilteredTabs/FilteredTabs";
import {
  formatDate,
  getValueFromLocalStorage,
  setValueInStorage,
} from "../Utility/Utility";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

type status = "NEW" | "IN PROGRESS" | "SUCCESSFULL";

const STATUS_TAG: Record<status, React.ReactNode> = {
  NEW: (
    <div className="newbg">
      <span className="newtext">NEW</span>
    </div>
  ),
  "IN PROGRESS": (
    <div className="newprogrss">
      <span className="progresstext">IN PROGRESS</span>
    </div>
  ),
  SUCCESSFULL: (
    <div className="newsuccess">
      <span className="successtext">SUCCESSFUL</span>
    </div>
  ),
};

const rowSelection = {
  onChange: (selectedRowKeys: React.Key[], selectedRows: ISelectedRows[]) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
};

let sortedColumn: any;

const alldata = tableJsonData.tableList;

const ApplicationTable = () => {
  //@ts-ignore
  console.log("alldata: ", alldata);
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("applications");
  });
  const { setGlobalFillterStatus, globalFilterStatus } =
    useContext(ApplicationContext);
  const [tableData, setTableData] = useState<ISelectedRows[]>([]);
  const [codeNumber, setCodeNumber] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currentFilterName, setCurrentFilterName] = useState({
    id: "",
    name: "",
  });
  const [deleteFilter, setDeleteFilter] = useState(false);
  const [filterValues, setFilterValues] =
    useState<IAppTableFilterValuesWithKey>(InitialApplicationFilterValue);
  const [searchText, setSearchText] = useState<string | undefined>("");
  const [deletedFilterKey, setDeletedFilterKey] = useState<string | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const useSortOrderState = (initialValue: any) => {
    return useState<"ascend" | "descend" | null>(initialValue);
  };

  const [column1SortOrder, setColumn1SortOrder] = useSortOrderState(null);
  const [column2SortOrder, setColumn2SortOrder] = useSortOrderState(null);
  const [column3SortOrder, setColumn3SortOrder] = useSortOrderState(null);
  const [column4SortOrder, setColumn4SortOrder] = useSortOrderState(null);
  const [column5SortOrder, setColumn5SortOrder] = useSortOrderState(null);
  const [column6SortOrder, setColumn6SortOrder] = useSortOrderState(null);
  const [column7SortOrder, setColumn7SortOrder] = useSortOrderState(null);

  useEffect(() => {
    setTableData(alldata);
    setValueInStorage("openApplicationsLength", alldata.length.toString());
  }, []);

  useEffect(() => {
    if (globalFilterStatus) {
      // Reset the state when globalFilterStatus changes to true
      setFilterValues(InitialApplicationFilterValue);
    } else {
      setSearchText("");
    }
  }, [globalFilterStatus]);

  useEffect(() => {
    const savedFilterValues = JSON.parse(
      getValueFromLocalStorage("appTableFilterData") || "[]"
    );
    const caseDefaultChecked = JSON.parse(
      getValueFromLocalStorage("applicationDefaultChecked?") || "{}"
    );

    if (caseDefaultChecked.makeDefault === "true") {
      const matchingFilter = savedFilterValues.find(
        (item: { filterId: any }) =>
          item.filterId.toString() === caseDefaultChecked.id
      );
      if (matchingFilter) {
        setFilterValues(matchingFilter);
      } else {
        setFilterValues(InitialApplicationFilterValue);
      }
    }
    // else if (savedFilterValues.length > 0) {
    //   setFilterValues(savedFilterValues[savedFilterValues.length - 1]);
    // }
    else {
      setFilterValues(InitialApplicationFilterValue);
    }
  }, []);

  const toggleSortOrder = (column: string) => {
    const sortOrderMapping: any = {
      ID: [column1SortOrder, setColumn1SortOrder],
      Cdate: [column2SortOrder, setColumn2SortOrder],
      Udate: [column3SortOrder, setColumn3SortOrder],
      Bname: [column4SortOrder, setColumn4SortOrder],
      Fname: [column5SortOrder, setColumn5SortOrder],
      Lname: [column6SortOrder, setColumn6SortOrder],
      Status: [column7SortOrder, setColumn7SortOrder],
    };

    for (const columnName in sortOrderMapping) {
      const [sortOrder, setSortOrder] = sortOrderMapping[columnName];
      if (column === columnName) {
        if (sortOrder === "ascend") {
          setSortOrder("descend");
        } else {
          setSortOrder("ascend");
        }
      } else {
        setSortOrder(null);
      }
    }
  };

  useEffect(() => {
    if (deletedFilterKey) {
      const updatedFilterValues = { ...filterValues };
      if (deletedFilterKey in updatedFilterValues) {
        delete updatedFilterValues[deletedFilterKey];
        setFilterValues(updatedFilterValues);
      }
    }
    setDeletedFilterKey(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletedFilterKey]);

  useEffect(() => {
    //To delete the filters from the saved filter table
    if (deleteFilter) {
      setFilterValues(InitialApplicationFilterValue);
    }
    setDeleteFilter(false);
  }, [deleteFilter]);

  useEffect(() => {
    // Filter the data based on filterValues
    const filteredData = alldata.filter((item: any) => {
      for (const key in filterValues) {
        if (filterValues[key as keyof IFilterValues] && key !== "filterId") {
          if (key === "dob") {
            const formattedDate = formatDate(
              filterValues[key as keyof IFilterValues] as string
            );
            if (String(!item[key]).includes(formattedDate)) {
              return false;
            }
          } else {
            if (
              !String(filterValues[key as keyof IFilterValues])
                ?.toLowerCase()
                .includes(item[key]?.toLowerCase())
            ) {
              return false;
            }
          }
        }
      }
      return true;
    });
    // Filter the data based on date range
    // if (startDate && endDate) {
    //   const sDate = moment(startDate);
    //   const eDate = moment(endDate);
    //   // const dateFilteredData = filteredData.filter(
    //   //   (value) => moment(value.Cdate) >= sDate && moment(value.Udate) <= eDate
    //   // );

    //   const dateFilteredData = filteredData.filter(
    //     (value) =>
    //       moment(value.Cdate)
    //         .startOf("day")
    //         .isSameOrAfter(sDate.startOf("day")) &&
    //       moment(value.Cdate)
    //         .startOf("day")
    //         .isSameOrBefore(eDate.startOf("day"))
    //   );

    //   setTableData(dateFilteredData);
    // } else {
    //   setTableData(filteredData);
    // }
    console.log("filteredData:  ", filteredData);
    setTableData(filteredData);
    // }, [filterValues, startDate, endDate]);
  }, [filterValues]);

  const getDateResult = (list: ISelectedRows[]) => {
    var sDate = moment(startDate);
    var eDate = moment(endDate);
    // var result = list.filter(
    //   (value) => moment(value.Cdate) >= sDate && moment(value.Cdate) <= eDate
    // );
    var result = list.filter(
      (value) =>
        moment(value.Cdate)
          .startOf("day")
          .isSameOrAfter(sDate.startOf("day")) &&
        moment(value.Cdate).startOf("day").isSameOrBefore(eDate.startOf("day"))
    );
    return result;
  };

  const [selectionType, setSelectionType] = useState<"checkbox" | "radio">(
    "checkbox"
  );

  const openFilter = () => {
    setCodeNumber(!codeNumber);
  };

  const handleSearch = (searchText: string) => {
    setSearchText(searchText); // Set searchText to the new value entered in the input field
    var result: ISelectedRows[] = [];

    if (!searchText) {
      // If searchText is empty, clear the input field
      setSearchText(""); // Set searchText to empty
      result = alldata;
    } else {
      result = getSearchResults(alldata, searchText);
      setGlobalFillterStatus(true);
    }

    if (startDate && endDate) {
      result = getDateResult(result);
    }

    setTableData(result);
  };

  const getSearchResults = (list: ISelectedRows[], text: string) => {
    let result: any = [];
    if (text?.length) {
      setGlobalFillterStatus(true);
      result = list.filter(
        (value) =>
          value.Bname.toLowerCase().includes(text?.toLowerCase()) ||
          value.ID.toLowerCase().includes(text.toLowerCase()) ||
          value.Status.toLowerCase().includes(text.toLowerCase()) ||
          value.Fname.toLowerCase().includes(text.toLowerCase()) ||
          value.Lname.toLowerCase().includes(text.toLowerCase())
      );
    }
    return result;
  };

  const navigate = useNavigate();

  const handleNavigateToDetailPage = () => {
    navigate("/openappln");
  };

  const columns: ColumnsType<ISelectedRows> = [
    {
      key: "key",
      dataIndex: "ID",
      sortOrder: column1SortOrder,
      onHeaderCell: () => ({
        onClick: () => toggleSortOrder("ID"),
      }),
      sorter: (a, b) => moment(a.Cdate).unix() - moment(b.Cdate).unix(),
      title: (titleProps) => {
        titleProps.sortColumns?.map((result) => {
          if (result.column.dataIndex == "ID") sortedColumn = result.order;
          else sortedColumn = "";
        });
        return getColumnLabel(column1SortOrder, "Application ID");
      },
      render: (text: string, row) => {
        return (
          <div style={{ justifyContent: "spaceBetween" }}>
            {text ? (
              <span
                className=""
                style={{
                  cursor: "pointer",
                  textDecoration:
                    text === "SMB-123456788" ? "underline" : "none",
                }}
              >
                {text}
              </span>
            ) : (
              <Tooltip title="No data provided yet" arrow={false}>
                <img width={20} height={20} src={ic_clock} alt="no data" />
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      key: "key",
      dataIndex: "Cdate",
      sorter: (a, b) => moment(a.Cdate).unix() - moment(b.Cdate).unix(),
      sortOrder: column2SortOrder,
      onHeaderCell: () => ({
        onClick: () => toggleSortOrder("Cdate"),
      }),
      title: (titleProps) => {
        titleProps.sortColumns?.map((result) => {
          if (result.column.dataIndex == "Cdate") sortedColumn = result.order;
          else sortedColumn = "";
        });
        return getColumnLabel(column2SortOrder, "Created");
      },
      render: (text: string, row) =>
        text || (
         <Tooltip title="No data provided yet" arrow={false}>
              <Flex>
                <img width={20} height={20} src={ic_clock} alt="no data" />
              </Flex>
            </Tooltip>
        ),
    },
    {
      dataIndex: "Udate",
      key: "key",
      sorter: (a, b) => moment(a.Udate).unix() - moment(b.Udate).unix(),
      sortOrder: column3SortOrder,
      onHeaderCell: () => ({
        onClick: () => toggleSortOrder("Udate"),
      }),
      title: (titleProps) => {
        titleProps.sortColumns?.map((result) => {
          if (result.column.dataIndex == "Udate") sortedColumn = result.order;
          else sortedColumn = "";
        });
        return getColumnLabel(column3SortOrder, "Last Updated");
      },
      render: (text: string, row) =>
        text || (
         <Tooltip title="No data provided yet" arrow={false}>
              <Flex>
                <img width={20} height={20} src={ic_clock} alt="no data" />
              </Flex>
            </Tooltip>
        ),
    },
    {
      key: "key",
      dataIndex: "Bname",
      sorter: (a, b) => a.Bname.localeCompare(b.Bname),
      sortOrder: column4SortOrder,
      onHeaderCell: () => ({
        onClick: () => toggleSortOrder("Bname"),
      }),
      title: (titleProps) => {
        titleProps.sortColumns?.map((result) => {
          if (result.column.dataIndex == "Bname") sortedColumn = result.order;
          else sortedColumn = "";
        });
        return getColumnLabel(column4SortOrder, "Business Name");
      },
      render: (text: string, row) => {
        return text ? (
          <Tooltip title={text} arrow={false}>
            <div>
              <span
                className="ellipsis"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  // navigate("/openappln");
                }}
              >
                {text.length > 20 ? text.substring(0, 20) + "..." : text}
              </span>
            </div>
          </Tooltip>
        ) : (
            <Tooltip title="No data provided yet" arrow={false}>
              <Flex>
                <img width={20} height={20} src={ic_clock} alt="no data" />
              </Flex>
            </Tooltip>
        );
      },
    },
    {
      key: "key",
      dataIndex: "Fname",
      sorter: (a, b) => a.Fname.localeCompare(b.Fname),
      sortOrder: column5SortOrder,
      onHeaderCell: () => ({
        onClick: () => toggleSortOrder("Fname"),
      }),
      title: (titleProps) => {
        titleProps.sortColumns?.map((result) => {
          if (result.column.dataIndex == "Fname") sortedColumn = result.order;
          else sortedColumn = "";
        });
        return getColumnLabel(column5SortOrder, "First Name");
      },
      render: (text: string, row) =>
        text || (
         <Tooltip title="No data provided yet" arrow={false}>
              <Flex>
                <img width={20} height={20} src={ic_clock} alt="no data" />
              </Flex>
            </Tooltip>
        ),
    },
    {
      key: "key",
      dataIndex: "Lname",
      sorter: (a, b) => a.Lname.localeCompare(b.Lname),
      sortOrder: column6SortOrder,
      onHeaderCell: () => ({
        onClick: () => toggleSortOrder("Lname"),
      }),
      title: (titleProps) => {
        titleProps.sortColumns?.map((result) => {
          if (result.column.dataIndex == "Lname") sortedColumn = result.order;
          else sortedColumn = "";
        });
        return getColumnLabel(column6SortOrder, "Last Name");
      },
      render: (text: string, row) =>
        text || (
         <Tooltip title="No data provided yet" arrow={false}>
              <Flex>
                <img width={20} height={20} src={ic_clock} alt="no data" />
              </Flex>
            </Tooltip>
        ),
    },
    {
      title: (titleProps) => {
        titleProps.sortColumns?.map((result) => {
          if (result.column.dataIndex == "Status") sortedColumn = result.order;
          else sortedColumn = "";
        });
        return getColumnLabel(column7SortOrder, "Status");
      },
      key: "key",
      dataIndex: "Status",
      sorter: (a, b) => a.Status.localeCompare(b.Status),
      sortOrder: column7SortOrder,
      onHeaderCell: () => ({
        onClick: () => toggleSortOrder("Status"),
      }),
      render: (text: string, row) => {
        return (
          <div style={{ justifyContent: "spaceBetween" }}>
            {STATUS_TAG[row.Status as status]}
          </div>
        );
      },
    },
  ];

  const getColumnLabel = (sortedColumn: any, text: string) => {
    return (
      <div style={{ justifyContent: "spaceBetween" }}>
        {text}
        {(() => {
          if (sortedColumn == "ascend") {
            return (
              <img
                src={ascend}
                alt="addcase"
                className="ascendImg cursor"
                style={{ width: 6, height: 10 }}
              />
            );
          } else if (sortedColumn == "descend") {
            return (
              <img
                src={ascend}
                alt="addcase"
                className="ascendImg cursor"
                style={{ width: 6, height: 10, transform: "rotate(180deg)" }}
              />
            );
          }
        })()}
      </div>
    );
  };

  const handleSelect = (date: any, type: string) => {
    if (!date) {
      if (type == "StartDate") {
        setStartDate(null);
      } else if (type == "EndDate") {
        setEndDate(null);
      }
      if (searchText) {
        var result = getSearchResults(alldata, searchText);
        setTableData(result);
      } else {
        setTableData(alldata);
      }

      return;
    }

    if (type == "StartDate") {
      setStartDate(date.format());
    } else {
      setEndDate(date.format());
    }
  };

  const handleDropdownVisibleChange = (visible: boolean) => {
    setIsDropdownOpen(visible);
  };

  const takeActionmenu = (
    <ClientFilter
      setFilterValues={setFilterValues}
      filterValues={filterValues}
      setCodeNumber={setCodeNumber}
      setCurrentFilterName={setCurrentFilterName}
      setIsDropdownOpen={setIsDropdownOpen}
    />
  );

  const countKeysWithValues = (filterValues: any) => {
    let count = 0;
    for (const key in filterValues) {
      if (
        key !== "filterId" &&
        filterValues[key] !== null &&
        filterValues[key] !== undefined &&
        filterValues[key] !== ""
      ) {
        count++;
      }
    }
    return count;
  };

  const filterCount = countKeysWithValues(filterValues);

  return (
    <div className="">
      <FixedHeaderApplnTable
        currentFilterName={currentFilterName}
        setFilterValues={setFilterValues}
        setDeleteFilter={setDeleteFilter}
      />
      <div className="appTable">
        <Input
          placeholder="Search"
          className="search-box"
          onChange={(e) => handleSearch(e.target.value)}
          value={searchText}
          prefix={
            <img src={ic_search} alt="search" style={{ marginRight: "6px" }} />
          }
          suffix={
            <img
              src={ic_circle_cross}
              alt="search"
              style={{
                cursor: "pointer",
                display: searchText ? "block" : "none",
              }}
              onClick={() => handleSearch("")}
              className="search-clear-icon"
            />
          }
        />
        <div className="filter-box">
          <div className="onlyFilterSection">
            <div className="filterleft cursor floatLeft">
              <Dropdown
                overlay={takeActionmenu}
                trigger={["click"]}
                open={isDropdownOpen}
                className="dropdown-filters"
                onOpenChange={handleDropdownVisibleChange}
              >
                <Button
                  icon={
                    <img
                      src={filterIcon}
                      alt="filterIcon"
                      className="filter-appl-buttton"
                      onClick={openFilter}
                    />
                  }
                >
                  <span style={{ marginRight: "30px" }}>
                    Filters{" "}
                    {!!filterCount && (
                      <span style={{ color: "#7A8EBA" }}>{filterCount}</span>
                    )}
                  </span>
                  <img
                    src={isDropdownOpen ? ic_big_arrow_up : ic_big_arrow_down}
                    alt="arrow icon"
                    className="filterIcon"
                  />
                </Button>
              </Dropdown>
            </div>

            <div className="menuSections floatRight">
              <div className="date_borderText floatLeft">
                <span className="info_span correctSpan">
                  Created Start Date
                </span>

                <DatePicker
                  suffixIcon={<CalendarIcon />}
                  bordered={false}
                  className="callSche callDate cursor alignDateInBox"
                  onChange={(e) => {
                    handleSelect(e, "StartDate");
                  }}
                  format="MM/DD/YYYY"
                  renderExtraFooter={() => (
                    <div className="alignAnytime">
                      <Checkbox className="anyTime">Anytime</Checkbox>
                    </div>
                  )}
                  showToday={false}
                  value={startDate ? dayjs(startDate) : null}
                />
              </div>
              <span className="toTextDate floatLeft"> to </span>

              <div className="date_borderText floatLeft">
                <span className="info_span correctSpan">Created End Date</span>

                <DatePicker
                  suffixIcon={<CalendarIcon />}
                  bordered={false}
                  className="callSche callDate cursor alignDateInBox"
                  onChange={(e) => {
                    handleSelect(e, "EndDate");
                  }}
                  format="MM/DD/YYYY"
                  renderExtraFooter={() => (
                    <div className="alignAnytime">
                      <Checkbox className="anyTime">Anytime</Checkbox>
                    </div>
                  )}
                  showToday={false}
                  value={endDate ? dayjs(endDate) : null}
                />
              </div>
            </div>
          </div>

          <Row className="FilteredListWidth">
            <FilteredTabs
              filterValues={filterValues}
              setDeletedFilterKey={setDeletedFilterKey}
            />
          </Row>
        </div>
        <div>
          <div className="headingTable">
            <div className="resultSec marginTop20">
              <span className="results">
                {tableData.length}{" "}
                {tableData.length == 1 ? "Result" : "Results"}
              </span>
            </div>

            <div className="exportSec cursor">
              <div
                className="buttonBg floatLeft marginRight30 createButton"
                // onClick={() => {
                //   window.open(
                //     window.location.host.includes("sandbox")
                //       ? "https://app-loquat-web-sandbox-001.azurewebsites.net/"
                //       : "https://app-loquat-web-dev-003.azurewebsites.net",
                //     "_blank"
                //   );
                // }}
              >
                <span className="buttonText">Create Application</span>
              </div>
              <div className="floatRight marginTop20">
                <span className="exportText">Export to PDF</span>
                <Image
                  src={exportPDF}
                  alt="front"
                  loading="lazy"
                  preview={false}
                  className="excelImage"
                />
              </div>
            </div>
          </div>

          <Table
            rowKey={"ID"}
            rowSelection={{
              type: selectionType,
              ...rowSelection,
            }}
            columns={columns}
            dataSource={tableData}
            // dataSource={[]}
            bordered
            pagination={false}
            showSorterTooltip={false}
            onRow={(item) => ({
              onClick: () => {
                //@ts-ignore

                item.key == 2 && navigate("/openappln");
              },
            })}
            locale={{
              emptyText: (
                <span className="emptyTableText">
                  <img src={notFoundIcon} alt="no data" />
                  You have no applications to display
                </span>
              ),
            }}
            components={{
              header: {
                cell: (
                  props: JSX.IntrinsicAttributes &
                    ClassAttributes<HTMLTableCellElement> &
                    ThHTMLAttributes<HTMLTableCellElement>,
                ) => {
                  if (
                    Array.isArray(props.children) &&
                    props.children[1]?.props?.className === "ant-table-selection"
                  ) {
                    return (
                      <Tooltip title="Select all items">
                        <th {...props} />
                      </Tooltip>
                    );
                  }
                  return <th {...props} />;
                },
              },
            }}
    
          />

          <div id="pagnations">
            <div className="paginationSection floatLeft cursor marginTopPage">
              <img src={ic_arrow_left} alt="leftNav" className="" />
            </div>
            <div>
              <span className="pageNum">
                1 to {tableData.length} of {tableData.length}
              </span>
            </div>
            <div className="paginationSection floatRight cursor marginTopPage">
              <img
                src={ic_arrow_right}
                alt="rightNav"
                className="darkerImage"
              />
            </div>
          </div>
        </div>
      </div>
      <div className={tableData.length == 0 ? "reduceMarginFooter" : ""}>
        <PrivacyPolicy></PrivacyPolicy>
      </div>
    </div>
  );
};

export default ApplicationTable;
