import React, { useState, useEffect } from "react";
import "./ValidateApplnTabs.scss";
import { Layout, Tabs, Row, Col, Image, Input } from "antd";
import type { TabsProps } from "antd";
import { useNavigate } from "react-router-dom";
import SupportPopup from "../SupportPopup/SupportPopup";
import ValidateDetailsTab from "../ValidateDetailsTabs/ValidateDetailsTabs";
import OpenedApplicationTab from "../OpenedApplicationTab/OpenedApplicationTab";
import ApplicationsEvidenceTab from "../ApplicationsEvidenceTab/ApplicationsEvidenceTab";
import ApplicationsHistoryTab from "../ApplicationsHistoryTab/ApplicationsHistoryTab";
const { Search } = Input;

const onChange = (key: string) => {
  console.log(key);
};

const items: TabsProps["items"] = [
  {
    key: "1",
    label: "Details",
    // children: <ValidateDetailsTab></ValidateDetailsTab>,
    children: <OpenedApplicationTab></OpenedApplicationTab>,
  },
  {
    key: "2",
    label: "Evidence",
    children: <ApplicationsEvidenceTab></ApplicationsEvidenceTab>,
  },

  {
    key: "3",
    label: "History",
    children: <ApplicationsHistoryTab></ApplicationsHistoryTab>,
  },
];

const CaseDetailsTab = () => {
  const [showPopup, setShowPopup] = useState(false);
  const openPopup = () => {
    setShowPopup(true);
  };
  const navigate = useNavigate();

  useEffect(() => {
    document
      .getElementById("suppportIcon")!
      .addEventListener("click", function () {
        openPopup();
      });
  }, []);
  const callbackTabClicked = (key: any, event: any) => {
    if (key === "1") {
      navigate("/applications");
    }
    if (key === "2") {
      navigate("/applications?from=cases");
    }
  };
  return (
    <Layout className="case-tabs-layout">
      <div className="">
        <Tabs
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
          className="case-tabs-background "
        />
        <SupportPopup
          showPopup={showPopup}
          setShowPopup={setShowPopup}
        ></SupportPopup>
      </div>
    </Layout>
  );
};
export default CaseDetailsTab;
