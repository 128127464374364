import React, { useContext, useEffect, useState } from "react";
import { ApplicationContext } from "../../Context/ApplicationContextProvider";
import { Button, Image, Modal, Layout, Space, Radio } from "antd";
import okBtn from "../../Assets/Images/okBg.png";

import "./RefreshData.scss";

import { IStepsHeader } from "../../Contracts/Contracts";

const { Header, Footer, Sider, Content } = Layout;

type FinalPopupProps = {
  showPopup: boolean;
  setShowPopup: (value: boolean) => void;
};
const RefreshData = (popupProps: FinalPopupProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => {
    popupProps.setShowPopup(false);
  };

  const handleCancel = () => {
    popupProps.setShowPopup(false);
  };

  return (
    <Modal
      centered
      open={popupProps.showPopup}
      onOk={handleOk}
      onCancel={handleCancel}
      width={600}
      title="All the information is up to date"
      footer={[]}
    >
      <div className="refreshBtns">
        <div
          className="buttonBg cursor margin35 createButton"
          onClick={handleOk}
        >
          <span className="buttonText">OK</span>
        </div>
        ,
      </div>
    </Modal>
  );
};

export default RefreshData;
