import React, { useContext, useEffect, useState } from "react";
import { ApplicationContext } from "../../Context/ApplicationContextProvider";
import { Layout, Input, Modal, Checkbox, Select, Space } from "antd";

import "./Escalate.scss";

import escalatepopup from "../../Assets/Images/escalateBg.png";
import starimage from "../../Assets/Images/starSvg.svg";

const size = "large";
const { Header, Footer, Sider, Content } = Layout;
const { TextArea } = Input;

type FinalPopupProps = {
  showPopup2: boolean;
  setShowPopup: (value: boolean) => void;
};
const Escalate = (popupProps: FinalPopupProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => {
    popupProps.setShowPopup(false);
  };

  const handleCancel = () => {
    popupProps.setShowPopup(false);
  };
  return (
    <Modal
      centered
      open={popupProps.showPopup2}
      onOk={handleOk}
      onCancel={handleCancel}
      width={800}
      title="Please fill in these fields"
      className="escalate widthOfPopup"
      footer={[
            <div className="buttonBg floatRight cursor margin65" onClick={handleOk}>
              <img src={escalatepopup} alt="escalatepopup" className="buttonWidthEscalate" />
              <span className="buttonTextEscalate">Escalate</span>
            </div>
      ]}
    >
      <div className="escalate-container">
        <div className="passwordFieldsBg">
          {/* <Input bordered={false} className="passwordFields" placeholder="Assignee" /> */}
          <img className="cursor starimage" src={starimage} alt="starimage"/>
          <Space wrap className="fullWidth">

          <Select
                size={size}
                className="passwordFields"
                placeholder="Assignee"
                options={[
                  { value: "John", label: "John" },
                  { value: "James", label: "James" },
                  { value: "Brown", label: "Brown" },
                ]}
              />
              </Space>
        </div>


        <div className="passwordFieldsBg DescDiv">
          <img className="cursor starimage" src={starimage} alt="starimage"/>
          <TextArea
            bordered={false}
            className="detaistextarea"
            rows={4}
            placeholder="Comment"
            style={{resize: 'none' }}

          />
        </div>

      </div>
    </Modal>
  );
};

export default Escalate;
