import React, { useContext, useEffect, useState } from "react";
import { ApplicationContext } from "../../Context/ApplicationContextProvider";
import { Layout, Input, Modal, Checkbox } from "antd";

import "./EmailApplicant.scss";

import { IStepsHeader } from "../../Contracts/Contracts";
import send from "../../Assets/Images/sendMailBg.png";
const { Header, Footer, Sider, Content } = Layout;
const { TextArea } = Input;

type FinalPopupProps = {
  showPopup: boolean;
  popupHeading:string;
  checkboxText:string;
  setShowPopup: (value: boolean) => void;
};
const EmailApplicant = (popupProps: FinalPopupProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => {
    popupProps.setShowPopup(false);
  };

  const handleCancel = () => {
    popupProps.setShowPopup(false);
  };
  return (
    <Modal
      centered
      open={popupProps.showPopup}
      onOk={handleOk}
      onCancel={handleCancel}
      width={700}
      title={popupProps.popupHeading}
      footer={[
        <div className="buttonBg floatRight cursor margin65" onClick={handleOk}>
          <img src={send} alt="send" className="buttonWidthSend" />
          <span className="buttonTextSend">Send</span>
        </div>,
      ]}
      className="EmailHeaderfix widthOfPopup"
    >
      <div className="Email-container">
        <div className="passwordFieldsBg">
          <Input bordered={false} className="passwordFields" placeholder="To" />
        </div>

        <div className="passwordFieldsBg">
          <Input bordered={false} className="passwordFields" placeholder="CC" />
        </div>

        <div className="passwordFieldsBg">
          <Input
            bordered={false}
            className="passwordFields"
            placeholder="Subject"
          />
        </div>

        <div className="passwordFieldsBg DescDiv">
          <TextArea
            bordered={false}
            className="detaistextarea"
            rows={4}
            placeholder="Description"
            style={{ resize: "none" }}
          />
        </div>
        <div className="checkbox_email floatLeft DescDiv">
          <Checkbox className="checkEmail">{popupProps.checkboxText}</Checkbox>
        </div>
      </div>
    </Modal>
  );
};

export default EmailApplicant;
