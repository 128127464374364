import React, { useContext, useEffect, useState } from "react";
import {
  Layout,
  Space,
  Button,
  Row,
  Col,
  Input,
  Select,
  Form,
  notification,
} from "antd";

import "./Applications.scss";
import TabView from "../Tabs/Tabs";
import ApplicationHeader from "../Header/Header";
import PrivacyPolicy from "../PrivayPolicy/PrivacyPolicy";
import FixedFooter from "../Footer/Footer";


const { Header, Footer, Sider, Content } = Layout;
const size = "large";

const ClientPortal = () => {
  return (
    <Layout className="Applications-layout">
      <ApplicationHeader></ApplicationHeader>
      <TabView></TabView>

    </Layout>
  );
};

export default ClientPortal;
