import React, { useContext, useEffect, useState } from "react";
import {
  Layout,
  Space,
  Button,
  Row,
  Col,
  Input,
  Select,
  Form,
  Image,
} from "antd";

import docImage from "../../Assets/Images/attachEvidenceSvg.svg";
import deleleIcon from "../../Assets/Images/deleteiconSvg.svg";
import edit from "../../Assets/Images/wrongEditSvg.svg";

import { RightOutlined } from "@ant-design/icons";
import "./ApplicationsEvidenceTab.scss";

const { Header, Footer, Sider, Content } = Layout;
const size = "large";

const ApplicationsEvidenceTab = () => {
  return (
    <div className="applnevidencetabdetails marginTop20 marginTotalNotes">
      <h3 className="marginBottom20 notesHeading">Notes</h3>
      <div className="attachSection">
        <h3 className="attachHeading alignAttach">Attachments</h3>
        <div>
          <p className="notesAdded paddingLeft">
            Added by:{" "}
            <span className="emailNotes">john.smith@loquatinc.io</span>
          </p>
          <p>
            <span className="weekpara onlyEvidence">09/04 12.30PM</span>
          </p>
        </div>

        <div className="eachAttach">
          <div className="outlookAttach">
            <span className="onlyImage">
              <Image
                src={docImage}
                alt="doc"
                className="docImage"
                preview={false}
              />
            </span>
            <span className="alignDocImage underline">Business License</span>
          </div>
          <div>
            <img src={edit} alt="search" className="search-logoimage cursor" />
            <img
              src={deleleIcon}
              alt="deleteicon"
              className="articleimg-logo cursor deleteEvidence"
            />
          </div>
        </div>
      </div>
      <div className="notesSection">
        <Row className="marginBottom20">
          <Col
            xs={24}
            sm={24}
            md={14}
            lg={16}
            className="evidencetab-container"
          >
            <p className="notesAdded">
              Added by:{" "}
              <span className="emailNotes">john.smith@loquatinc.io</span>
            </p>

            <p className="notesDetails">
              Last name does not match public records, contacting applicant to
              address the issue.
            </p>
          </Col>
          <Col xs={24} sm={24} md={6} lg={5}>
            <p>
              <span className="weekpara">09/04 12.30PM</span>
            </p>
            <div className="editIcons">
              <img
                src={edit}
                alt="search"
                className="search-logoimage cursor"
              />
              <img
                src={deleleIcon}
                alt="deleteicon"
                className="articleimg-logo cursor deleteEvidence"
              />
            </div>
          </Col>
        </Row>
        <hr></hr>
        <Row className="marginBottom20">
          <Col
            xs={24}
            sm={24}
            md={14}
            lg={16}
            className="evidencetab-container"
          >
            <p className="notesAdded">
              Added by:{" "}
              <span className="emailNotes">john.smith@loquatinc.io</span>
            </p>
            <p className="notesDetails">
              Applicant failed to upload Business License, uploading manually.
            </p>
          </Col>
          <Col xs={24} sm={24} md={6} lg={5}>
            <p>
              <span className="weekpara">09/02 10.30PM</span>
            </p>
            <div className="editIcons">
              <img
                src={edit}
                alt="search"
                className="search-logoimage cursor"
              />
              <img
                src={deleleIcon}
                alt="deleteicon"
                className="articleimg-logo cursor deleteEvidence"
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ApplicationsEvidenceTab;
