import React, { useContext, useEffect, useState } from "react";
import {
  Layout,
  Space,
  Button,
  Row,
  Col,
  Input,
  Select,
  Form,
  notification,
  Tooltip,
  Dropdown,
} from "antd";
import {
  AppstoreOutlined,
  DownOutlined,
  MailOutlined,
  SettingOutlined,
  UpOutlined,
} from "@ant-design/icons";

import type { MenuProps } from "antd";
import { Menu } from "antd";
import search from "../../Assets/Images/searchSvg.svg";

import "./FixedHeader.scss";
import CreateCase from "../CreateCase/CreateCase";
import EmailApplicant from "../EmailApplicant/EmailApplicant";
import AddApplicationsToExisting from "../AddApplicationsExistingCase/AddApplicationsExistingCase";
import AddaNotePopup from "../AddAnotePopup/AddAnotePopup";
import AddAttachementPopup from "../AddAttachementPopup/AddAttachementPopup";
import ic_arrow_up from "../../Assets/Images/icons/ic_arrow_up.svg";
import ic_arrow_down from "../../Assets/Images/icons/ic_arrow_down.svg";
const { Header, Footer, Sider, Content } = Layout;
const size = "large";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}
const items: MenuProps["items"] = [
  getItem("Take action", "sub2", <AppstoreOutlined />, [
    getItem("Edit application", "1"),
    getItem("Assign application", "sub3", null, [
      getItem(
        <div className="search-box">
          <img src={search} alt="search" className="search-image cursor" />
          <Input className="searchBox" placeholder="Search" />
        </div>,
        "66"
      ),
      getItem(
        <Tooltip title="john.smith@loquatinc.io">
          <span>john.smith@loquatinc.io</span>
        </Tooltip>,
        "7"
      ),
      getItem(
        <Tooltip title="bob.smith@loquatinc.io">
          <span>bob.smith@loquatinc.io</span>
        </Tooltip>,
        "8"
      ),
      getItem(
        <Tooltip title="james.smith@loquatinc.io">
          <span>james.smith@loquatinc.io</span>
        </Tooltip>,
        "10"
      ),
    ]),
    getItem("Create case", "77"),
    getItem("Add to existing case", "2"),
    getItem("Add attachment", "6"),
    getItem("Add note", "80"),
    getItem("Email applicant", "3"),
    getItem("Export to PDF", "5"),
  ]),
];

const FixedHeader = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [showEmailPopup, setShowEmailPopup] = useState(false);
  const [showAddToPopup, setShowAddToPopup] = useState(false);
  const [showNotePopup, setShowNotePopup] = useState(false);
  const [showAddAttchPopup, setShowAddAttchPopup] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handlePopup = () => {
    setShowPopup(true);
  };

  const onClick: MenuProps["onClick"] = (e) => {
    if (e.key == "77") {
      handlePopup();
    } else if (e.key == "3") {
      setShowEmailPopup(true);
    } else if (e.key == "2") {
      setShowAddToPopup(true);
    } else if (e.key == "80") {
      setShowNotePopup(true);
    } else if (e.key == "6") {
      setShowAddAttchPopup(true);
    }
  };

  const assignAppSubMenu = (
    <Menu className="dropdown-submenu">
      <Menu.Item key="66">
        <div className="search-box">
          <img src={search} alt="search" className="search-image cursor" />
          <Input className="searchBox" placeholder="Search" />
        </div>
      </Menu.Item>
      <Menu.Item key="7">
        <Tooltip title="john.smith@loquatinc.io">
          <span>john.smith@loquatinc.io</span>
        </Tooltip>
      </Menu.Item>
      <Menu.Item key="8">
        <Tooltip title="bob.smith@loquatinc.io">
          <span>bob.smith@loquatinc.io</span>
        </Tooltip>
      </Menu.Item>
      <Menu.Item key="10">
        <Tooltip title="james.smith@loquatinc.io">
          <span>james.smith@loquatinc.io</span>
        </Tooltip>
      </Menu.Item>
    </Menu>
  );

  const takeActionmenu = (
    <Menu onClick={onClick} className="dropdown-options">
      <Menu.Item disabled key="1">
        Edit application
      </Menu.Item>
      {/* <Menu.Item key="">Assign application</Menu.Item> */}
      <Menu.SubMenu disabled key="sub3" title="Assign application">
        {assignAppSubMenu}
      </Menu.SubMenu>
      <Menu.Item disabled key="77">
        Create case
      </Menu.Item>
      <Menu.Item disabled key="2">
        Add to existing case
      </Menu.Item>
      <Menu.Item disabled key="6">
        Add attachment
      </Menu.Item>
      <Menu.Item disabled key="80">
        Add note
      </Menu.Item>
      <Menu.Item disabled key="3">
        Email applicant
      </Menu.Item>
      <Menu.Item disabled key="5">
        Export to PDF
      </Menu.Item>
    </Menu>
  );

  const handleDropdownVisibleChange = (visible: boolean) => {
    setIsDropdownOpen(visible);
  };

  return (
    <div className="fixed_portal_sec">
      <div className="header_fixed">
        <div className="fheader_images floatLeft">
          <span className="appnumber">Application SMB-123456788</span>
        </div>
        <div
          className="menuSections floatRight"
          style={{ display: "flex", alignItems: "center" }}
        >
          <div className="paymore floatLeft">
            {/* <Menu
              onClick={onClick}
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["sub1"]}
              mode="inline"
              items={items}
              key={1}
            /> */}
            <Dropdown
              overlay={takeActionmenu}
              trigger={["click"]}
              // visible={isDropdownOpen}
              className="dropdown-takeaction"
              onOpenChange={handleDropdownVisibleChange}
            >
              <Button className="dropdown-btn">
                Take action{" "}
                <img
                  src={isDropdownOpen ? ic_arrow_up : ic_arrow_down}
                  alt="icon arrow"
                />
              </Button>
            </Dropdown>
          </div>
          <div className="statusprogress floatRight">
            <span className="statusheading">Status</span>
            <div className="newprogrss floatRight">
              <span className="progresstext">IN PROGRESS</span>
            </div>
          </div>
        </div>
      </div>
      <CreateCase showPopup={showPopup} setShowPopup={setShowPopup} />
      <EmailApplicant
        showPopup={showEmailPopup}
        popupHeading="Email Applicant"
        checkboxText="Include application"
        setShowPopup={setShowEmailPopup}
      ></EmailApplicant>
      <AddApplicationsToExisting
        showPopup={showAddToPopup}
        setShowPopup={setShowAddToPopup}
      ></AddApplicationsToExisting>
      <AddaNotePopup
        showPopup={showNotePopup}
        setShowPopup={setShowNotePopup}
      ></AddaNotePopup>
      <AddAttachementPopup
        showPopup={showAddAttchPopup}
        setShowPopup={setShowAddAttchPopup}
      ></AddAttachementPopup>
    </div>
  );
};

export default FixedHeader;
