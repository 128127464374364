import React, { useContext, useEffect, useState } from "react";
import RingLoader from "react-spinners/RingLoader";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { IApprouteProps } from "../Contracts/Contracts";

import Login from "../Components/Login/Login";
import ClientPortal from "../Components/ApplicationList/Applications";
import NotFoundError from "../Components/ErrorPages/NotFoundError";
import ApplicationOpened from "../Components/ApplicationOpened/ApplicationsOpened";
import { ApplicationContext } from "../Context/ApplicationContextProvider";
import InvestigateAppln from "../Components/ApplnInvestigate/ApplnInvestigate";
import CaseOpened from "../Components/CaseOpened/CaseOpened";
import ReportsDashboardWithTabs from "../Components/ReportsDashboardWithTabs/ReportsDashboardWithTabs";
import ReportsSARsWithTabs from "../Components/ReportsSARsWithTabs/ReportsSARsWithTabs";

const AppRoute = (props: IApprouteProps) => {
  const { online } = props;
  const { setAuth, auth, getLoquatClient } = useContext(ApplicationContext);

  const defaultRoutes = [
    {
      path: "/",
      breadcrumbName: "Home",
    },
  ];

  const [routes, setRoutes] = useState(defaultRoutes);
  const [locationPermission, setLocationPermission] = useState("default");
  const history = useNavigate();

  useEffect(() => {
    if (online) {
      setAuth({ ls: false, loadingUser: false });
    }
  }, [online]);

  return (
    <div>
      {auth.loadingUser ? (
        <RingLoader
          loading={true}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        <>
          {auth.ls ? (
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="*" element={<NotFoundError />} />
            </Routes>
          ) : (
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/applications" element={<ClientPortal />} />
              <Route path="/openappln" element={<ApplicationOpened />} />
              {/* <Route path="/investigateappln" element={<InvestigateAppln />} /> */}
              {/* <Route path="/opencase" element={<CaseOpened />} /> */}
              <Route
                path="/opendashboard"
                element={<ReportsDashboardWithTabs />}
              />
              {/* <Route path="/opensars" element={<ReportsSARsWithTabs />} /> */}
              <Route path="*" element={<Navigate to="/applications" />} />
            </Routes>
          )}
        </>
      )}
    </div>
  );
};

export default AppRoute;
