import moment from "moment";
import { RuleObject } from "antd/lib/form";

export const getFormattedDate = (
  dateString: string,
  format?: string | null
) => {
  format = format || "MMM, DD YYYY";
  return moment(dateString).locale("en_us").format(format);
};
export const setDateFormat = (date?: string) => {
  let setDate = date ? date : moment();
  // console.log(setDate,"date")
  // console.log(moment.parseZone(setDate).utc().format(),"return value")
  return moment.parseZone(setDate).utc().format();
};
export const getNonUTCFormat = (date?: string, format?: string) => {
  if (format) {
    let setDate = getFormattedDateForNonUTC(date, format);
    return setDate;
  } else {
    let setDate = date ? moment(date) : moment();
    return setDate;
  }
};
const getFormattedDateForNonUTC = (date?: string, format?: string) => {
  let formatDate = date ? moment(date).format(format) : moment().format(format);
  return formatDate;
};
export const setTimeFormat = (time?: string) => {
  return time ? moment(time).locale("en_us").format("h:mm a") : "";
};

export const getTimeFormat = (time?: string) => {
  return time ? moment(time, "hh:mm a") : moment("00:00:00", "h:mm a");
};

export const validateEmail = (rule: RuleObject, value: string) => {
  return new Promise<void>((resolve, reject) => {
    if (!value) {
      reject("Email is required");
    } else if (!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(value)) {
      reject("Invalid email address");
    } else {
      resolve();
    }
  });
};

export const formatPhoneNumber = (input: string) => {
  const cleaned = input.replace(/\D/g, "");
  const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
  if (match) {
    return `${match[1] ? `(${match[1]})` : ""} ${match[2] ? match[2] : ""}${
      match[2] && match[3] ? "-" : ""
    }${match[3] ? match[3] : ""}`;
  }
  return input;
};

export const validatePhoneNumber = async (
  _: unknown,
  value: string
): Promise<void> => {
  if (value === "") {
    return Promise.reject("Phone is required");
  }

  const cleaned = value.replace(/\D/g, "");
  if (!cleaned.match(/^\d{10}$/)) {
    return Promise.reject("Please enter a valid 10-digit phone number.");
  }
};

export function formatDate(dateString: string | number | Date) {
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  const formattedDate = new Date(dateString).toLocaleDateString(
    "en-US",
    options as Intl.DateTimeFormatOptions
  );
  return formattedDate;
}

export const getValueFromStorage = (key: string) => {
  return sessionStorage.getItem(key);
};

export const setValueInStorage = (key: string, value: string) => {
  sessionStorage.setItem(key, value);
};

export const getValueFromLocalStorage = (key: string) => {
  return localStorage.getItem(key);
};

export const setValueInLocalStorage = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

export const removeValueFromLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};

export function isWeekend(date:any) {
  const day = date.day();
  return day === 0 || day === 6;
}
