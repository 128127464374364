import { Col, Radio, Row, Input, Form } from "antd";
import identificationFront from "../../../Assets/Images/identificationFront.png";
import identificationBack from "../../../Assets/Images/identificationBack.png";
import selfiewPreview from "../../../Assets/Images/selfiePreview.png";
import rightsymbol from "../../../Assets/Images/rightSymbolSvg.svg";
import wrongsymbol from "../../../Assets/Images/wrongSvg.svg";

const InformationExtractedTop = {
  "City Name": "Miami",
  "State Name": "FL",
  "Country Name": "United States",
  "Device Type": "iPhone (iOS)",
}

const InformationExtractedMiddle = {
  "First Name": "John",
  "Last Name": "Smith",
  "Document": "Drivers License",
  "Document Expiration": "05/14/2028",
  "Status": "Not tampered",
  "Document Quality": "Good",
  "Address 1": "620 Florida Ave NE, Miami, FL, 33156",
  "Address 2": "-",
  "Date of Birth": "01/14/1985",
  "Document Number": "D83772430",
  "Expiration Date": "05/14/2028",
  "ID Country": "+1 (001)",
  "Document Type": "Drivers License",
  "Document Number #2": "-",
  "Place of Birth": "Florida",
  "Valid From Date": "07/13/2020",
  "Nationality": "US",
  "Issuing State": "Florida",
  "Issuing Authority or Organization": "Florida",
  "Place of Issue": "Florida",
  "Date of Issue": "07/13/2020",
}

const InformationExtractedBottom = {
  "Liveness": "Verified",
}


const ResponsibleIndividuals = (
  <div className="ownerSection">
    {" "}
    <Row className="main_client_filter">
      <Col xs={24} sm={24} md={18} lg={10} className="">
        <span className="headingTexts">User Application</span>
      </Col>
      <Col
        xs={24}
        sm={24}
        md={8}
        lg={8}
        className="extraxtLeft onlyLarge"
      >
        <span className="headingTexts">Information Extracted</span>
      </Col>
    </Row>

    <Row className="main_client_filter">
      <Col
        xs={24}
        sm={24}
        md={18}
        lg={10}
        className="main_filter-container"
      >
        <div className="doc_info_borderText">
          <span className="phonenumer">Phone</span>
          <Input
            className="doc_inputTextClass"
            bordered={false}
            placeholder="(000) 000-0000"
          />
        </div>
        <div className="idendoc">
          <h3>Identification document</h3>
          <img
            src={identificationFront}
            alt="licensefront"
            className="licensefront-logo"
          />
          <img
            src={identificationBack}
            alt="licenseback"
            className="licensefront-logo"
          />
          <h3>Selfie</h3>
          <img src={selfiewPreview} alt="selfie" className="selfie-logo" />
        </div>
      </Col>
      <Col
        xs={24}
        sm={24}
        md={18}
        lg={8}
        className="second_filter-container"
      >
        <div className="info-extracted-list top">
          <Row gutter={[24, 8]}>
            {Object.entries(InformationExtractedTop).map(([key, value]) =>
              <>
                <Col span={12} className="info-extracted-key">{key}</Col>
                <Col span={12} className="info-extracted-value">{value}</Col>
              </>
            )}
          </Row>
        </div>
        <div className="info-extracted-list middle">
          <Row gutter={[24, 8]}>
            {Object.entries(InformationExtractedMiddle).map(([key, value]) =>
              <>
                <Col span={12} className="info-extracted-key">{key}</Col>
                <Col span={12} className="info-extracted-value">{value}</Col>
              </>
            )}
          </Row>
        </div>
        <div className="info-extracted-list bottom">
          <Row gutter={[24, 8]}>
            {Object.entries(InformationExtractedBottom).map(([key, value]) =>
              <>
                <Col span={12} className="info-extracted-key">{key}</Col>
                <Col span={12} className="info-extracted-value">{value}</Col>
              </>
            )}
          </Row>
        </div>
      </Col>
      <Col
        xs={24}
        sm={24}
        md={18}
        lg={4}
        className="third_filter-container"
      >
        <div className="rightdetails geolocation-verified">
          <span className="geolocationimg">
            <img
              src={rightsymbol}
              alt="rightsymbol"
              className="rightheader-logo"
            />
          </span>
          <span className="geolocation">Geolocation verified</span>
        </div>


        <div className="rightdetails">
          <span className="geolocationimg">
            <img
              src={rightsymbol}
              alt="rightsymbol"
              className="rightheader-logo"
            />
          </span>
          <span className="geolocation">ID authenticated</span>
        </div>
        <div className="rightdetails">
          <span className="geolocationimg">
            <img
              src={rightsymbol}
              alt="rightsymbol"
              className="rightheader-logo"
            />
          </span>
          <span className="geolocation">Images processed & quality checked</span>
        </div>
        <div className="rightdetails">
          <span className="geolocationWrongimg">
            <img
              src={wrongsymbol}
              alt="wrongsymbol"
              className="rightheader-logo"
            />
          </span>
          <span className="geolocation">
            Public records
          </span>
        </div>
        <div className="rightdetails">
          <span className="geolocationimg">
            <img
              src={rightsymbol}
              alt="rightsymbol"
              className="rightheader-logo"
            />
          </span>
          <span className="geolocation">Document type match</span>
        </div>
        <div className="rightdetails">
          <span className="geolocationimg">
            <img
              src={rightsymbol}
              alt="rightsymbol"
              className="rightheader-logo"
            />
          </span>
          <span className="geolocation">Face match</span>
        </div>
        <div className="rightdetails">
          <span className="geolocationimg">
            <img
              src={rightsymbol}
              alt="rightsymbol"
              className="rightheader-logo"
            />
          </span>
          <span className="geolocation">Date of birth matched</span>
        </div>
        <div className="rightdetails">
          <span className="geolocationimg">
            <img
              src={rightsymbol}
              alt="rightsymbol"
              className="rightheader-logo"
            />
          </span>
          <span className="geolocation">Address match</span>
        </div>
        <div className="rightdetails">
          <span className="geolocationimg">
            <img
              src={rightsymbol}
              alt="rightsymbol"
              className="rightheader-logo"
            />
          </span>
          <span className="geolocation">Document country match</span>
        </div>


        <div className="rightdetails live-human2">
          <span className="geolocationimg">
            <img
              src={rightsymbol}
              alt="rightsymbol"
              className="rightheader-logo"
            />
          </span>
          <span className="geolocation">Live human</span>
        </div>
      </Col>
    </Row>
    <Row className="main_client_filter secondrowdetails">
      <Col
        span={20}
        className="row_filter-container"
      >
        <div className="idendoc">
          <h3>Personal information</h3>
          <div className="personal-information-container">
            <Form className="personalinfo-form">
              <Row>
                <Col span={8}>
                  <Form.Item name="firstname">
                    <div className={`info_borderText application-inputBox`}>
                      <span className="info_span">First Name</span>
                      <Input
                        className="info_inputTextClass"
                        placeholder="First Name"
                        bordered={false}
                        value={"John"}
                        required
                      />
                    </div>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="middlename" >
                    <div className={`info_borderText application-inputBox`}>
                      <span className="info_span placeholder">Middle Name</span>
                      <Input
                        className="info_inputTextClass"
                        placeholder="-"
                        bordered={false}
                        value={"-"}
                        required
                      />
                    </div>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="lastname" >
                    <div className={`info_borderText application-inputBox`}>
                      <span className="info_span placeholder">Last Name</span>
                      <Input
                        className="info_inputTextClass"
                        placeholder="-"
                        bordered={false}
                        value={"Smith"}
                        required
                      />
                    </div>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={8}>
                  <Form.Item name="dateofbirth">
                    <div className={`info_borderText application-inputBox `}>
                      <span className="info_span placeholder">Date Of Birth</span>
                      <Input
                        className="info_inputTextClass"
                        placeholder="Date Of Birth"
                        bordered={false}
                        value={"01/14/1985"}
                        required
                      />
                    </div>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="ssn" >
                    <div className={`info_borderText application-inputBox`}>
                      <span className="info_span placeholder">SSN</span>
                      <Input
                        className="info_inputTextClass"
                        placeholder="SSN"
                        bordered={false}
                        value={"XXX-XX-1378"}
                        required
                      />
                    </div>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="email" >
                    <div className={`info_borderText application-inputBox`}>
                      <span className="info_span placeholder">Email</span>
                      <Input
                        className="info_inputTextClass"
                        placeholder="Email"
                        bordered={false}
                        value={"josmith@gmail.com"}
                        required
                      />
                    </div>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={12}>
                  <Form.Item name="city">
                    <div className={`info_borderText application-inputBox `}>
                      <span className="info_span placeholder">Street Address 1</span>
                      <Input
                        className="info_inputTextClass"
                        placeholder="streetaddress1"
                        bordered={false}
                        value={"620 Florida Ave NE"}
                        required
                      />
                    </div>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="streetaddress2" >
                    <div className={`info_borderText application-inputBox`}>
                      <span className="info_span placeholder">Street Address 2</span>
                      <Input
                        className="info_inputTextClass"
                        placeholder="-"
                        bordered={false}
                        value={""}
                        required
                      />
                    </div>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={14}>
                  <Form.Item name="city">
                    <div className={`info_borderText application-inputBox `}>
                      <span className="info_span placeholder">City</span>
                      <Input
                        className="info_inputTextClass"
                        placeholder="City"
                        bordered={false}
                        value={"Miami"}
                        required
                      />
                    </div>
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item name="state" >
                    <div className={`info_borderText application-inputBox`}>
                      <span className="info_span placeholder">State</span>
                      <Input
                        className="info_inputTextClass"
                        placeholder="State"
                        bordered={false}
                        value={"FL"}
                        required
                      />
                    </div>
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item name="zip" >
                    <div className={`info_borderText application-inputBox`}>
                      <span className="info_span placeholder">ZIP</span>
                      <Input
                        className="info_inputTextClass"
                        placeholder="ZIP"
                        bordered={false}
                        value={"33156"}
                        required
                      />
                    </div>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={12}>
                  <Form.Item name="employer">
                    <div className={`info_borderText application-inputBox `}>
                      <span className="info_span placeholder">Employer</span>
                      <Input
                        className="info_inputTextClass"
                        placeholder="Employer"
                        bordered={false}
                        value={"Adam’s Coffee House LLC"}
                        required
                      />
                    </div>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="jobtitle" >
                    <div className={`info_borderText application-inputBox`}>
                      <span className="info_span placeholder">Job title / Position with the company you are applying for</span>
                      <Input
                        className="info_inputTextClass"
                        placeholder="Job title"
                        bordered={false}
                        value={"Barista"}
                        required
                      />
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </Col>
      <Col
        xs={24}
        sm={24}
        md={18}
        lg={4}
        className="third_filter-container"
      >
        <div className="rightdetails personal-information-rightinfo">
          <span className="geolocationimg">
            <img
              src={rightsymbol}
              alt="rightsymbol"
              className="rightheader-logo"
            />
          </span>
          <span className="geolocation">Provided information is verified</span>
        </div>
        <div className="rightdetails">
          <span className="geolocationimg">
            <img
              src={rightsymbol}
              alt="rightsymbol"
              className="rightheader-logo"
            />
          </span>
          <span className="geolocation">Associated person match</span>
        </div>
        <div className="rightdetails">
          <span className="geolocationimg">
            <img
              src={rightsymbol}
              alt="rightsymbol"
              className="rightheader-logo"
            />
          </span>
          <span className="geolocation">Name match</span>
        </div>
        <div className="rightdetails">
          <span className="geolocationWrongimg">
            <img
              src={wrongsymbol}
              alt="wrongsymbol"
              className="rightheader-logo"
            />
          </span>
          <span className="geolocation">SSN not matched</span>
        </div>
        <div className="rightdetails">
          <span className="geolocationimg">
            <img
              src={rightsymbol}
              alt="rightsymbol"
              className="rightheader-logo"
            />
          </span>
          <span className="geolocation">City and state match</span>
        </div>
        <div className="rightdetails">
          <span className="geolocationimg">
            <img
              src={rightsymbol}
              alt="rightsymbol"
              className="rightheader-logo"
            />
          </span>
          <span className="geolocation">
            Text about address
          </span>
        </div>
        <div className="rightdetails">
          <span className="geolocationimg">
            <img
              src={rightsymbol}
              alt="rightsymbol"
              className="rightheader-logo"
            />
          </span>
          <span className="geolocation">
            EIN and name information available
          </span>
        </div>
      </Col>
    </Row>
  </div>
);

export default ResponsibleIndividuals;
