import React, { useState, useEffect } from "react";
import "./ReportsDashboard.scss";
import { Layout, Tabs, Row, Col, Image, Input } from "antd";
import PrivacyPolicy from "../PrivayPolicy/PrivacyPolicy";
import { getValueFromStorage } from "../Utility/Utility";
import { useNavigate } from "react-router-dom";

const { Search } = Input;

const ReportsDashboard = () => {
  const navigate = useNavigate();
  const openApplicationsLength = getValueFromStorage("openApplicationsLength");
  const openSARsLength = getValueFromStorage("openSARsLength");
  const openCasesLength = getValueFromStorage("openCasesLength");
  const [showPopup, setShowPopup] = useState(false);
  const openPopup = () => {
    setShowPopup(true);
  };

  useEffect(() => {
    document
      .getElementById("suppportIcon")!
      .addEventListener("click", function () {
        openPopup();
      });
  }, []);

  return (
    <div className="DashboardContent">
      <span className="dashboardHeading">Dashboard</span>
      <div className="boxesSection">
        <Row>
          <Col className="FirstCol">
            <div
              className="firstBox block-item cursor minHieghtBox"
              // onClick={() => navigate("/applications")}
            >
              <span className="firstBlock">
                {openApplicationsLength || 429}
              </span>
              <span className="openText">My Open Applications</span>
              <span className="daysTotal">30 days</span>
            </div>
          </Col>
          <Col className="SecondCol">
            <div
              className="secondBox block-item cursor minHieghtBox"
              // onClick={() => navigate("/applications?from=cases")}
            >
              <span className="secondBlock">{openCasesLength || 201}</span>
              <span className="openText">My Open Cases</span>
              <span className="daysTotal">30 days</span>
            </div>
          </Col>
          <Col className="ThirdCol">
            <div
              className="thirdBox block-item cursor minHieghtBox"
              // onClick={() => navigate("/opensars")}
            >
              <span className="thirdBlock">{openSARsLength || 56}</span>
              <span className="openText">My Open SARs</span>
              <span className="daysTotal">30 days</span>
            </div>
          </Col>
        </Row>
      </div>
      <div className="boxesSection">
        <Row>
          <Col className="FirstColRow2">
            <div
              className="firstBox block-item cursor minHieghtBox"
              // onClick={() => navigate("/applications")}
            >
              <span className="firstBlock">
                {openApplicationsLength || "4 654 652"}
              </span>
              <span className="openText">All Open Applications</span>
              <span className="daysTotal">30 days</span>
            </div>
          </Col>
          <Col className="SecondColRow2">
            <div
              className="secondBox block-item cursor minHieghtBox"
              // onClick={() => navigate("/applications?from=cases")}
            >
              <span className="secondBlock">
                {openCasesLength || "260 485"}
              </span>
              <span className="openText">All Open Cases</span>
              <span className="daysTotal">30 days</span>
            </div>
          </Col>
          <Col className="ThirdColRow2">
            <div
              className="thirdBox block-item cursor minHieghtBox"
              // onClick={() => navigate("/opensars")}
            >
              <span className="thirdBlock">{openSARsLength || "58 501"}</span>
              <span className="openText">All Open SARs</span>
              <span className="daysTotal">30 days</span>
            </div>
          </Col>
        </Row>
      </div>
      <div className="reduceMarginFooter"></div>
    </div>
  );
};
export default ReportsDashboard;
