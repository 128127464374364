import React, { useContext, useEffect, useState } from "react";
import {
  Layout,
  Space,
  Button,
  Row,
  Col,
  Input,
  Select,
  Form,
  notification,
} from "antd";

import excelIcon from "../../Assets/Images/exporticonSvg.svg";
import clock from "../../Assets/Images/clockSvg.svg";

import { RightOutlined } from "@ant-design/icons";
import "./ApplicationsHistoryTab.scss";

const { Header, Footer, Sider, Content } = Layout;
const size = "large";

const ApplicationsHistoryTab = () => {
  return (
    <div className="historytabdetails history_client_filter">
      <Row>
        <Col xs={24} sm={24} md={11} lg={13} className="historytab-container">
          <p>Bob Smith submitted Application SMB-123456788.</p>
        </Col>
        <Col xs={24} sm={24} md={7} lg={7} className="historytab-week">
          <p>
            <span className="weekago">
              {" "}
              <img className="clockimg" src={clock} alt="clock" />
            </span>
            <span className="weekpara">2 weeks ago</span>
          </p>
        </Col>
        <Col xs={24} sm={24} md={6} lg={4} className="historytab-export cursor">
          <p>
            <span className="exporttime">Export to CSV / XLS</span>
            <span className="exportimg">
              <img className="excelIconimg" src={excelIcon} alt="excelIcon" />
            </span>
          </p>
        </Col>
      </Row>

      <Row>
        <Col xs={24} sm={24} md={11} lg={13} className="historytab-container">
          <p>Application assigned to John Smith.</p>
        </Col>
        <Col xs={24} sm={24} md={7} lg={7} className="historytab-week">
          <p>
            <span className="weekago">
              <img className="clockimg" src={clock} alt="clock" />
            </span>
            <span className="weekpara">2 weeks ago</span>
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default ApplicationsHistoryTab;
