import React, { useContext, useEffect, useState } from "react";
import { ApplicationContext } from "../../Context/ApplicationContextProvider";
import {
  Layout,
  Space,
  Button,
  Row,
  Col,
  Input,
  Table,
  Form,
  Collapse,
  CollapseProps,
  theme,
  DatePicker,
  Modal,
  Checkbox,
  Dropdown,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import ascend from "../../Assets/Images/ascendSvg.svg";
import dayjs from "dayjs";

import "./AddApplicationsExistingCase.scss";
import exportIcon from "../../Assets/Images/exporticonSvg.svg";
import ClientFilter from "../../Components/ClientsFilter/ClientFilters";
import progress from "../../Assets/Images/Inprogress.png";
import search from "../../Assets/Images/searchSvg.svg";
import filtericon from "../../Assets/Images/filtericonSvg.svg";
import filtercross from "../../Assets/Images/filterCrossSvg.svg";
import addTocase from "../../Assets/Images/addToCaseBg.png";

import rightVector from "../../Assets/Images/rightvectorSvg.svg";
import leftVector from "../../Assets/Images/leftvectorSvg.svg";
import Casefilters from "../CaseFilter/CaseFilter";
import CasesDataJson from "../../Components/CasesTable/CaseTableData.json";

import { DownOutlined, UpOutlined } from "@ant-design/icons";

const { Header, Footer, Sider, Content } = Layout;
interface DataType {
  key: React.Key;
  ID: string;
  Cdate: any;
  Udate: any;
  Cat: string;
  Creat: string;
  DueDate: string;
  Priority: string;
  Status: string;
}
const { RangePicker } = DatePicker;
const dateFormat = "MM/DD/YYYY";
// const data: DataType[] = [
//   {
//     key: "1",
//     ID: "AML-123456789",
//     Cdate: "8/15/2023 7:37 PM",
//     Udate: "08/15/2023 7:37 PM",
//     Cat: "AML",
//     Creat: "John Smith",
//     Due: "11/15/2023 00:00 PM",
//     Priority: "high",
//     Status: "UNDECIDED",
//   },
//   {
//     key: "2",
//     ID: "FRD-123456789",
//     Cdate: "8/15/2023 7:37 PM",
//     Udate: "08/15/2023 7:37 PM",
//     Cat: "AML",
//     Creat: "James Williams",
//     Due: "11/15/2023 00:00 PM",
//     Priority: "Medium",
//     Status: "UNDECIDED",
//   },
// ];

// rowSelection object indicates the need for row selection
const rowSelection = {
  onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
};

type FinalPopupProps = {
  showPopup: boolean;
  setShowPopup: (value: boolean) => void;
};
const AddApplicationsToExisting = (popupProps: FinalPopupProps) => {
  const data = CasesDataJson.createTableList;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [codeNumber, setCodeNumber] = useState(false);
  const [selectionType, setSelectionType] = useState<"checkbox" | "radio">(
    "checkbox"
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const navigate = useNavigate();

  const handleOk = () => {
    popupProps.setShowPopup(false);
  };

  const handleCancel = () => {
    popupProps.setShowPopup(false);
  };
  const openFilter = () => {
    setCodeNumber(!codeNumber);
  };
  const handleGoBack = () => {
    navigate("/opencase");
  };
  const columns: ColumnsType<DataType> = [
    {
      title: "Case ID",
      dataIndex: "ID",
      // render: (text: string) => (
      //   <a className="firstColumn underline" onClick={handleGoBack}>
      //     {text}
      //   </a>
      // ),
      render: (text: string, row) => {
        return (
          <div style={{ justifyContent: "spaceBetween" }}>
            {row.ID === "AML-123456789" ? (
              <a className="firstColumn underline" onClick={handleGoBack}>
                {text}
              </a>
            ) : (
              <span className="">{text}</span>
            )}
          </div>
        );
      },
    },
    {
      title: "Created",
      dataIndex: "Cdate",
    },
    {
      dataIndex: "Udate",
      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "name"
        );

        return (
          <div style={{ justifyContent: "spaceBetween" }}>
            Last Updated
            <img
              src={ascend}
              alt="addcase"
              className="ascendImgPopup cursor"
              style={{ width: 6, height: 10 }}
            />
          </div>
        );
      },
      defaultSortOrder: "ascend",
    },
    {
      title: "Category",
      dataIndex: "Cat",
    },
    {
      title: "Creator",
      dataIndex: "Creat",
    },
    {
      title: "Due Date",
      dataIndex: "DueDate",
    },
    {
      title: "Priority",
      dataIndex: "Prior",
      render: (text: string, row) => (
        <div style={{ justifyContent: "spaceBetween" }}>
          {row.Priority == "High" ? (
            <div className="highBg">
              <span className="highText">HIGH</span>
            </div>
          ) : row.Priority == "Medium" ? (
            <div className="mediumBg">
              <span className="mediumText">MEDIUM</span>
            </div>
          ) : (
            <div className="newbg">
              <span className="newtext">LOW</span>
            </div>
          )}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "Status",
      render: (text: string) => (
        // <img src={progress} alt={"404-Notfound"} className="progressImg" />
        <div className="newprogrss">
          <span className="progresstext">IN PROGRESS</span>
        </div>
      ),
    },
  ];

  const handleDropdownVisibleChange = (visible: boolean) => {
    setIsDropdownOpen(visible);
  };

  const takeActionmenu = (
    <Casefilters
      isCreateCase={true}
      setCaseNumber={setCodeNumber}
      setIsDropdownOpen={setIsDropdownOpen}
    />
  );

  return (
    <Modal
      centered
      open={popupProps.showPopup}
      onOk={handleOk}
      onCancel={handleCancel}
      width={1250}
      title="Add Application SMB-123456788"
      footer={[
        <div className="cursor margin100">
          <div className="buttonBg floatRight" onClick={handleOk}>
            <img
              src={addTocase}
              alt="addTocase"
              className="buttonWidth add_CaseImage"
            />
            <span className="buttonTextAddCase">Add to case +</span>
          </div>
        </div>,
      ]}
      className="AddHeaderfix widthOfPopup"
    >
      <div className="Apppopup-container appexistingtable">
        <div className="appTable">
          <div className="search-box">
            <img
              src={search}
              alt="search"
              className="search-logoimage cursor"
            />
            <Input className="searchele" placeholder="Search" />
          </div>
          <div className="filter-box">
            <div className="onlyFilterSection">
              <div className="filterleft cursor floatLeft">
                {/* <img
                  src={filtercross}
                  alt="filtercross"
                  className="filtercross-logoimage"
                  onClick={openFilter}
                />
                <span className="filtersname onlypopup" onClick={openFilter}>
                  Filters
                </span>
                <img
                  src={filtericon}
                  alt="filtericon"
                  className="filtericon-logoimage "
                  onClick={openFilter}
                />
                {codeNumber ? <Casefilters isCreateCase={true} setCaseNumber={setCodeNumber} /> : ""} */}

                <Dropdown
                  overlay={takeActionmenu}
                  trigger={["click"]}
                  visible={isDropdownOpen}
                  className="dropdown-addcases-filters"
                  onOpenChange={handleDropdownVisibleChange}
                >
                  <Button
                    icon={
                      <img
                        src={filtercross}
                        alt="filtercross"
                        className="filter-addcases-buttton"
                        onClick={openFilter}
                      />
                    }
                  >
                    <span style={{ marginRight: "30px" }}>Filters</span>
                    {isDropdownOpen ? (
                      <UpOutlined className="up-arrow" />
                    ) : (
                      <DownOutlined />
                    )}
                  </Button>
                </Dropdown>
              </div>
              <div className="menuSections floatRight">
                <div className="date_borderText floatLeft">
                  <span className="info_span correctSpan">
                    Created Start Date
                  </span>

                  <DatePicker
                    bordered={false}
                    placeholder="12/23/2023"
                    className="callSche callDate cursor alignDateInBox"
                    format={"MM/DD/YYYY"}
                    renderExtraFooter={() => (
                      <div className="alignAnytime">
                        <Checkbox className="anyTime">Anytime</Checkbox>
                      </div>
                    )}
                    showToday={false}
                  />
                </div>
                <span className="toTextDate floatLeft"> to </span>
                <div className="date_borderText floatLeft">
                  <span className="info_span correctSpan">
                    Created End Date
                  </span>

                  <DatePicker
                    bordered={false}
                    placeholder="08/31/2023"
                    className="callSche callDate cursor alignDateInBox"
                    format={"MM/DD/YYYY"}
                    renderExtraFooter={() => (
                      <div className="alignAnytime">
                        <Checkbox className="anyTime">Anytime</Checkbox>
                      </div>
                    )}
                    showToday={false}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <Table
              rowSelection={{
                type: selectionType,
                ...rowSelection,
              }}
              columns={columns}
              dataSource={data}
              bordered
              pagination={false}
            />
            <div id="pagnations">
              <div className="paginationSection floatLeft cursor marginTopPage">
                <img src={leftVector} alt="leftNav" className="" />
              </div>
              <div>
                <span className="pageNum">
                  1 to {data.length} of {data.length}
                </span>
              </div>
              <div className="paginationSection floatRight cursor marginTopPage">
                <img src={rightVector} alt="rightNav" className="darkerImage" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddApplicationsToExisting;
