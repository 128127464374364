import React from "react";
import { Button, Layout } from "antd";
import { useNavigate } from "react-router-dom";
import branding from "../../Assets/Images/branding.svg";
import { error } from "./Constants";

const { Content } = Layout;

const NotFound = () => {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate("/");
  };
  const { title, image } = error["404"];
  return (
    <div className="error-container">
      <div  className="error-description">
        <img src={image} alt={"404-Notfound"} />
        <div>
        <span>{title}</span><br/>
          <Button className="" onClick={handleGoBack}>
            Go Back
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
