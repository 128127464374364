import { IUnderStandingUploadProps } from "../../Web/Contracts/Contracts";
import { ApiEndpoints } from "../APIEndPoints";
import { ILoquatServiceClient } from "../LoquatClient";

import { ServiceBase } from "../ServiceBase";

export class KYBService
  extends ServiceBase
  implements IKYBService
{
  constructor(private client: ILoquatServiceClient) {
    super(client.getAxiosInstance());
  }

  async postUnderstandingUpload(
    body: IUnderStandingUploadProps,
    documentTypeId: number,
    countrycode: string,
    legalTypeId: number
  ) {
    let url = ApiEndpoints.UnderstandingUpload.setUnderstandingUpload(
      documentTypeId,
      countrycode,
      legalTypeId
    );
    const response: any = await this.doPost(url, body);
    return response;
  }
}

export interface IKYBService {
  postUnderstandingUpload(
    body: any,
    documentTypeId: number,
    countrycode: string,
    legalTypeId: number
  ): Promise<any>;
}
