import { ApiEndpoints } from "../APIEndPoints";
import {
    ILoquatServiceClient,
} from "../LoquatClient";

import { ServiceBase } from "../ServiceBase";

export class MasterDataService
  extends ServiceBase
  implements IMasterDataService
{
  constructor(private client: ILoquatServiceClient) {
    super(client.getAxiosInstance());
  }

  async getLegalTypes() {
    let url = ApiEndpoints.MasterData.getLegalType();
    console.log(url);
    const response: any = await this.doGet(url);
    return response;
  }

  async getDocumentTypes() {
    let url = ApiEndpoints.MasterData.getDocumentType();
    const response: any = await this.doGet(url);
    return response;
  }

  async getIndustries() {
    let url = ApiEndpoints.MasterData.getIndustries();
    console.log(url);
    const response: any = await this.doGet(url);
    return response;
  }
}



export interface IMasterDataService {
    getLegalTypes(): Promise<any>;
    getDocumentTypes(): Promise<any>;
    getIndustries(): Promise<any>;
}

