import React, { useContext, useEffect, useState } from "react";
import {
  Layout,
  Space,
  Button,
  Row,
  Col,
  Input,
  Select,
  Form,
  Image,
} from "antd";
import { useNavigate } from "react-router-dom";

import goback from "../../Assets/Images/goBackSvg.svg";
import approve from "../../Assets/Images/approveBg.png";
import approveVector from "../../Assets/Images/approveVector.svg";
import declineVector from "../../Assets/Images/declineVector.svg";

import escalate from "../../Assets/Images/escalateerrorBg.png";
import decline from "../../Assets/Images/declineBg.png";
import investigate from "../../Assets/Images/investigateBg.png";
import ApplicationApproved from "../ApplicationApproved/ApplicationApproved";
import ApplicationDeclined from "../ApplicationDeclined/ApplicationDeclined";
import Escalate from "../Escalate/Escalate";
import { ReactComponent as IconCheck } from "../../Assets/Images/icons/ic_check.svg";
import { ReactComponent as IconEye } from "../../Assets/Images/icons/ic_eye_opened.svg";
import { ReactComponent as IconCross } from "../../Assets/Images/icons/ic_cross.svg";

import "./Footer.scss";
import { ApplicationContext } from "../../Context/ApplicationContextProvider";

const size = "large";
const StepsFooter = () => {
  const { isApproveDeclineEnabled, enableApproveDecline } =
    useContext(ApplicationContext);

  const [showPopup, setShowPopup] = useState(false);
  const [showPopup1, setShowPopup1] = useState(false);
  const [showPopup2, setShowPopup2] = useState(false);

  const handlePopup = () => {
    setShowPopup(true);
  };
  const handlePopup1 = () => {
    setShowPopup1(true);
  };
  const esaalatepopup = () => {
    setShowPopup2(true);
  };
  const navigate = useNavigate();
  const goToInvestiagte = () => {
    navigate("/investigateappln");
    enableApproveDecline(false);
  };
  const navigateBack = () => {
    if (pathname == "/investigateappln") {
      // write to disable condition
      enableApproveDecline(false);
      navigate("/openappln");
    } else if (pathname == "/openappln") {
      navigate("/applications");
    }
  };

  console.log(window.location.pathname);
  const pathname = window.location.pathname;

  return (
    <>
      <div className="footerfixed">
        <div className="fixed_left">
          <p className="gobackcenter">
            <span className="gobackimgmain">
              <img className="goback_img" src={goback} alt="goback" />
            </span>
            <span className="goback" onClick={navigateBack}>
              Go back
            </span>
          </p>
        </div>

        <div className="fixed_right">
          <div
            className={`buttonBg floatLeft cursor createButton approve `}
            // onClick={handlePopup}
          >
            <div className="buttonText">
              <span>
                Approve <IconCheck />
              </span>
            </div>
          </div>
          {pathname !== "/investigateappln" ? (
            <div
              className="buttonBg floatLeft cursor margin10 createButton investigate"
              // onClick={goToInvestiagte}
            >
              <span className="buttonText">
                Investigate <IconEye />
              </span>
            </div>
          ) : (
            <div
              className="buttonBg floatLeft cursor marginTop7"
              // onClick={esaalatepopup}
            >
              <Image
                src={escalate}
                alt="escalate"
                loading="lazy"
                preview={false}
                className="buttonWidthInvestEscalate"
              />
              <span className="buttonTextInvesEscalate">Escalate</span>
            </div>
          )}
          <div
            className={`buttonBg floatLeft cursor createButton decline }`}
            // className={`buttonBg floatLeft cursor`}
            // onClick={handlePopup1}
          >
            <div className="buttonText">
              <span>
                Decline <IconCross />
              </span>
            </div>
          </div>
        </div>
        <ApplicationApproved
          showPopup={showPopup}
          setShowPopup={setShowPopup}
        ></ApplicationApproved>

        <ApplicationDeclined
          showPopup1={showPopup1}
          setShowPopup={setShowPopup1}
        ></ApplicationDeclined>
        <Escalate
          showPopup2={showPopup2}
          setShowPopup={setShowPopup2}
        ></Escalate>
      </div>
    </>
  );
};

export default StepsFooter;
