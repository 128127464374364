import React, { useContext, useEffect, useState } from "react";
import { ApplicationContext } from "../../Context/ApplicationContextProvider";
import { Layout, Space, Select, Input, DatePicker } from "antd";
import { RightOutlined } from "@ant-design/icons";
import "./DeleteFilter.scss";
import { useNavigate } from "react-router-dom";
import { Button, Modal, Image, Row, Col } from "antd";
import noBtn from "../../Assets/Images/noBtnbg.png";
import yesBtn from "../../Assets/Images/yesBtnBg.png";
import { LeftOutlined } from "@ant-design/icons";
const { Header, Footer, Sider, Content } = Layout;
const { TextArea } = Input;

type DeleteFilterProps = {
  showPopup: boolean;
  setShowPopup: (value: boolean) => void;
  setIsDeleteFilter?: (value: boolean) => void;
};
const DeleteFilter = (popupProps: DeleteFilterProps) => {
  const navigate = useNavigate();

  const handleOk = () => {
    if (popupProps.setIsDeleteFilter) {
      popupProps.setIsDeleteFilter(true);
    }
    popupProps.setShowPopup(false);
  };

  const handleCancel = () => {
    if (popupProps.setIsDeleteFilter) {
      popupProps.setIsDeleteFilter(false);
    }
    popupProps.setShowPopup(false);
  };

  return (
    <Modal
      centered
      open={popupProps.showPopup}
      title="Are you sure you want to delete this saved filter?"
      width={700}
      bodyStyle={{ height: 100 }}
      onOk={handleOk}
      className="passHeaderfix"
      onCancel={handleCancel}
      footer={[,]}
    >
      <div className="ContentCreate margin20 ">
        <Row className="popBtns">
          <div className="leftBtn">
            <div className="buttonBg floatLeft cursor" onClick={handleCancel}>
              <img src={noBtn} alt="noBtn" className="buttonWidthNo" />
              <span className="buttonTextNo">No</span>
            </div>
          </div>
          <div className="rightBtn">
            <div
              className="buttonBg floatLeft cursor createButton"
              onClick={handleOk}
            >
              <span className="buttonText">Yes</span>
            </div>
          </div>
        </Row>
      </div>
    </Modal>
    //   </Content>
    // </Layout>
  );
};

export default DeleteFilter;
