import React, { useContext, useEffect, useState } from "react";
import {
  Layout,
  Space,
  Button,
  Row,
  Col,
  Input,
  Select,
  Form,
  Collapse,
  CollapseProps,
  theme,
  DatePicker,
} from "antd";

import FixedFooter from "../Footer/Footer";
import FixedHeader from "../FIxedHeader/FixedHeader";

import { RightOutlined } from "@ant-design/icons";
import "./ValidateDetails.scss";
import PrivacyPolicy from "../PrivayPolicy/PrivacyPolicy";
import ValidateApplnTabs from "../ValidateApplnTabs/ValidateApplnTabs";
const { Header, Footer, Sider, Content } = Layout;
const size = "large";
const { Option } = Select;
type ExpandIconPosition = "start" | "end";

const ValidateDetails = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className="doc_details_sec">
      <FixedHeader></FixedHeader>
      <ValidateApplnTabs></ValidateApplnTabs>
      <PrivacyPolicy></PrivacyPolicy>
      <div className="ApplicationsFooter">
        <FixedFooter></FixedFooter>
      </div>
    </div>
  );
};

export default ValidateDetails;
