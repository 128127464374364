import {
  ISendOtp,
  IValidateOtp,
  IValidateOtpDetails,
} from "../../Web/Contracts/Contracts";
import { ApiEndpoints } from "../APIEndPoints";
import { ILoquatServiceClient } from "../LoquatClient";

import { ServiceBase } from "../ServiceBase";

export class AuthenticationService
  extends ServiceBase
  implements IAuthenticationService
{
  constructor(private client: ILoquatServiceClient) {
    super(client.getAxiosInstance());
  }

  async getPhoneAuthenticationOtp(payload: ISendOtp) {
    let url = ApiEndpoints.PhoneAuthentication.getOtp();
    const response: any = await this.doPost(url, payload);
    return response;
  }

  async postPhoneAuthenticationOtp(body: IValidateOtp) {
    let url = ApiEndpoints.ValidateOtp.validateOtp();
    const response: any = await this.doPost(url, body);
    return response;
  }
}

export interface IAuthenticationService {
  getPhoneAuthenticationOtp(payload: ISendOtp): Promise<any>;
  postPhoneAuthenticationOtp(body: IValidateOtp): Promise<any>;
}
