import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { IDynamicConfig } from "../Web/Configuration/Config";

import { AuthService } from "../Web/Authentication/AuthService";
import {
  IMasterDataService,
  MasterDataService,
} from "./Services/MasterDataService";
import {
  IGeolocationService,
  GeolocationService,
} from "./Services/GeolocationService";
import {
  AuthenticationService,
  IAuthenticationService,
} from "./Services/AuthenticationService";
import { IKYBService, KYBService } from "./Services/KYBService";
import { IProfileService, ProfileService } from "./Services/ProfileService";
import { BusinessService, IBusinessService } from "./Services/BusinessService";
export interface ILoquatServiceClient {
  getAxiosInstance(): Promise<AxiosInstance>;
  currentUserId: string;
}

export class LoquatClient implements ILoquatServiceClient {
  public currentUserId!: string;
  private _instance: AxiosInstance;
  private authService;

  constructor(public baseURL: string, public config: IDynamicConfig | any) {
    this._instance = axios.create({
      baseURL: baseURL,
    });

    this._instance.defaults.headers.common["Accept"] = "application/json";
    this._instance.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
    this._instance.defaults.headers.common["TenantID"] = "bd237a9a-2f1c-4136-8fc5-07386878786f-afcu";

    this.authService = AuthService.getInstance(this.config);
  }

  async getAxiosInstance(): Promise<AxiosInstance> {
    try {
      const token = await AuthService.getToken();
      const subKey = await AuthService.getSubscriptionKey();

      if (this._instance.defaults.headers != null) {
        this._instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        this._instance.defaults.headers.common["Ocp-Apim-Subscription-Key"] = `${subKey}`;
      }
    } catch (error: any) {
      console.log(error);
    }
    return this._instance;
  }

  masterDataService(): IMasterDataService {
    return new MasterDataService(this);
  }

  geolocationService(): IGeolocationService {
    return new GeolocationService(this);
  }

  authenticationService(): IAuthenticationService {
    return new AuthenticationService(this);
  }

  kybService(): IKYBService {
    return new KYBService(this);
  }
  profileService(): IProfileService {
    return new ProfileService(this);
  }

  businessService(): IBusinessService {
    return new BusinessService(this);
  }
}
