import React, { useContext, useEffect, useState } from "react";
import { ApplicationContext } from "../../Context/ApplicationContextProvider";
import { Layout, Space, Select, Input, DatePicker, TimePicker } from "antd";
import { RightOutlined } from "@ant-design/icons";
import "./CallScheduled.scss";
import { useNavigate } from "react-router-dom";
import { Button, Modal, Image, Row, Col } from "antd";
import cancelcall from "../../Assets/Images/scheduleCancel.png";
import schedulecall from "../../Assets/Images/scheduleCallBg.png";
import { LeftOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

const { Header, Footer, Sider, Content } = Layout;
const { TextArea } = Input;

type CallPopupProps = {
  showCallPopup: boolean;
  showScheduleunfilled: boolean;
  setShowCallPopup: (value: boolean) => void;
  setshowScheduleunfilled: (value: boolean) => void;
  setSelectedDateTime: (dateTime: any) => void;
  selectedDateTime: any;
};
const CallScheduled = (popupProps: CallPopupProps) => {
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [dateError, setDateError] = useState("");
  const [timeError, setTimeError] = useState("");

  useEffect(() => {
    if (popupProps.selectedDateTime) {
      const [dateTime, time] = popupProps.selectedDateTime.split(" ");
      const [month, day, year] = dateTime.split("/");
      const formattedDate = `${month}/${day}/${year}`;
      const formattedTime = `${time} ${dateTime.endsWith("AM") ? "AM" : "PM"}`;
      setSelectedDate(formattedDate);
      setSelectedTime(formattedTime);
    }
  }, [popupProps.selectedDateTime]);

  const handleOk = () => {
    popupProps.setShowCallPopup(false);
  };

  const handleCancel = () => {
    popupProps.setShowCallPopup(false);
  };

  const copyDone = () => {
    if (selectedDate && selectedTime) {
      const formattedDateTime = `${selectedDate ? selectedDate : ""} ${
        selectedTime ? selectedTime : ""
      }`;
      popupProps.setSelectedDateTime(formattedDateTime);
      popupProps.setshowScheduleunfilled(true);
      handleCancel();
    } else {
      if (selectedDate === null || selectedDate === "") {
        setDateError("Please select date");
      }
      if (selectedTime === null || selectedTime === "") {
        setTimeError("Please select time");
      }
    }
  };

  return (
    <Modal
      centered
      open={popupProps.showCallPopup}
      title="Please contact Support to provide further information"
      width={800}
      bodyStyle={{ height: 200 }}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Row className="popBtns onlySchedule">
          <div className="leftBtn">
            <div className="buttonBg floatLeft cursor" onClick={handleCancel}>
              <img
                src={cancelcall}
                alt="cancelcall"
                className="buttonWidthScheCancel"
              />
              <span className="buttonTextScheCancel">Cancel call</span>
            </div>
          </div>
          <div className="rightBtn">
            <div className="buttonBg floatRight cursor" onClick={copyDone}>
              <img src={schedulecall} alt="reset" className="buttonWidthSche" />
              <span className="buttonTextSche">Schedule call</span>
            </div>
          </div>
        </Row>,
      ]}
    >
      {/* <Form form={form}> */}
      <div className="ContentCreate margin20 ">
        <Row>
          <LeftOutlined className="cursor" />
        </Row>
        <Row>
          <p className="paraCall">When would you like us to call you?</p>
        </Row>
        <Row>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 10 }}
            lg={{ span: 10 }}
          >
            <Space direction="vertical" className="fullWidth">
              {/* <Form.Item
                  name="date"
                  rules={[
                    {
                      required: true,
                      message: "Please select a date",
                    },
                  ]}
                > */}
              <div className="inputCallScheduled">
                <span className="callyou">Date</span>
                <DatePicker
                  bordered={false}
                  placeholder="MM/DD/YYYY"
                  className="callSche callDate cursor"
                  format={"MM/DD/YYYY"}
                  value={
                    selectedDate ? dayjs(selectedDate, "MM/DD/YYYY") : null
                  }
                  onChange={(date, dateString) => {
                    //@ts-ignore
                    setSelectedDate(dateString);
                    setDateError("");
                  }}
                />
              </div>
              {dateError && <p className="error-message">{dateError}</p>}
              {/* </Form.Item> */}
            </Space>
          </Col>

          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 10 }}
            lg={{ span: 9 }}
          >
            <Space wrap className="fullWidth">
              {/* <Form.Item
                  name="time"
                  rules={[
                    {
                      required: true,
                      message: "Please select a time",
                    },
                  ]}
                > */}
              <div className="inputCallScheduled">
                <span className="callyou">Time</span>
                <TimePicker
                  bordered={false}
                  className="callSche timeSch"
                  format="hh:mm A"
                  minuteStep={30}
                  use12Hours
                  value={selectedTime ? dayjs(selectedTime, "hh:mm A") : null}
                  onChange={(time, timeString) => {
                    //@ts-ignore
                    setSelectedTime(timeString);
                    setTimeError("");
                  }}
                />
              </div>
              {timeError && <p className="error-message">{timeError}</p>}
              {/* </Form.Item> */}
            </Space>
          </Col>
        </Row>
      </div>
      {/* </Form> */}
    </Modal>
    //   </Content>
    // </Layout>
  );
};

export default CallScheduled;
