import React, { useContext, useEffect, useState } from "react";
import {
  Layout,
  Space,
  Button,
  Row,
  Col,
  Input,
  Select,
  Form,
  Image,
  DatePicker,
} from "antd";
import "./ClientFilters.scss";

import SaveFilterPopup from "../SaveFilterPopup/SaveFilterPopup";
import { ApplicationContext } from "../../Context/ApplicationContextProvider";
import { InitialApplicationFilterValue } from "../../Contracts/Contracts";
import applyimage from "../../Assets/Images/acceptBg.png";
import saveimage from "../../Assets/Images/saveFilterBg.png";
import clearimage from "../../Assets/Images/clearBg.png";
import { ReactComponent as IconCalendar } from "../../Assets/Images/icons/ic_calendar.svg";
import { ReactComponent as IconArrow } from "../../Assets/Images/icons/ic_big_arrow_down.svg";
import {
  getValueFromLocalStorage,
  setValueInLocalStorage,
} from "../Utility/Utility";

const { Header, Footer, Sider, Content } = Layout;
const size = "large";

type FilterProps = {
  setFilterValues?: any;
  filterValues?: any;
  setCodeNumber?: any;
  setCurrentFilterName?: any;
  isAddApplication?: any;
  setIsDropdownOpen?: any;
};

const Clientfilters = (props: FilterProps) => {
  const [showPopup, setShowPopup] = useState(false);
  const { setGlobalFillterStatus } = useContext(ApplicationContext);
  const [onSaveClick, setOnSaveClick] = useState(false);
  const [filterData, setFilterData] = useState(InitialApplicationFilterValue);

  useEffect(() => {
    if (props.filterValues) {
      setFilterData(props.filterValues);
    }
  }, [props.filterValues]);

  useEffect(() => {
    console.log("filterData: ", filterData);
  }, [JSON.stringify(filterData)]);

  const onFilterClick = () => {
    if (props.setFilterValues) {
      props.setFilterValues(filterData);
    }
    props.setCodeNumber(false);
    props.setIsDropdownOpen(false);
  };

  const onClearClick = () => {
    setFilterData(InitialApplicationFilterValue);
  };

  useEffect(() => {
    if (onSaveClick) {
      if (props.setFilterValues) {
        props.setFilterValues(filterData);
      }
      const savedData = JSON.parse(
        getValueFromLocalStorage("appTableFilterData") || "[]"
      );
      const filterId = savedData.length + 1;
      filterData.filterId = filterId;
      savedData.push(filterData);
      setValueInLocalStorage("appTableFilterData", JSON.stringify(savedData));
      setGlobalFillterStatus(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSaveClick]);

  const updateFilterValue = (fieldName: string, value: any) => {
    setFilterData((prevFilterValues: any) => ({
      ...prevFilterValues,
      [fieldName]: value,
    }));
  };

  const handlePopup = () => {
    setShowPopup(true);
    props.setIsDropdownOpen(false);
  };

  return (
    <div className="ClientFilters " id="filterSection">
      <div className="wholeSection">
        <Row className="">
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
          >
            <div className="input-form__wrapper">
              <Input
                className="input-form"
                placeholder="Application ID"
                value={filterData.ID}
                onChange={(e) => updateFilterValue("ID", e.target.value)}
              />
              {filterData.ID && (
                <span className="customLabel">Application ID</span>
              )}
            </div>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
          >
            <div className="input-form__wrapper">
              <Input
                className="input-form"
                placeholder="First Name"
                value={filterData.Fname}
                onChange={(e) => updateFilterValue("Fname", e.target.value)}
              />
              {filterData.Fname && (
                <span className="customLabel">First Name</span>
              )}
            </div>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
          >
            <div className="input-form__wrapper">
              <Input
                className="input-form"
                placeholder="Business Name"
                value={filterData?.Bname}
                onChange={(e) => updateFilterValue("Bname", e.target.value)}
              />
              {filterData?.Bname && (
                <span className="customLabel">Business Name</span>
              )}
            </div>
          </Col>
        </Row>

        <Row className="">
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
          >
            <div className="input-form__wrapper">
              <Input
                className="input-form"
                placeholder="SSN"
                value={filterData.ssn}
                onChange={(e) => updateFilterValue("ssn", e.target.value)}
              />
              {filterData.ssn && <span className="customLabel">SSN</span>}
            </div>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
          >
            <div className="input-form__wrapper">
              <Input
                className="input-form"
                placeholder="Last Name"
                value={filterData.Lname}
                onChange={(e) => updateFilterValue("Lname", e.target.value)}
              />
              {filterData.Lname && (
                <span className="customLabel">Last Name</span>
              )}
            </div>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
          >
            <div className="input-form__wrapper">
              <Input
                className="input-form"
                placeholder="Business Address"
                value={filterData.businessAddress}
                onChange={(e) =>
                  updateFilterValue("businessAddress", e.target.value)
                }
              />
              {filterData.businessAddress && (
                <span className="customLabel">Business Address</span>
              )}
            </div>
          </Col>
        </Row>

        <Row className="">
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
          >
            <div className="input-form__wrapper">
              <Input
                className="input-form"
                placeholder="TIN"
                value={filterData.tin}
                onChange={(e) => updateFilterValue("tin", e.target.value)}
              />
              {filterData.tin && <span className="customLabel">TIN</span>}
            </div>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
          >
            <div className="input-form__wrapper">
              <DatePicker
                suffixIcon={<IconCalendar />}
                className="input-form"
                bordered={false}
                format={"MM/DD/YYYY"}
                placeholder="Date of Birth"
                value={filterData.dob}
                onChange={(value) => updateFilterValue("dob", value)}
              />
              {filterData.dob && (
                <span className="customLabel">Date of Birth</span>
              )}
            </div>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
          >
            <div className="input-form__wrapper">
              <Select
                suffixIcon={<IconArrow />}
                className="input-form"
                mode="multiple"
                maxTagCount="responsive"
                size={size}
                placeholder="Industry"
                options={[
                  {
                    value: "Accommodation Services",
                    label: "Accommodation Services",
                  },
                  {
                    value: "Administration Services",
                    label: "Administration Services",
                  },
                  { value: "Agriculture", label: "Agriculture" },
                  { value: "Arts", label: "Arts" },
                  {
                    value: "Business Support Services",
                    label: "Business Support Services",
                  },
                  { value: "Construction", label: "Construction" },
                  {
                    value: "Educational Services",
                    label: "Educational Services",
                  },
                  { value: "Entertainment", label: "Entertainment" },
                  { value: "Finance", label: "Finance" },
                  { value: "Fishing", label: "Fishing" },
                  { value: "Food Services", label: "Food Services" },
                  { value: "Forestry", label: "Forestry" },
                  { value: "Healthcare", label: "Healthcare" },
                  { value: "Hunting", label: "Hunting" },
                  { value: "Information", label: "Information" },
                  { value: "Insurance", label: "Insurance" },
                  { value: "Leasing", label: "Leasing" },
                  { value: "Manufacturing", label: "Manufacturing" },
                  { value: "Mining", label: "Mining" },
                  { value: "Other Services", label: "Other Services" },
                  {
                    value: "Professional Services",
                    label: "Professional Services",
                  },
                  { value: "Real Estate", label: "Real Estate" },
                  { value: "Recreation", label: "Recreation" },
                  { value: "Rental", label: "Rental" },
                  { value: "Retail Trade", label: "Retail Trade" },
                  {
                    value: "Scientific Services",
                    label: "Scientific Services",
                  },
                  { value: "Social Assistance", label: "Social Assistance" },
                  {
                    value: "Technical Services",
                    label: "Technical Services",
                  },
                  { value: "Transportation", label: "Transportation" },
                  { value: "Utilities", label: "Utilities" },
                  { value: "Warehousing", label: "Warehousing" },
                  {
                    value: "Waste Management Services",
                    label: "Waste Management Services",
                  },
                  { value: "Wholesale Trade", label: "Wholesale Trade" },
                ]}
                value={
                  filterData.industry === "" || filterData.industry === null
                    ? undefined
                    : filterData.industry
                }
                // value={filterData.industry}
                onChange={(value) => updateFilterValue("industry", value)}
              />
              {filterData.industry && (
                <span className="customLabel">Industry</span>
              )}
            </div>
          </Col>
        </Row>

        <Row className="">
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
          >
            <div className="input-form__wrapper">
              <Input
                className="input-form"
                placeholder="Phone"
                value={filterData.phone}
                onChange={(e) => updateFilterValue("phone", e.target.value)}
              />
              {filterData.phone && <span className="customLabel">Phone</span>}
            </div>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
          >
            <div className="input-form__wrapper">
              <Input
                className="input-form"
                placeholder="Address"
                value={filterData.address}
                onChange={(e) => updateFilterValue("address", e.target.value)}
              />
              {filterData.address && (
                <span className="customLabel">Address</span>
              )}
            </div>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
          >
            <div className="input-form__wrapper">
              <Select
                className="input-form"
                mode="multiple"
                maxTagCount="responsive"
                suffixIcon={<IconArrow />}
                size={size}
                placeholder="Status"
                options={[
                  { value: "New", label: "New" },
                  { value: "In Progress", label: "In Progress" },
                  { value: "Escalated", label: "Escalated" },
                  { value: "Approved", label: "Approved" },
                  { value: "Auto-Approved", label: "Auto-Approved" },
                  { value: "Declined", label: "Declined" },
                ]}
                value={
                  filterData.Status === "" || filterData.Status === null
                    ? undefined
                    : filterData.Status
                }
                // value={filterData.Status}
                onChange={(value) => updateFilterValue("Status", value)}
              />
              {filterData.Status && <span className="customLabel">Status</span>}
            </div>
          </Col>
        </Row>

        <Row className="">
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
          >
            <div className="input-form__wrapper">
              <Input
                className="input-form"
                placeholder="Email"
                value={filterData.email}
                onChange={(e) => updateFilterValue("email", e.target.value)}
              />
              {filterData.email && <span className="customLabel">Email</span>}
            </div>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
          >
            <div className="input-form__wrapper">
              <Select
                className="input-form"
                size={size}
                suffixIcon={<IconArrow />}
                // placeholder="Multiple Beneficial Ownership"
                placeholder="Beneficial Ownership"
                options={[
                  { value: "Yes", label: "Yes" },
                  { value: "No", label: "No" },
                ]}
                // value={filterData.beneficialOwnership}
                value={
                  filterData.beneficialOwnership === "" ||
                  filterData.beneficialOwnership === null
                    ? undefined
                    : filterData.beneficialOwnership
                }
                onChange={(value) =>
                  updateFilterValue("beneficialOwnership", value)
                }
              />
              {filterData.beneficialOwnership && (
                <span className="customLabel">Beneficial Ownership</span>
              )}
            </div>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
          >
            <div className="input-form__wrapper">
              <Select
                className="input-form"
                suffixIcon={<IconArrow />}
                size={size}
                placeholder="Assignee"
                options={[
                  {
                    value: "john.smith@loquatinc.io",
                    label: "john.smith@loquatinc.io",
                  },
                  {
                    value: "james.smith@loquatinc.io",
                    label: "james.smith@loquatinc.io",
                  },
                  {
                    value: "bob.smith@loquatinc.io",
                    label: "bob.smith@loquatinc.io",
                  },
                ]}
                value={
                  filterData.assignee === "" || filterData.assignee === null
                    ? undefined
                    : filterData.assignee
                }
                // value={filterData.assignee}
                onChange={(value) => updateFilterValue("assignee", value)}
              />
              {filterData.assignee && (
                <span className="customLabel">Assignee</span>
              )}
            </div>
          </Col>
        </Row>
        <Row className="floatRight marginTop42">
          <div className="btn-apply" onClick={onFilterClick}>
            Apply
          </div>

          {!props.isAddApplication && (
            <div className="btn-save" onClick={handlePopup}>
              Save
            </div>
          )}
          <div className="btn-clear" onClick={onClearClick}>
            Clear
          </div>
        </Row>
        {showPopup && (
          <SaveFilterPopup
            showPopup={showPopup}
            setShowPopup={setShowPopup}
            setOnSaveClick={setOnSaveClick}
            filterType="application"
            setCodeNumber={props.setCodeNumber}
            setCurrentFilterName={props.setCurrentFilterName}
          />
        )}
      </div>
    </div>
  );
};

export default Clientfilters;
