import { visitFunctionBody } from "typescript";
import { IBusinessPostInfoValues } from "../../Web/Contracts/Contracts";
import { IBeneficiaryOwnerInviteValue } from "../../Web/Contracts/Contracts";
import { ApiEndpoints } from "../APIEndPoints";
import { ILoquatServiceClient } from "../LoquatClient";

import { ServiceBase } from "../ServiceBase";

export class BusinessService extends ServiceBase implements IBusinessService {
  constructor(private client: ILoquatServiceClient) {
    super(client.getAxiosInstance());
  }

  async getBusinessInfo() {
    let url = ApiEndpoints.Business.getBusinessInfo();
    const response: any = await this.doGet(url);
    return response;
  }

  async postBusinessInfo(body: IBusinessPostInfoValues) {
    let url = ApiEndpoints.Business.postBusinessInfo();
    const response: any = await this.doPost(url,body);
    return response;
  }

  async postBeneficiaryOwnerInvitation(
    body: IBeneficiaryOwnerInviteValue,
  ) {
    let url = ApiEndpoints.Business.setBeneficiaryOwnerInvitation();
    const response: any = await this.doPost(url, body);
    return response;
  }

}


export interface IBusinessService {
  getBusinessInfo(): Promise<any>;
  postBusinessInfo(body: IBusinessPostInfoValues): Promise<any>;
  postBeneficiaryOwnerInvitation(
    body: any,
  ): Promise<any>;
}
