import React, { useState } from 'react';
import "./BeneficialOwnerList.scss";

interface BeneficialOwner {
  name: string;
  children: React.ReactNode;
  status: boolean;
}

const BeneficialOwnerList: React.FC<{ owners: BeneficialOwner[] }> = ({ owners }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(0);
  const [activeOwner, setActiveOwner] = useState<React.ReactNode | null>(owners[0].children)

  const changeOwner = (index: number, owner: BeneficialOwner) => {
    setActiveIndex(index);
    setActiveOwner(owner.children);
  }

  return (
    <>
        <div className="beneficialowner-list">
        {owners.map((owner, index) => (
            <button
            key={index}
            className={`beneficialowner-list__item ${activeIndex === index ? 'active' : ''}`}
            onClick={() => changeOwner(index, owner)}
            >
            <div className="beneficialowner-list__item-body">
                <span className="beneficialowner-list__item-name">{owner.name}</span>
                <div className={`beneficialowner-list__item-status ${owner.status ? "confirmed" : ""}`}>
                {owner.status ? 'CONFIRMED' : 'NOT CONFIRMED'}
                </div>
            </div>
            </button>
        ))}
        </div>
        <div>
            {activeOwner}
        </div>
    </>
  );
};

export default BeneficialOwnerList;
