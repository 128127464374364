import React, { useContext, useEffect, useState } from "react";
import { ApplicationContext } from "../../Context/ApplicationContextProvider";
import { Button, Image, Modal, Layout, Input, Space, Radio } from "antd";
import callus from "../../Assets/Images/callUsSvg.svg";
import livechat from "../../Assets/Images/livechat.png";
import CallScheduled from "../CallScheduled/CallScheduled";
import editicon from "../../Assets/Images/editiconSvg.svg";

import "./SupportPopup.scss";

import { IStepsHeader } from "../../Contracts/Contracts";

const { Header, Footer, Sider, Content } = Layout;
type FinalPopupProps = {
  showPopup: boolean;
  setShowPopup: (value: boolean) => void;
};
const SupportPopup = (popupProps: FinalPopupProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showCallPopup, setShowCallPopup] = useState(false);
  const [showScheduleunfilled, setshowScheduleunfilled] = useState(false);
  const [selectedDateTime,setSelectedDateTime] = useState();
  const handleOk = () => {
    popupProps.setShowPopup(false);
  };
  const openCallPopup = () => {
    setShowCallPopup(true);
  };

  const handleCancel = () => {
    popupProps.setShowPopup(false);
  };
  return (
    <Modal
      centered
      open={popupProps.showPopup}
      onOk={handleOk}
      onCancel={handleCancel}
      width={900}
      title="Please contact Support to provide further information"
      footer={[]}
    >
      <div className="support-container">
        <div className="support-group">
          <p className="support_para">Support 24/7</p>

          {/* support popup */}

          {!showScheduleunfilled && (
            <div className="supporcallus" id="supportschedulepopup">
              <div className="callsupport cursor" onClick={openCallPopup}>
                <span className="callmetext">Schedule a call</span>
                <span className="callmeimg">
                  <img
                    className="supportcornerimg"
                    src={callus}
                    alt="callicon"
                  />
                </span>
              </div>
              <div className="callsupport cursor">
                <span className="callmetext">Live Chat</span>
                <span className="callmeimg liveimage">
                  <img
                    className="supportcornerimg"
                    src={livechat}
                    alt="livechat"
                  />
                </span>
              </div>
            </div>
          )}

          {/* support schedule popup */}

          {showScheduleunfilled && (
            <div className="supporcallus" id="schedulepopup">
              <div className="callsupport">
                <div className="callyou_borderText">
                  <span className="callyou">We will call you</span>
                  <Input
                    className="callyoutext"
                    bordered={false}
                    value={selectedDateTime}
                    // placeholder="08/28/2023 15:00 PM"
                  />
                  <img
                    className="editimg cursor"
                    src={editicon}
                    alt="editicon"
                    onClick={openCallPopup}
                  />
                </div>
              </div>
              <div className="callsupport">
                <span className="callmetext">Live Chat</span>
                <span className="callmeimg liveimage">
                  <img
                    className="supportcornerimg"
                    src={livechat}
                    alt="livechat"
                  />
                </span>
              </div>
            </div>
          )}

          <div className="call_supportus">
            <span className="callus">Call us</span>
            <span className="call_num">(234) 567-8900</span>
          </div>
          <div className="call_supportus">
            <span className="callus">Write to us</span>
            <span className="call_num">help@loquatinc.io</span>
          </div>
        </div>
       {showCallPopup&& <CallScheduled
          showCallPopup={showCallPopup}
          setShowCallPopup={setShowCallPopup}
          setshowScheduleunfilled={setshowScheduleunfilled}
          showScheduleunfilled={showScheduleunfilled}
          setSelectedDateTime={setSelectedDateTime}
          selectedDateTime={selectedDateTime}
        />}
      </div>
    </Modal>
  );
};

export default SupportPopup;
