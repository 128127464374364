import React, { useContext, useEffect, useState } from "react";
import {
  Layout,
  Space,
  Button,
  Row,
  Col,
  Input,
  Select,
  Form,
  notification,
} from "antd";

import "./ReportsDashboardWithTabs.scss";
import ApplnTabView from "../ApplnTabs/ApplnTabs";
import ApplicationHeader from "../Header/Header";

import ReportsDasboardOnlyTab from "../ReportsDasboardOnlyTab/ReportsOnlyTab";

const { Header, Footer, Sider, Content } = Layout;
const size = "large";

const ReportsDashboardWithTabs = () => {
  return (
    <Layout className="ReportsDash-Open-layout">
      <ApplicationHeader></ApplicationHeader>
      <ReportsDasboardOnlyTab></ReportsDasboardOnlyTab>
    </Layout>
  );
};

export default ReportsDashboardWithTabs;
