import React, { useContext, useEffect, useState } from "react";
import { ApplicationContext } from "../../Context/ApplicationContextProvider";
import { Button, Image, Modal, Layout, Space, Radio } from "antd";
import applicantapprove from "../../Assets/Images/investigateAppDecBg.png";

import "./ApplicationApproved.scss";

import { IStepsHeader } from "../../Contracts/Contracts";

const { Header, Footer, Sider, Content } = Layout;

type FinalPopupProps = {
  showPopup: boolean;
  setShowPopup: (value: boolean) => void;
};
const ApplicationApproved = (popupProps: FinalPopupProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => {
    popupProps.setShowPopup(false);
  };

  const handleCancel = () => {
    popupProps.setShowPopup(false);
  };
  return (
    <Modal
      centered
      open={popupProps.showPopup}
      onOk={handleOk}
      onCancel={handleCancel}
      width={500}
      title="Application approved"
      footer={[]}
      className="widthOfPopup"
    >
      <div className="Applicationapproved">
        <div className="menbership">
          <span className="memberpara">Membership Number is : </span>
          <span className="memberbold">123456790</span>
        </div>

        <div
          className="buttonBg floatRight cursor"
          onClick={handleCancel}
        >
          <img
            src={applicantapprove}
            alt="applicantapprove"
            className="buttonWidthApproved"
          />
          <span className="buttonTextApproved">Email applicant</span>
        </div>
      </div>
    </Modal>
  );
};

export default ApplicationApproved;
